import { ContentResponse } from 'app/types/integration/content.api.types';
import { TaxonomyMetadata } from 'app/types/integration/taxonomies.api.types';
import { removeDuplicates } from 'utils/arrays';

/**
 * Given a list content editor objects, return learning objectives,
 * without duplicates
 */
export const contentsToLearningObjectives = (
  documentLocation: string,
  content: ContentResponse[]
): string[] => {
  const learningObjectives: string[] = [];
  for (let i = 0; i < content.length; i++) {
    const contentEditorObject: ContentResponse = content[i];
    const taxonomyMetadata = contentEditorObject.taxonomy;
    if (!taxonomyMetadata) continue;
    learningObjectives.push(
      ...taxonomyMetadataToLearningObjectives(
        documentLocation,
        taxonomyMetadata
      )
    );
  }

  const sorted = sortLearningObjectives(learningObjectives);
  const unique = removeDuplicates(sorted);
  return unique;
};

export const taxonomyMetadataToLearningObjectives = (
  documentLocation: string,
  taxonomyMetadata: TaxonomyMetadata
) => {
  const learningObjectives: string[] = [];

  const taxonomies = Array.isArray(taxonomyMetadata.entries.entry)
    ? taxonomyMetadata.entries.entry
    : [taxonomyMetadata.entries.entry];

  for (let j = 0; j < taxonomies.length; j++) {
    const taxonomy = taxonomies[j];
    const courseTopicLocation = `${taxonomy.courseOffering}\\${taxonomy.topic}`;
    const clo = taxonomy.contentLearningObjective;
    if (
      clo &&
      documentLocation.includes(courseTopicLocation) &&
      !learningObjectives.includes(clo)
    ) {
      learningObjectives.push(clo);
    }
  }

  return sortLearningObjectives(learningObjectives);
};

/**
 * Sorting algorithm for CLOs
 */
export const sortLearningObjectives = (clos: string[]) => {
  /**
   * split LOs by id and text eg [['1.2.3', 'text (Level 1)'], ['2.1.1', 'text (Level 2)']]
   */
  let losSplit = clos
    .map((lo) => lo.split('.'))
    .map((loSplit) => [
      ...loSplit.slice(0, -1),
      loSplit.at(-1)!.split(' ')[0],
      loSplit.at(-1)!.split(' ').slice(1).join(' ')
    ])
    .map((loSplit) => [loSplit.slice(0, -1).join('.'), loSplit.at(-1)!]);

  /**
   * Sort by id
   */
  losSplit = losSplit.sort((a, b) => {
    let aNumbers = a[0];
    let bNumbers = b[0];

    /**
     * Append points until lengths match
     */
    while (bNumbers.split('.').length - 1 > aNumbers.split('.').length - 1) {
      aNumbers += '.0';
    }
    while (aNumbers.split('.').length - 1 > bNumbers.split('.').length - 1) {
      bNumbers += '.0';
    }

    if (aNumbers.split('.').length - 1 === bNumbers.split('.').length - 1) {
      // a is smaller if less digits
      if (bNumbers.length > aNumbers.length) {
        return -1;
      }
      // b is smaller if less digits
      if (aNumbers.length > bNumbers.length) {
        return 1;
      }
    }

    // alphabetical comparison of LO id when no of points and digits match
    return aNumbers < bNumbers ? -1 : bNumbers < aNumbers ? 1 : 0;
  });

  /**
   * join LOs id and text
   */
  const losSorted = losSplit.map(
    (loSplit) => `${loSplit.slice(0, -1).join('.')} ${loSplit.at(-1)}`
  );
  return losSorted;
};

export const loIdRegexPattern = /([0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*)/;
export const loIdRegexValidation = /^([0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*)$/;
export const uniqueLoRegexPattern = /[^\\]* \((Level )*(?<level>[\dS])\)/;
const taxonomyLoRegexPattern =
  /([0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*) ([^\\]* \((Level )*(?<level>[\dS])\))/;

/**
 * Determine if string is unique learning objective
 */
export const isUniqueLearningObjective = (text: string): boolean => {
  const exec = uniqueLoRegexPattern.exec(text);
  return exec ? true : false;
};

export const uniqueLearningObjectiveLevel = (text: string): string | null => {
  const exec = uniqueLoRegexPattern.exec(text);
  return exec && exec.groups ? exec.groups['level'] : null;
};

/**
 * Determine if taxonomy string contains is learning objective
 */
export const taxonomyContainsLearningObjective = (text: string): boolean => {
  const exec = taxonomyLoRegexPattern.exec(text);
  return exec ? true : false;
};

/**
 * Extract parts from learning objective
 */
export const taxonomy2LearningObjectiveWithId = (lo: string): string | null => {
  const exec = taxonomyLoRegexPattern.exec(lo);
  return exec ? exec[0] : null;
};

export const taxonomy2LearningObjectiveId = (lo: string): string | null => {
  const exec = taxonomyLoRegexPattern.exec(lo);
  return exec ? exec[1] : null;
};

export const taxonomy2LearningObjectiveWithoutId = (
  lo: string
): string | null => {
  const exec = taxonomyLoRegexPattern.exec(lo);
  return exec ? exec[3] : null;
};
