/**
 * HTML Teaching Point
 */

import { TeachingPointProps } from 'app/libraries/html2blocks/blocks/tp.types';

export function TeachingPoint(
  id: string,
  text: string,
  props: TeachingPointProps,
  position: string
) {
  return `
  <div 
    id="block-element-${id}" 
    class="mt-2 mb-2" 
    data-block 
    data-type="tp" 
    data-tp-text="${text}"
    data-element-pos="${position}"
  >
    <div class="flex items-center p-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800" role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" fill="#fffbd1" stroke-width="1.5" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
      </svg>
      <span class="sr-only">${text}</span>
      <div>
      <p class="text-xs">Teaching point</p>
      <p class="text-sm font-bold">${text}</p>
      </div>
    </div>
  </div>
  `;
}
