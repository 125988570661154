/* eslint-disable prettier/prettier */

import { htmlBeautify } from '../utils/htmlBeautify';
import { Banner } from './template/banner';
import { Breadcrumb } from './template/breadcrumb';
import { Main } from './template/main';
import { Footer } from './template/footer';
import { Navbar } from './template/navbar';
import { Settings } from './template/settings';
import { TableOfContents } from './template/toc';
import { Disclaimer } from './template/disclaimer';
// import { MasterDocumentObj2HtmlCreationObject } from '../../@types/master.obj2html.types';
import { DocumentEditorObject } from 'app/types/service/document.types';
import { getAppHTMLConfig } from '../config/getLocalHtmlConfig';
import { getBuildHTMLConfig } from '../config/getBuildHtmlConfig';

// Screen-reader ignore = aria-hidden="true"

const localPreviewHtmlJavaScript = `
  <script>
    window.addEventListener("load", (event) => {
      const el = document.querySelector("html");

      const styles = window.getComputedStyle(el);
      const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

      const height = Math.ceil(el.offsetHeight + margin);

      window.parent.postMessage({
          type: "resize",
          value: height
      }, "*");
    });
  </script>
`;

/**
 * Google Analytics
 * Insert directly after the opening <head> tag
 */
// const googleAnalyticsHTML = `
// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-N543LDDGL1"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-N543LDDGL1');
// </script>`

/**
 * Config
 * @preview is used for local preview in the Toolbox
 * @build is used for the build process whenever the POST /documents endpoint is called
 */
export type DocumentHTMLBuildProcess = 'preview' | 'build';

/**
 * Create the HTML Document
 */
export default function document(
  process: DocumentHTMLBuildProcess,
  document: DocumentEditorObject,
  studentResourceUrl?: string
) {
  let config = getBuildHTMLConfig();
  if (process === 'preview') config = getAppHTMLConfig();

  /**
   * HTML Template
   */
  const template = `
        <!DOCTYPE html>
        <html lang="en">
            <head>
              <!-- Page Metadata -->
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="ie=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta name="version" content="1.0.0">
              <meta name="oeq" content="new">
              <meta id="courseStudentResourceUrl" name="student-resource" content="${studentResourceUrl ? studentResourceUrl : ''
    }">

              <!-- Head Links -->
              <link rel="icon" type="image/x-icon" href="${config.favicon}">
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link rel="stylesheet" href="${config.fonts.google}">
              
              <!-- JavaScript -->
              <script src="${config.scripts.flowbite}"></script>
              <script src="${config.scripts.spotlight}"></script>
              <script>
              MathJax = {
                tex: {
                  displayMath: [['$$', '$$']],
                },
                chtml: {
                  displayAlign: "center",
                  scale: 1.25
                }
              };
              </script>
              <script id="MathJax-script" async src="${config.scripts.mathjax
    }"></script>
              
              <!-- Styles -->
              <link rel="stylesheet" href="${config.styles.main}">
              <link rel="stylesheet" href="${config.styles.spotlight}">
              <link rel="stylesheet" href="${config.styles.overrides}">

              <!-- Page Title -->
              <title>${document?.document?.heading}</title>
            </head>
            <body class="bg-white dark:bg-gray-900">

                <!-- Banner -->
                ${Banner().trim()}

                <!-- Navbar -->
                ${Navbar(config.logo).trim()}

                <!-- Breadcrumb -->
                ${Breadcrumb(document?.document?.location).trim()}

                <!-- Table of Contents -->
                ${TableOfContents().trim()}

                <!-- Page Settings -->
                ${Settings().trim()}

                <!-- Container -->
                ${Main(document, process).trim()}

                <!-- Footer -->
                ${Footer().trim()}

                <!-- Disclaimer -->
                ${Disclaimer().trim()}

                ${process === 'preview' ? localPreviewHtmlJavaScript : ''}
                
                <script src="${config.scripts.main}"></script>
            </body>
        </html>
    `;
  return htmlBeautify(template);
}
