import { Block } from '../../../types/libraries/block.types';

const EDITOR_VERSION = '2.26.5';

/**
 * Generate the EditorJS Object
 */
export function generateContentEditorObject(blocks: Block[]) {
  return {
    time: new Date().getTime(),
    blocks,
    version: EDITOR_VERSION
  };
}
