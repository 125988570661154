import { DefaultProps } from '../../blocks/common.types';
import { createErrorBlock } from '../../blocks/error';
import { ErrorBlock } from '../../blocks/error.types';
import { createTeachingPointBlock } from '../../blocks/tp';
import { TeachingPointBlock } from '../../blocks/tp.types';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';
import { htmlToInlineContent } from '../../utils/htmlToInlineContent';

export function teachingPointElementToBlock(
  element: HTMLElement
): TeachingPointBlock | ErrorBlock {
  const blockId = extractBlockIdFromElementId(element.id);
  const text = htmlToInlineContent(element?.dataset?.tpText || '');

  const blockPropsJSONEncoded = element.dataset.props;
  if (!blockPropsJSONEncoded) {
    return createErrorBlock(
      `Failed to create Teaching Point with ID "${blockId}", missing props.`,
      '',
      blockId
    );
  }
  const blockPropsJSON = decodeURI(blockPropsJSONEncoded);
  let blockProps: DefaultProps;
  try {
    blockProps = JSON.parse(blockPropsJSON);
  } catch (e) {
    return createErrorBlock(
      `Failed to parse Teaching Point block props with ID "${blockId}", ${e}`,
      '',
      blockId
    );
  }

  return createTeachingPointBlock(text, blockProps, blockId);
}
