import { Typography, Divider, Box, Button, Grid } from '@mui/material';
import { Container } from '@mui/system';
import {
  CourseLearningObjective,
  CourseTopic
} from 'app/types/service/course-manager/course.types';
import { DocumentLearningObjectivesEditor } from './DocumentLearningObjectivesEditor';
import { useEffect, useState } from 'react';
import { LearningObjectiveChip } from './LearningObjectiveChip';
import { ListEditor } from './ListEditor';
import {
  uniqueLoRegexPattern,
  loIdRegexValidation
} from 'app/service/utils/learningObjectives';

export const TopicLearningObjectives = (props: {
  courseTopics: CourseTopic[];
  courseTopicIndex: number;
  availableLearningObjectives: CourseLearningObjective[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const topic = props.courseTopics[props.courseTopicIndex];
  const topicLearningObjectives = topic.learningObjectives ?? [];

  const [localLearningObjectives, setLocalLearningObjectives] = useState(
    topicLearningObjectives
  );

  const [learningObjectivesBuilt, setLearningObjectivesBuilt] = useState(false);
  const saveLearningObjectives = () => setLearningObjectivesBuilt(true);
  const editLearningObjectives = () => {
    props.setEditing(true);
    setLearningObjectivesBuilt(false);
  };

  const saveTopics = () => {
    const newCourseTopics = [...props.courseTopics];
    let topic = newCourseTopics[props.courseTopicIndex];
    topic.learningObjectives = localLearningObjectives
      .map((lo) => {
        // recover ulo since ListEditor component drops the property
        const alo = props.availableLearningObjectives.find(
          (alo) => lo.stringValue === alo.stringValue
        );
        if (!alo) return undefined;
        return {
          ...alo,
          prefixStringValue: lo.prefixStringValue,
          loId: lo.prefixStringValue
        };
      })
      .filter((lo) => lo !== undefined);
    newCourseTopics[props.courseTopicIndex] = topic;
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    setLocalLearningObjectives(
      localLearningObjectives.filter((lo) =>
        props.availableLearningObjectives
          .map((lo) => lo.stringValue)
          .includes(lo.stringValue)
      )
    );
  }, [props.availableLearningObjectives]);

  useEffect(() => {
    saveLearningObjectives();
    saveTopics();
  }, [localLearningObjectives]);

  return (
    <Container sx={{ my: '1em' }} key={`apply-los`}>
      <Box key={`apply-los-topic-${props.courseTopicIndex}`}>
        <Typography variant="h4">{`${topic.prefixStringValue} ${topic.stringValue}`}</Typography>
        {learningObjectivesBuilt && (
          <Box>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="caption">{`Learning Objectives (${localLearningObjectives.length})`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={props.availableLearningObjectives.length === 0}
                  fullWidth
                  onClick={editLearningObjectives}
                >
                  Add LOs
                </Button>
              </Grid>
            </Grid>
            <Container>
              {topic.learningObjectives?.map((lo, index) => (
                <LearningObjectiveChip
                  key={`topic-document-learning-objective-${index}`}
                  courseLearningObjective={lo}
                />
              ))}
            </Container>
          </Box>
        )}
        {!learningObjectivesBuilt && (
          <ListEditor
            title={''}
            listItemName={'LO'}
            listSelectChoices={props.availableLearningObjectives}
            includesPrefix={true}
            itemRegexValidation={uniqueLoRegexPattern}
            prefixName="LO ID"
            prefixRegexValidation={loIdRegexValidation}
            list={localLearningObjectives}
            setList={setLocalLearningObjectives}
          />
        )}
        {topic.documents && (
          <Typography variant="caption">{`Documents (${topic.documents.length})`}</Typography>
        )}

        <Divider />
        {topic.documents &&
          topic.documents.map((d, documentIndex) => (
            <DocumentLearningObjectivesEditor
              key={`course-topic-${props.courseTopicIndex}-document-${documentIndex}`}
              courseTopics={props.courseTopics}
              courseTopicIndex={props.courseTopicIndex}
              courseDocumentIndex={documentIndex}
              availableLearningObjectives={topicLearningObjectives}
              setCourseTopics={props.setCourseTopics}
              setEditing={props.setEditing}
            />
          ))}
      </Box>
    </Container>
  );
};
