import { GenerateAccordion } from './accordion';
import { LearningObjectives } from './blocks/styled-elements/lo-group';
import { htmlBeautify } from '../../utils/htmlBeautify';
import { ContentEditorObject } from 'app/types/service/content.types';
import { DocumentHTMLBuildProcess } from '..';
import { contentsToLearningObjectives } from 'app/service/utils/learningObjectives';

/**
 * Create the HTML Content
 */
export default function styledContent(
  documentLocation: string,
  content: ContentEditorObject,
  process: DocumentHTMLBuildProcess
): any {
  const htmlSchemaVersion = '1.0.0';

  /**
   * Extract blocks and learning objectives
   */
  const contentUuid = content.resource.uuid;
  const contentVersion = content.resource.version;
  const contentEditorBlockObj = content.editor!;
  const learningObjectivesArray: string[] = contentsToLearningObjectives(
    documentLocation,
    [content]
  );

  /**
   * Convert them to HTML
   */
  const learningObjectivesHtml = LearningObjectives(
    contentUuid,
    learningObjectivesArray
  );
  const contentHtml = GenerateAccordion(
    content.resource.uuid,
    contentEditorBlockObj,
    process
  );
  return htmlBeautify(`
  <div 
      id="content-${contentUuid}"
      class="content-resource"
      data-meta-oeq="new"
      data-version="${htmlSchemaVersion}"
      data-content-uuid="${contentUuid}"
      data-content-version="${contentVersion}">
        <h2 
          id="content-${contentUuid}-heading" 
          class="text-xl md:text-3xl font-bold text-aa-blue dark:text-aa-blue mt-8 mb-3"
        >${content.content.heading}</h2>
        ${learningObjectivesHtml}
        ${contentHtml}
    </div>
  `);
}
