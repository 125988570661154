import { Alert, Container, Divider, Typography } from '@mui/material';
import MainCard from 'components/app/MainCard';
import { DocumentContentsEditor } from 'pages/course-manager/components/DocumentContentsEditor';
import { CourseTopic } from 'app/types/service/course-manager/course.types';
import React, { useEffect } from 'react';

export const AddContent = (props: {
  courseCode: string;
  courseTitle: string;
  courseTopics: CourseTopic[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setPreventNext: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  /**
   * Prevent continue if document has no content or content has no learning objectives
   */
  useEffect(() => {
    for (let i = 0; i < props.courseTopics.length; i++) {
      const topic = props.courseTopics[i];
      const documents = topic.documents;
      if (!documents) continue;
      for (let j = 0; j < documents.length; j++) {
        const document = documents[j];
        if (!document.contents || document.contents.length === 0) {
          props.setPreventNext(true);
          return;
        }

        for (let k = 0; k < document.contents.length; k++) {
          const content = document.contents[k];
          if (
            !content.learningObjectives ||
            content.learningObjectives.length === 0
          ) {
            props.setPreventNext(true);
            return;
          }
        }
      }
      props.setPreventNext(false);
    }
  }, [props.courseTopics]);

  return (
    <div>
      <MainCard sx={{ my: '1em' }}>
        <Typography variant="h3">
          {props.courseCode} {props.courseTitle}
        </Typography>
        <Typography variant="caption">{`Topics (${props.courseTopics.length})`}</Typography>
        <Divider />
        {props.courseTopics.map((topic, topicIndex) => (
          <Container key={`add-content-topic-${topicIndex}`} sx={{ my: '1em' }}>
            <Typography variant="h4">{`${topic.prefixStringValue} ${topic.stringValue}`}</Typography>
            {topic.documents && (
              <Typography variant="caption">{`Documents (${topic.documents.length})`}</Typography>
            )}
            <Divider />
            {!topic.documents ? (
              <Alert severity="warning">
                {topic.stringValue} has no documents.
              </Alert>
            ) : (
              topic.documents.map((document, documentIndex) => (
                <DocumentContentsEditor
                  key={`link-content-document-${documentIndex}`}
                  courseTopics={props.courseTopics}
                  courseTopicIndex={topicIndex}
                  courseDocumentIndex={documentIndex}
                  setCourseTopics={props.setCourseTopics}
                  setEditing={props.setEditing}
                  setPreventNext={props.setPreventNext}
                />
              ))
            )}
          </Container>
        ))}
      </MainCard>
      <Alert severity="info">
        Documents must have atleast 1 content, and content must have atleast 1
        learning objective.
      </Alert>
    </div>
  );
};
