/**
 * Removes specified characters from a string.
 * @param {string} str
 * @return {string} A filtered string
 */
export function removeFluffFromString(str: string): string {
  return str
    .replace(/\s+/g, ' ')
    .replace(/&nbsp;/gi, '')
    .replace(/â€™/g, "'")
    .replace(/ >/g, '>')
    .replace(/> </g, '><')
    .replace('|', '')
    .replace('<br>', '')
    .trim();
}

export function sanitiseParagraph(str: string): string {
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/\s{2,}/g, ' ')
    .replace(/[\r\n]+/g, '')
    .replace(/&nbsp;/gi, '')
    .replace(/â€™/g, "'")
    .replace(/â€˜/g, "'")
    .trim()
    .replace(/<br>$/g, '');
}
