import { inlineContentToExplicitHtml } from 'app/libraries/blocks2html/utils/inlineContentToHtml';
import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';
import {
  ListBlock,
  ListProps,
  ListType
} from 'app/libraries/html2blocks/blocks/lists.types';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';

/**
 * Create List element
 */
export function List(
  id: string,
  type: ListType,
  content: BlockInlineContent[],
  props: ListProps,
  children: ListBlock[],
  position: string
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);

  let styleIndex = -1;

  function renderItems(listItems: ListBlock[]): string {
    if (!listItems) return '';
    if (!listItems.length) return '';

    const listType = listItems.length > 0 ? listItems[0].type : null;
    if (!listType) return '';
    const listTagType = listType === 'bulletListItem' ? 'ul' : 'ol';

    // update style index
    const isOrdered = listTagType === 'ol';
    const ulListStyles = ['list-disc', 'list-circle', 'list-square'];
    styleIndex += 1;
    if (styleIndex > 2) styleIndex = 0;
    const arrListStyle = isOrdered ? 'list-decimal' : ulListStyles[styleIndex];

    let listHtml = `<${listTagType} class="${arrListStyle} list-inside pl-4">`;
    for (const item of listItems) {
      const propsJSON = jsonStringifyObjAndHtmlEncode(item.props);
      listHtml += `
    <li
      id="block-element-${item.id}"
      data-block
      data-type="${listType}"
      data-element-pos="${position}"
      data-props="${propsJSON}"
    >${inlineContentToExplicitHtml(item.content)}`;
      if (item.children && item.children.length > 0) {
        listHtml += renderItems(item.children);
      }
      listHtml += `</li>`;
    }
    listHtml += `</${listTagType}>`;

    return listHtml;
  }

  return `
    <li 
      id="block-element-${id}"
      data-block 
      data-type="${type}"
      data-element-pos="${position}"
      data-props='${propsJSON}'
    >
      ${inlineContentToExplicitHtml(content)}
      ${renderItems(children)}
    </li>`;
}

// function renderSublistItems(
//   listItems: ListBlock[],
//   listType: 'ul' | 'ol',
//   position: string
// ): string {
//   if (!listItems) {
//     return '';
//   }

//   let listHtml = `<${listType}>`;

//   for (const item of listItems) {
//     const propsJSON = jsonStringifyObjAndHtmlEncode(item.props);
//     listHtml += `
//     <li
//       id="block-element-${item.id}"
//       data-block
//       data-type="${listType === 'ol' ? 'numberedListItem' : 'bulletListItem'}"
//       data-element-pos="${position}"
//       data-props="${propsJSON}"
//     >${inlineContentToExplicitHtml(item.content)}`;
//     if (item.children && item.children.length > 0) {
//       listHtml += renderSublistItems(item.children, listType, position);
//     }
//     listHtml += `</li>`;
//   }

//   listHtml += `</${listType}>`;

//   return listHtml;
// }
