import { validateOrCreateUuidV4 } from '../utils/uuid';
import { BlockInlineContent, DefaultProps } from './common.types';
import { ParagraphBlock } from './paragraph.types';

export function createParagraphBlock(
  content: BlockInlineContent[],
  props: DefaultProps,
  id?: string
): ParagraphBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'paragraph',
    props,
    content
  };
}

/**
 * This function creates a paragraph block from a text string
 * All of the html should be removed prior to calling this function
 */
export function createParagraphBlockFromText(
  text: string,
  id?: string
): ParagraphBlock {
  const paragraphInlineContent: BlockInlineContent = {
    text: text,
    type: 'text',
    styles: {}
  };
  return createParagraphBlock(
    [paragraphInlineContent],
    { backgroundColor: 'default', textAlignment: 'left', textColor: 'default' },
    id || undefined
  );
}

// export interface ParagraphBlock {
//   id: string;
//   type: 'paragraph';
//   data: {
//     text: string;
//     blockId: string;
//   };
// }

// export function paragraphElementToBlock(
//   element: HTMLElement,
//   elementId: string
// ): ParagraphBlock | null {
//   const text = element.innerHTML.trim();
//   if (!text) return null;
//   return createParagraphBlock(text, elementId);
// }
