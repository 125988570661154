import { CourseLearningObjective } from '../../../app/types/service/course-manager/course.types';
import { Box, Chip } from '@mui/material';
export const LearningObjectiveChip = (props: {
  courseLearningObjective: CourseLearningObjective;
  disableHighlight?: boolean;
}) => {
  return (
    <Box>
      <Chip
        color={
          props.disableHighlight
            ? 'default'
            : props.courseLearningObjective.ulo
              ? 'primary'
              : 'success'
        }
        variant={props.disableHighlight ? 'filled' : 'outlined'}
        sx={{
          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal'
          }
        }}
        label={`${props.courseLearningObjective.loId
            ? `${props.courseLearningObjective.loId} `
            : ''
          } ${props.courseLearningObjective.stringValue}`}
      />
    </Box>
  );
};
