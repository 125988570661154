import { validateOrCreateUuidV4 } from '../utils/uuid';
import { BlockInlineContent } from './common.types';
import { ListBlock, ListProps } from './lists.types';

export function createBulletListBlock(
  content: BlockInlineContent[],
  props: ListProps,
  children: ListBlock[],
  id?: string
): ListBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'bulletListItem',
    props,
    content,
    children
  };
}

export function createNumberedListBlock(
  content: BlockInlineContent[],
  props: ListProps,
  children: ListBlock[],
  id?: string
): ListBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'numberedListItem',
    props,
    content,
    children
  };
}

/**
 * Returns an array of list blocks
 */
// export function createListBlocksFromListArray(
//   items: string,
//   style: 'unordered' | 'ordered',
//   id?: string
// ): ListBlock[] {

//   // for (let i = 0; i < items.length; i++) {

//   // }

// }

// const mapTagNameToStyle = new Map<string, ListStyle>([
//   ['OL', 'ordered'],
//   ['UL', 'unordered']
// ]);

// export function listElementToBlock(
//   element: HTMLElement,
//   elementId: string
// ): ListBlock | null {
//   if (element.tagName === 'DIV') {
//     const child = element.firstElementChild as HTMLElement;
//     return listElementToBlock(child, elementId);
//   } else if (element.tagName === 'UL' || element.tagName === 'OL') {
//     const items = recursiveHtmlListIntoArray(element);
//     const style = mapTagNameToStyle.get(element.tagName);
//     if (!items || !style || !elementId) return null;
//     return createListBlock(items, style, elementId);
//   } else {
//     return null;
//   }
// }
