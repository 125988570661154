/* eslint-disable no-fallthrough */
import { H4, H5 } from './styled-elements/headings';
import { Paragraph } from './styled-elements/paragraph';
import { TeachingPoint } from './styled-elements/tp';
import { Maths } from './styled-elements/maths';
import { List } from './styled-elements/list';
import { Block } from 'app/types/libraries/block.types';
import { Media } from './styled-elements/media';
import { DocumentHTMLBuildProcess } from '../..';
import { HeadingBlock } from 'app/libraries/html2blocks/blocks/heading.types';
import { inlineContentToSemanticHtml } from 'app/libraries/blocks2html/utils/inlineContentToHtml';
import { ParagraphBlock } from 'app/libraries/html2blocks/blocks/paragraph.types';
import { MediaBlock } from 'app/libraries/html2blocks/blocks/media.type';
import { TeachingPointBlock } from 'app/libraries/html2blocks/blocks/tp.types';
import { MathsBlock } from 'app/libraries/html2blocks/blocks/maths.types';
import {
  BulletListItemBlock,
  NumberedListItemBlock
} from 'app/libraries/html2blocks/blocks/lists.types';

function getPosition(index: number, length: number) {
  if (index === 0) return 'first';
  else if (index === length - 1) return 'last';
  else return 'middle';
}

/**
 * Convert editor-js blocks to Aviation Australia HTML elements
 */
export function convertBlocksToStyledHTML(
  blocks: Block[],
  process: DocumentHTMLBuildProcess
): string {
  let html = '';
  let isList = false; // Flag to check if the current block is a list

  for (let index = 0; index < blocks.length; index++) {
    /**
     * Get the current block
     */
    const element = blocks[index];
    if (!element) {
      console.error(`Element ${index} is undefined`);
      continue;
    }

    /**
     * Check the next element
     */
    let nextElement = null;
    if (index < blocks.length - 1) {
      nextElement = blocks[index + 1];
    }

    const elementType = element.type;
    const position = getPosition(index, blocks.length);

    switch (elementType) {
      /**
       * Headers
       */
      case 'heading':
        const header = element as HeadingBlock;
        const headerId = header.id;
        const headerProps = header.props;
        const headerText = inlineContentToSemanticHtml(header?.content);

        if (headerProps.level === 2) {
          html += H4(headerId, headerText, headerProps, position);
          break;
        } else if (headerProps.level === 3) {
          html += H5(headerId, headerText, headerProps, position);
          break;
        } else break;

      /**
       * Paragraph Text
       */
      case 'paragraph':
        const paragraph = element as ParagraphBlock;
        const paragraphId = paragraph.id;
        const paragraphText = inlineContentToSemanticHtml(paragraph.content);
        const paragraphProps = paragraph.props;
        html += Paragraph(paragraphId, paragraphText, paragraphProps, position);
        break;

      /**
       * Media
       */
      case 'media':
        const media = element as MediaBlock;
        const mediaId = media.id;
        const mediaProps = media.props;
        const content = media.content;
        html += Media(mediaId, content, mediaProps, position, process);
        break;

      /**
       * Teaching Point
       */
      case 'tp':
        const tp = element as TeachingPointBlock;
        const tpId = tp.id;
        const tpText = inlineContentToSemanticHtml(tp.content);
        const tpProps = tp.props;
        html += TeachingPoint(tpId, tpText, tpProps, position);
        break;

      /**
       * Maths
       */
      case 'maths':
        const math = element as MathsBlock;
        const mathId = math.id;
        const mathProps = math.props;
        html += Maths(mathId, mathProps, position);
        break;

      /**
       * Lists
       */
      case 'bulletListItem':
        const bulList = element as BulletListItemBlock;
        const bulListId = bulList.id;
        const bulListContent = bulList.content;
        const bulListChildren = bulList.children;
        const bulListProps = bulList.props;
        const bulListClass = 'list-disc';
        if (!isList) {
          html += `<ul class="${bulListClass} list-inside pl-4">`;
          isList = true;
        }
        html += List(
          bulListId,
          'ul',
          bulListContent,
          bulListChildren,
          bulListProps,
          position
        );
        if (isList && nextElement?.type !== 'bulletListItem') {
          html += '</ul>';
          isList = false;
        }
        break;

      /**
       * Lists
       */
      case 'numberedListItem':
        const numList = element as NumberedListItemBlock;
        const numListId = numList.id;
        const numListContent = numList.content;
        const numListChildren = numList.children;
        const numListProps = numList.props;
        const numListClass = 'list-decimal';
        if (!isList) {
          html += `<ol class="${numListClass} list-inside pl-4">`;
          isList = true;
        }
        html += List(
          numListId,
          'ol',
          numListContent,
          numListChildren,
          numListProps,
          position
        );
        if (isList && nextElement?.type !== 'numberedListItem') {
          html += '</ol>';
          isList = false;
        }
        break;

      /**
       * Slide
       * Not rendered as HTML
       */
      case 'slide':
        break;

      /**
       * Unknown
       */
      default:
        console.log(`Unknown element type: ${element.type}`);
        break;
    }
  }
  return html;
}
