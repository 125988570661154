import { lazy } from 'react';

// project import
import Loadable from 'components/@mantis/Loadable';
import MainLayout from 'layout/MainLayout';
import EditorLayout from 'layout/EditorLayout';
import AuthGuard from 'routes/guard/AuthGuard';
import AuthRouter from 'pages/auth/AuthRouter';
import CourseManager from 'pages/course-manager';
import { CourseBuilder } from 'pages/course-manager/builder';

const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')));

// Courseware
const CoursesPage = Loadable(lazy(() => import('pages/courses')));
const CoursePage = Loadable(lazy(() => import('pages/course')));
const CourseAutopilotPage = Loadable(
  lazy(() => import('pages/course/autopilot'))
);
const DocumentPage = Loadable(lazy(() => import('pages/document')));
const EditorPage = Loadable(lazy(() => import('pages/editor')));
const LearningObjectivesPage = Loadable(
  lazy(() => import('pages/learning-objectives'))
);
const WorkflowsPage = Loadable(lazy(() => import('pages/workflows')));
const AutopilotAdminPage = Loadable(lazy(() => import('pages/autopilot')));

// Developer
const CourseMigration = Loadable(lazy(() => import('pages/migration')));
const DataMappingPage = Loadable(lazy(() => import('pages/data/mapping')));
const DataUnitsPage = Loadable(lazy(() => import('pages/data/units')));
const TestingPage = Loadable(lazy(() => import('pages/developer')));
const EmptyStateIcons = Loadable(
  lazy(() => import('pages/developer/testing/icons'))
);
// const ApiDocs = Loadable(lazy(() => import('pages/developer/apidocs')));
// const DrawersPage = Loadable(lazy(() => import('pages/developer/drawers')));

// Maintenance
const Error404 = Loadable(lazy(() => import('pages/_maintenance/404')));
const Error500 = Loadable(lazy(() => import('pages/_maintenance/500')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <>
            <AuthRouter />
            <MainLayout />
          </>
        </AuthGuard>
      ),
      children: [
        {
          path: 'login',
          element: <></>
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        // Courseware
        {
          path: 'courses',
          element: <CoursesPage />
        },
        {
          path: 'course/:id',
          element: <CoursePage />
        },
        {
          path: 'course/:id/autopilot',
          element: <CourseAutopilotPage />
        },
        {
          path: 'document/:documentId',
          element: <DocumentPage />
        },
        {
          path: 'workflows',
          element: <WorkflowsPage />
        },
        {
          path: 'course-manager',
          element: <CourseManager />
        },
        {
          path: 'course-manager/builder',
          element: <CourseBuilder />
        },
        {
          path: 'learning-objectives',
          element: <LearningObjectivesPage />
        },
        {
          path: 'autopilot',
          element: <AutopilotAdminPage />
        },
        // Developer
        {
          path: 'migration',
          element: <CourseMigration />
        },
        {
          path: 'data/mapping',
          element: <DataMappingPage />
        },
        {
          path: 'data/units',
          element: <DataUnitsPage />
        },
        // Developer
        {
          path: 'testing',
          element: <TestingPage />
        },
        {
          path: 'icons',
          element: <EmptyStateIcons />
        }
        // {
        //   path: 'apidocs',
        //   element: <ApiDocs />
        // },
        // {
        //   path: 'drawers',
        //   element: <DrawersPage />
        // }
      ]
    },
    {
      path: '/editor',
      element: (
        <AuthGuard>
          <EditorLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <EditorPage />
        },
        {
          path: ':documentId',
          element: <EditorPage />
        }
      ]
    },
    /**
     * @Error404
     * If any other pages are navigated to show a 404 (not found).
     */
    {
      path: '*',
      element: <Error404 />
    },
    /**
     * @Error500
     * If a server error / disconnection occurs, navigate to the server error page.
     */
    {
      path: 'error',
      element: <Error500 />
    }
  ]
};

export default MainRoutes;
