/* eslint-disable prettier/prettier */
import { inlineContentToExplicitHtml } from 'app/libraries/blocks2html/utils/inlineContentToHtml';
import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';
import { getMediaTypeFromFormat } from 'app/libraries/html2blocks/blocks/media';
import { MediaProps } from 'app/libraries/html2blocks/blocks/media.type';
import { createHangarApiUrl } from 'app/service/utils/hangar-urls';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';
import { ContentHTMLCreationProcess } from '../..';
// import { createRuffleUrl } from 'app/libraries/blocks2html/utils/createRuffleUrl';

/**
 * Media
 */
export function Media(id: string, content: BlockInlineContent[], props: MediaProps, position: string, process: ContentHTMLCreationProcess) {
  /**
   * Create HTML based on the type of media
   */
  let innerHtml = '';
  const mediaType = getMediaTypeFromFormat(props.format);
  const caption = inlineContentToExplicitHtml(content);

  switch (mediaType) {
    case 'image':
      innerHtml = createImageInnerHTML(caption, props, process);
      break;
    case 'iframe':
      innerHtml = createIframeInnerHTML(caption, props, process);
      break;
    case 'ruffle':
      innerHtml = createRuffleInnerHTML(caption, props, process);
      break;
    case 'html5':
      // TODO: Implement HTML5 media type
      innerHtml = `<p>View HTML5 interactive in Launchpad</p>`;
      break;
    default:
      console.warn(
        `An unsupported media type "${mediaType}" has been ignored during the obj2html conversion.`
      );
      break;
  }

  const propsJSON = jsonStringifyObjAndHtmlEncode(props);

  const outerHtml = `
    <div 
      id="block-element-${id}" 
      data-block
      data-type="media" 
      data-props="${propsJSON}"
      data-caption="${caption}"
      data-element-pos="${position}"
    >
      ${innerHtml}
    </div>
  `;

  return outerHtml;
}

/**
 * This creates viewable HTML for an image media type
 * Technically, this does not need to render the image, but a developer may want to use this output into the HTML Content collection for troubleshooting.
 */
function createImageInnerHTML(caption: string, props: MediaProps, process: ContentHTMLCreationProcess) {
  const imageUrl = createHangarApiUrl(
    props.itemUuid,
    props.itemVersion,
    props.attachmentUuid
  );
  return `
  <div ${process === 'view' ? 'style="text-align: center;"' : ''}>
  <img 
    src="${imageUrl}" 
    style="max-height: ${props.maxHeight}px; max-width: 100%" 
    alt="${caption}"
  ></img>
  ${process === 'view' ? '' : `<p style="text-align: right;">${props.attribution}</p>`}
  <p style="text-align: center;">${caption}</p>
  </div>`;
}

function createIframeInnerHTML(caption: string, props: MediaProps, process: ContentHTMLCreationProcess) {
  const embedUrl = createHangarApiUrl(
    props.itemUuid,
    props.itemVersion,
    props.attachmentUuid
  );
  return `
  <div ${process === 'view' ? 'style="text-align: center;"' : ''}>
  <iframe 
    style="margin: auto; max-width: 100%; max-height: 60.02vw;"
    width="640px"
    height="385px"
    src="${embedUrl}"
    title="${caption}"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
  <p style="text-align: center;">${caption}</p>
  </div>
  `;
}

function createRuffleInnerHTML(caption: string, props: MediaProps, process: ContentHTMLCreationProcess) {
  // const embedUrl = createHangarApiUrl(
  //   props.itemUuid,
  //   props.itemVersion,
  //   props.attachmentUuid
  // );
  // const ruffleUrl = createRuffleUrl(embedUrl);
  // return `
  // <div ${process === 'view' ? 'style="text-align: center;"' : ''}>
  // <iframe 
  //   style="margin: auto; max-width: 100%; max-height: 60.02vw;"
  //   width="640px"
  //   height="385px"
  //   src="${ruffleUrl}"
  //   title="${caption}"
  //   frameborder="0"
  //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //   allowfullscreen
  // ></iframe>
  // <p style="text-align: center;">${caption}</p>
  // </div>
  // `;
  return `<p style="color: #8c8c8c;">View the Flash content in the slide deck above, or visit Launchpad</p>`
}
