import { EditorBlockObject } from '../../../types/libraries/content.obj2html.types';
import { htmlCollectionToArray } from '../utils/html';
import { Block } from '../../../types/libraries/block.types';
import { generateContentEditorObject } from '../utils/editorjs';
import { createErrorBlock } from '../blocks/error';
import { headingElementToBlock } from './blocks/headingToBlock';
import { paragraphElementToBlock } from './blocks/paragraphToBlock';
import { mathsElementToBlock } from './blocks/mathsToBlock';
import { slideElementToBlock } from './blocks/slideToBlock';
import { teachingPointElementToBlock } from './blocks/tpToBlock';
import { mediaElementToBlock } from './blocks/mediaToBlock';
import { listElementToBlocks } from './blocks/listToBlock';

/**
 * Convert a stored HTML content file into an editor object
 */
export default function html2blocksV2(html: string): EditorBlockObject | null {
  var parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  const container = document?.getElementById('container');
  if (container) {
    const containerChildren = htmlCollectionToArray(container.children);
    const blocks = containerChildren
      .map((child) => createBlocks(child))
      .reduce((accumulator, value) => accumulator.concat(value), []);
    return generateContentEditorObject(blocks);
  } else {
    return null;
  }
}

/**
 * Create blocks from HTML elements
 */
const createBlocks = (element: HTMLElement): Block[] => {
  const type = element.dataset.type;

  let blocks: Block[] = [];
  switch (type) {
    /**
     * Heading
     */
    case 'heading': {
      const headerBlock = headingElementToBlock(element);
      blocks.push(headerBlock);
      break;
    }
    /**
     * Paragraph
     */
    case 'paragraph': {
      const paragraphBlock = paragraphElementToBlock(element);
      blocks.push(paragraphBlock);
      break;
    }
    /**
     * Maths
     */
    case 'maths': {
      const mathsBlock = mathsElementToBlock(element);
      blocks.push(mathsBlock);
      break;
    }
    /**
     * Lists
     */
    case 'list': {
      const listBlock = listElementToBlocks(element);
      listBlock.forEach((block) => blocks.push(block));
      break;
    }
    /**
     * Media
     */
    case 'media': {
      const mediaBlock = mediaElementToBlock(element);
      blocks.push(mediaBlock);
      break;
    }
    /**
     * Teaching Point
     */
    case 'tp': {
      const teachingPointsBlock = teachingPointElementToBlock(element);
      blocks.push(teachingPointsBlock);
      break;
    }
    /**
     * Slide
     */
    case 'slide': {
      const slideBlock = slideElementToBlock(element);
      // console.log('html2blocks / map / index');
      // console.log(slideBlock);
      blocks.push(slideBlock);
      break;
    }
    /**
     * DIV
     */
    // case 'div': {
    //   const childrenElements = htmlCollectionToArray(element.children);
    //   for (let i = 0; i < childrenElements.length; i++) {
    //     const child = childrenElements[i];
    //     const childBlocks = createBlocks(child);
    //     blocks.concat(childBlocks);
    //   }
    //   break;
    // }
    /**
     * Error
     */
    default: {
      blocks.push(
        createErrorBlock(
          `Undefined block from tagname: ${element.tagName}`,
          element.innerHTML,
          element.id
        )
      );
      break;
    }
  }
  return blocks;
};
