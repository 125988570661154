/**
 * ==========================================================================================
 * Page Disclaimer
 * Used to show a warning before students download a PDF
 * ==========================================================================================
 * Instructions:
 * To trigger this modal, add the following to a HTML button:
 * > data-modal-target="page-disclaimer-modal" data-modal-toggle="page-disclaimer-modal"
 * Note: Ensure the id of the modal matches the data-modal-target and data-modal-toggle
 */
export function Disclaimer() {
  return `
        <div 
            id="page-disclaimer-modal" 
            tabindex="-1" 
            aria-hidden="true" 
            class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            
            <div class="relative w-full max-w-2xl max-h-full">
                
                <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <!-- Modal header -->
                    <div id="page-disclaimer-modal-header" class="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-600">
                        <div>
                            <p id="page-disclaimer-modal-title" class="text-xl font-bold text-aa-blue lg:text-2xl dark:text-white">
                                Notice to Students
                            </p>
                            <p id="page-disclaimer-modal-subtitle" class="text-sm font-medium text-gray-500 dark:text-white">Offline Courseware Disclaimer</p>
                        </div>
                        <button type="button" onclick="toggleDisclaimerModal();" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                        </button>
                    </div>

                    <!-- Modal body -->
                    <div id="page-disclaimer-modal-body" class="p-6 space-y-6">
                        <p class="text-sm text-gray-500 dark:text-gray-400"><strong>Once downloaded or printed, this document is uncontrolled and can become out of date.</strong></p>
                        <p class="text-sm text-gray-500 dark:text-gray-400">
                            Aviation Australia continuously reviews and frequently updates it's courseware to ensure up-to-date and correct information. To ensure you have the latest version please check the date at the bottom of any page and compare it to the version in Launchpad. If you are attending Aviation Australia via face-to-face training or are sitting a regulatory examination please ensure you are using the latest version of the resource.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-gray-400">
                            By electing to download/print you agree that you are AWARE that this version is only current at the time of downloading/printing and any future updates will not be included and may not reflect examination questions.
                        </p>
                    </div>

                    <!-- Modal footer -->
                    <div id="page-disclaimer-modal-footer" class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button 
                            type="button" 
                            onclick="disclaimerButtonHandler();"
                            class="text-white bg-aa-blue hover:bg-aa-navy focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Continue
                        </button>
                        <button 
                            type="button"
                            onclick="toggleDisclaimerModal();"
                            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;
}
