import { validateOrCreateUuidV4 } from '../utils/uuid';
import { BlockInlineContent, DefaultProps } from './common.types';
import { TeachingPointBlock } from './tp.types';

/**
 * Create Teaching Point editor-js block data
 */
export function createTeachingPointBlock(
  content: BlockInlineContent[],
  props: DefaultProps,
  id?: string
): TeachingPointBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'tp',
    props,
    content
  };
}

export function createTPBlockFromText(
  text: string,
  id?: string
): TeachingPointBlock {
  const tpInlineContent: BlockInlineContent = {
    text: text,
    type: 'text',
    styles: {}
  };
  return createTeachingPointBlock(
    [tpInlineContent],
    { backgroundColor: 'default', textAlignment: 'left', textColor: 'default' },
    id
  );
}

// export function teachingPointElementToBlock(
//   element: HTMLElement,
//   elementId: string
// ): TeachingPointBlock | null {
//   const text = element.innerHTML.trim();
//   if (!text) return null;
//   return createTeachingPointBlock(text, elementId);
// }
