import {
  ContentToLink,
  CourseContent
} from 'app/types/service/course-manager/course.types';
import { createContent } from './create';
import { updateContent } from './update';

export const upsertContent = async (
  content: CourseContent,
  courseUuid: string,
  courseTaxonomy: string,
  topicId: string | undefined,

  userId: string,
  accessToken: string
): Promise<ContentToLink | null> => {
  const contentUuid = content.uuid;

  const taxonomies =
    content.learningObjectives?.map(
      (lo) => `${courseTaxonomy}\\${topicId}\\${lo.loId} ${lo.stringValue}`
    ) ?? [];

  const contentLearningObjectives = taxonomies
    .map((taxonomy) => taxonomy.split('\\').at(-1))
    .filter((lo) => lo !== undefined);

  if (contentUuid) {
    /**
     * Existing content
     */
    const postItemResponse = await updateContent(
      contentUuid,
      taxonomies,
      content,
      courseUuid,
      courseTaxonomy,
      topicId,
      accessToken
    );
    if (!postItemResponse) {
      console.error('Failed to update existing content');
      console.log(content);
      return null;
    }
    const existingContentToLink: ContentToLink = {
      ...content,
      postItemResponse,
      contentLearningObjectives,
      taxonomies
    };
    return existingContentToLink;
  } else {
    /**
     * New content
     */
    const postItemResponse = await createContent(
      taxonomies,
      content,
      courseUuid,
      courseTaxonomy,
      topicId,
      userId,
      accessToken
    );
    if (!postItemResponse) {
      console.error('Failed to create new content');
      console.log(content);
      return null;
    }
    const newContentToLink: ContentToLink = {
      ...content,
      postItemResponse,
      contentLearningObjectives,
      taxonomies
    };
    return newContentToLink;
  }
};
