/**
 * Check if value is primitive value according to MDN docs
 */
export function isPrimitiveValue(value: any) {
  return (
    typeof value === 'symbol' ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'undefined' ||
    value === null ||
    typeof value === 'bigint'
  );
}

/**
 * Check if object has a key
 */
export function objectHasKey(key: string, object: object) {
  return key in object;
}

/**
 * Check if value is an object - if primitive - no, else yes
 * This is because arrays etc are considered objects to JS
 */
export function isObject(input: any) {
  if (isPrimitiveValue(input)) {
    return false;
  }
  return true;
}

/**
 * Exclude keys from an object
 */
export function excludeKeysFromObject(
  object: any,
  listOfKeysToExclude: string[]
) {
  const filteredObject = (({
    // exclude
    metadata,
    linkedResources,
    registry,
    taxonomies,
    topics,
    // keep the rest
    ...keep
  }) => keep)(object);

  return filteredObject;
}

/**
 * Map an Object (similar to array map)
 * Usage: objectMap(myObject, v => 2 * v)
 */
export function objectMap(obj: object, fn: Function) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
  );
}

type NestedObject = {
  [key: string]: any;
};

/**
 * Update values in a nested object
 */
export function updateObjectField(
  key: string | string[],
  value: any,
  previousData: NestedObject
): NestedObject {
  // Clone the previousData object to avoid modifying the original object
  const newData: NestedObject = { ...previousData };

  // If the key is a string, convert it to an array for easier handling
  const keysArray = Array.isArray(key) ? key : key.split('.');

  // Helper function to traverse the object and update the nested field
  const updateNestedField = (
    obj: NestedObject,
    keys: string[],
    val: any
  ): NestedObject => {
    if (keys.length === 1) {
      // Base case: update the final nested field
      obj[keys[0]] = val;
    } else {
      // Recursively traverse the nested objects
      const currentKey = keys.shift();
      if (currentKey !== undefined) {
        obj[currentKey] = updateNestedField(obj[currentKey] || {}, keys, val);
      }
    }
    return obj;
  };

  // Call the helper function with the newData and keysArray
  updateNestedField(newData, keysArray, value);

  return newData;
}

export function jsonStringifyObjAndHtmlEncode(obj: any): string {
  const objJSON = JSON.stringify(obj);
  const objJSONEncoded = encodeURI(objJSON);
  return objJSONEncoded;
}

// /**
//  * Convert an object to a query string
//  */
// export function duplicateObjectWithoutProperties(obj: Object, keys: string[]) {
//   var target = {};
//   for (var i in obj) {
//     if (keys.indexOf(i) >= 0) continue;
//     if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
//     target[i] = obj[i];
//   }
//   return target;
// }
