import { axiosRequestJSON } from '../../../axios/axiosRequests';
import { getDocumentsEndpoint } from '../endpoints/documents';
import { getHangarApiHeaders } from '../headers/headers';
import {
  PostDocumentRequestBody,
  PublishDocumentRequestBody
} from '../../../types/integration/documents.api.types';
import { buildQueryParams } from '../utils/urls';
import { PostMasterSaveBodyObject } from 'app/types/integration/editor.api.types';
import { TasksParams } from 'app/types/integration/common.api.types';

/**
 * ==========================================================>>
 * @GET /master/{uuid} - Searchs the HTML Master Courseware collection
 * ==========================================================>>
 */
export async function searchMasterDocuments(
  params: object,
  accessToken: string
) {
  const searchParams = buildQueryParams(params);
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}?${searchParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * @GET /document/{uuid} - Gets a SSC document
 * ==========================================================>>
 */
export async function getDocument(uuid: string, accessToken: string) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * @GET /document/{uuid}/moderating - Gets a SSC document
 * ==========================================================>>
 */
export async function getLatestDocument(uuid: string, accessToken: string) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/moderating`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * @GET /documents/tasks - Get all Tasks
 * ==========================================================>>
 */
export async function getAlltasks(params: TasksParams, accessToken: string) {
  const searchParams = buildQueryParams(params);
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/tasks?${searchParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * @GET /documents/tasks/mytasks - Gets user assigned Tasks
 * ==========================================================>>
 */
export async function getMyTasks(params: TasksParams, accessToken: string) {
  const searchParams = buildQueryParams(params);
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/tasks/mytasks?${searchParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * @GET /documents/save/{documentId}/{documentVersion}
 * ==========================================================>>
 */
export async function getDocumentSave(
  uuid: string,
  version: number,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/save/${uuid}/${version}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 *  ==========================================================>>
 *  @POST /documents - Create a new document
 *  ==========================================================>>
 */

export async function createNewDocument(
  documentRequest: PublishDocumentRequestBody,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}`;
  const res = await axiosRequestJSON('POST', url, headers, documentRequest);
  return res;
}

/**
 *  ==========================================================>>
 *  @POST /documents/:id - Update a new version of the document
 *  ==========================================================>>
 */

export async function postDocument(
  uuid: string,
  documentRequest: PublishDocumentRequestBody,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('POST', url, headers, documentRequest);
  return res;
}

/**
 *  ==========================================================>>
 *  @PUT /documents/:id - Update a new version of the document
 *  ==========================================================>>
 */

export async function putDocument(
  uuid: string,
  documentRequest: PublishDocumentRequestBody,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('PUT', url, headers, documentRequest);
  return res;
}

/**
 * ==========================================================>>
 * @POST /master/{uuid}/launch - Creates a new version of the Master document and launches the mdoeration workflow
 * ==========================================================>>
 */
// export async function postMasterLaunchWorkflow(
//   uuid: string,
//   data: PostDocumentRequestBody,
//   accessToken: string
// ) {
//   const baseUrl = getDocumentsEndpoint();
//   const serverToken = getDocumentsServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/${uuid}/launch?code=${serverToken}`;
//   const res = await axiosRequestJSON('POST', url, headers, data);
//   return res;
// }

/**
 * ==========================================================>>
 * @POST /documents/save - Creates a cloud save reference
 * ==========================================================>>
 */
export async function postDocumentSave(
  data: PostMasterSaveBodyObject,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/save`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * @POST /documents/{uuid}/migrate - Creates a new version of the Master document and launches the mdoeration workflow
 * ==========================================================>>
 */
export async function postMigrateDocument(
  uuid: string,
  data: PostDocumentRequestBody,
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/migrate`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * postDocumentWorkflowAction
 * @POST /documents/{uuid}/task/{taskUuid}/{action}
 * ==========================================================>>
 */
export async function postDocumentWorkflowAction(
  documentId: string,
  taskId: string,
  action: 'approve',
  data: any, // TODO - define the type
  accessToken: string
) {
  const baseUrl = getDocumentsEndpoint();
  const url = `${baseUrl}/${documentId}/task/${taskId}/${action}`;
  const headers = getHangarApiHeaders(accessToken);
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}
