import { validateOrCreateUuidV4 } from '../utils/uuid';
import {
  MediaBlock,
  MediaDisplayType,
  MediaProps,
  MediaResourceType
} from './media.type';

/**
 * Create media block
 */
export function createMediaBlock(
  props: MediaProps,
  defaultCaption: string,
  id?: string
): MediaBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'media',
    props,
    content: [
      {
        text: defaultCaption,
        type: 'text',
        styles: {}
      }
    ]
  };
}

/**
 * Media Type
 */
export function getMediaTypeFromFormat(
  format: MediaResourceType
): MediaDisplayType {
  switch (format) {
    case 'img':
    case 'vector':
    case 'tbl':
    case 'gif':
      return 'image';
    case 'vid':
    case '3dobj':
      return 'iframe';
    case 'ruffle':
      return 'ruffle';
    case 'html5':
      return 'html5';
    default:
      return 'error';
  }
}
