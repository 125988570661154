import { createNewContent } from 'app/integration/server/library/content';
import { uniqueLearningObjectiveLevel } from 'app/service/utils/learningObjectives';
import { PostContentRequestBody } from 'app/types/integration/content.api.types';
import { CourseContent } from 'app/types/service/course-manager/course.types';
import { PostItemResponseObject } from 'app/types/integration/items.api.types';

export const createContent = async (
  contentTaxonomies: string[],
  courseContent: CourseContent,
  courseUuid: string,
  courseTaxonomy: string,
  topicId: string | undefined,
  userId: string,
  accessToken: string
): Promise<PostItemResponseObject | null> => {
  const knowledgeLevels =
    courseContent.learningObjectives?.map((lo) => {
      const level = uniqueLearningObjectiveLevel(lo.stringValue);
      const parsedInt = parseInt(level ?? '');
      return isNaN(parsedInt) ? 0 : parsedInt;
    }) ?? [];
  const highestKnowledgeLevel = Math.max(...knowledgeLevels);

  const resourceName = `${courseContent.stringValue} (Level ${highestKnowledgeLevel})`;
  const postContentRequestBody: PostContentRequestBody = {
    name: resourceName,
    description: 'A single-source HTML content resource.',
    owner: userId,
    taxonomies: contentTaxonomies,
    knowledgeLevel: highestKnowledgeLevel,
    teachingPoints: [],
    resourceReview: 'New resource created with Course Builder via Toolbox',
    courseId: [courseUuid],
    changeLog: [],
    file: {
      heading: courseContent.stringValue,
      content: '<div id="container"></div>'
    }
  };

  const createNewContentResponse = await createNewContent(
    postContentRequestBody,
    accessToken
  );
  if (createNewContentResponse.status !== 200) {
    console.error(createNewContentResponse);
    return null;
  } else {
    const newContentResponseData: PostItemResponseObject =
      createNewContentResponse.data;
    return newContentResponseData;
  }
};
