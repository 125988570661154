/**
 * Footer Component
 */
export function Footer() {
  const drawerInitClasses =
    'hidden text-white bg-gray-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800';
  return `
        <!-- drawer init and toggle -->
            <div class="text-center">
                <button 
                    class="${drawerInitClasses}" 
                    type="button" 
                    aria-controls="drawer-bottom-footer">
                Show footer
                </button>
            </div>
            
            <!-- Drawer Component -->
            <div id="drawer-bottom-footer" class="fixed z-30 w-full overflow-y-auto bg-white border-t border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform bottom-0 left-0 right-0 translate-y-full bottom-[60px]" tabindex="-1" aria-labelledby="drawer-bottom-footer-label">
            
                <!-- Drawer Header -->
                <div class="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700" onclick="toggleDrawer(2);">
                    <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-300 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
                    <h5 id="drawer-bottom-footer-label" class="inline-flex items-center text-base text-gray-500 dark:text-gray-400">
                    <svg class="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                    </svg></span><span id="drawer-bottom-footer-label-text">Menu</span></h5>

                </div>

                <!-- Drawer Tools -->
                <div id="footer-tools"></div>

                <hr class="mb-2">

                <div class="relative overflow-y-auto px-8 pb-2 pt-2 max-h-[36vh] md:max-h-[40vh]">
                    <h2 class="text-xl font-semibold text-aa-blue mb-2">Attributions</h2>
                    <ul id="page-attribution-list">
                        <li class="mt-1 font-normal text-xs text-left text-gray-400 dark:text-gray-600">No attributions found on this page.</li>
                    </ul>
                </div>

                <hr class="mt-4">

                <div class="w-full text-center mb-4">
                    <p id="page-footer-message" class="inline-flex text-xs items-center text-gray-500 dark:text-gray-400 mt-4"></p>
                    <p class="text-xs text-gray-400 dark:text-gray-500">v1.0.0</p>
                </div>
            </div>
        </div>
    `;
}
