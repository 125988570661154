import { HeadingProps } from 'app/libraries/html2blocks/blocks/heading.types';

/**
 * Create Heading 4 element
 */
export function H4(
  id: string,
  heading: string,
  props: HeadingProps,
  position: string
) {
  const classes = 'text-lg text-gray-700 font-bold dark:text-white mt-2 mb-2';
  return `
    <h4
      id="block-element-${id}" 
      class="${classes}"
      data-block
      data-type="heading" 
      data-heading-level="4"
      data-element-pos="${position}" 
    >${heading}</h4>`;
}

/**
 * Create Heading 5 element
 */
export function H5(
  id: string,
  heading: string,
  props: HeadingProps,
  position: string
) {
  const classes = 'text-base text-gray-700 font-bold dark:text-white mt-2 mb-2';
  return `
    <h5 
      id="block-element-${id}" 
      class="${classes}"
      data-block
      data-type="heading" 
      data-heading-level="5"
      data-element-pos="${position}" 
    >${heading}</h5>`;
}

/**
 * Create Heading 6 element
 */
export function H6(
  id: string,
  heading: string,
  props: HeadingProps,
  position: string
) {
  const classes = 'text-base text-gray-700 font-bold dark:text-white mt-2 mb-2';
  return `
    <h6 
      id="block-element-${id}" 
      class="${classes}"
      data-block
      data-type="heading" 
      data-heading-level="6" 
      data-element-pos="${position}" 
    >${heading}</h6>`;
}
