import { createErrorBlock } from '../../blocks/error';
import { ErrorBlock } from '../../blocks/error.types';
import { createHeadingBlock } from '../../blocks/heading';
import { HeadingBlock, HeadingProps } from '../../blocks/heading.types';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';
import { htmlToInlineContent } from '../../utils/htmlToInlineContent';

export function headingElementToBlock(
  element: HTMLElement
): HeadingBlock | ErrorBlock {
  const blockId = extractBlockIdFromElementId(element.id);

  const blockPropsJSONEncoded = element.dataset.props;
  if (blockPropsJSONEncoded === undefined) {
    return createErrorBlock(
      `Failed to create Heading block with ID "${blockId}", missing props.`,
      '',
      blockId
    );
  }
  const blockPropsJSON = decodeURI(blockPropsJSONEncoded);
  let blockProps: HeadingProps;
  try {
    blockProps = JSON.parse(blockPropsJSON);
  } catch (e) {
    return createErrorBlock(
      `Failed to parse Heading block with ID "${blockId}", ${e}`,
      '',
      blockId
    );
  }
  const headingLevel = blockProps.level;
  /**
   * Validation
   */
  if (isNaN(headingLevel) || headingLevel < 1 || headingLevel > 3) {
    return createErrorBlock(
      `Failed to create Heading block with ID "${blockId}" at level ${headingLevel}`,
      '',
      blockId
    );
  }

  /**
   * Convert the inline content to a block
   */
  const blockInlineContent = htmlToInlineContent(element.innerHTML);

  return createHeadingBlock(blockInlineContent, blockProps, blockId);
}
