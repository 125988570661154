import React, { useEffect, useState } from 'react';
import {
  CourseLearningObjective,
  CourseTopic
} from '../../../app/types/service/course-manager/course.types';
import { ListEditor } from './ListEditor';
import { Button, Container, Grid, Tooltip } from '@mui/material';
import { LearningObjectiveChip } from './LearningObjectiveChip';
import { Typography } from '@mui/material';

export const ContentLearningObjectivesEditor = (props: {
  courseTopics: CourseTopic[];
  courseTopicIndex: number;
  courseDocumentIndex: number;
  courseContentIndex: number;
  availableLearningObjectives: CourseLearningObjective[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const content = props.courseTopics[props.courseTopicIndex].documents
    ?.at(props.courseDocumentIndex)
    ?.contents?.at(props.courseContentIndex);
  const contentLearningObjectives = content?.learningObjectives ?? [];
  const [localLearningObjectives, setLocalLearningObjectives] = useState<
    CourseLearningObjective[]
  >(contentLearningObjectives);

  const [learningObjectivesBuilt, setLearningObjectivesBuilt] = useState(false);
  const saveLearningObjectives = () => setLearningObjectivesBuilt(true);
  const editLearningObjectives = () => {
    props.setEditing(true);
    setLearningObjectivesBuilt(false);
  };

  const saveTopics = () => {
    const newCourseTopics = [...props.courseTopics];
    let documents = newCourseTopics[props.courseTopicIndex].documents;
    if (!documents) return;
    let contents = documents[props.courseDocumentIndex].contents;
    if (!contents) return;
    contents[props.courseContentIndex] = {
      ...contents[props.courseContentIndex],
      learningObjectives: localLearningObjectives
        .map((lo) => {
          // recover ulo since ListEditor component drops the property
          const alo = props.availableLearningObjectives.find(
            (alo) => lo.stringValue === alo.stringValue
          );
          if (!alo) return undefined;
          return alo;
        })
        .filter((lo) => lo !== undefined)
    };
    documents[props.courseDocumentIndex].contents = contents;
    newCourseTopics[props.courseTopicIndex].documents = documents;
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    saveLearningObjectives();
    saveTopics();
  }, [localLearningObjectives]);

  if (!document) return;

  return (
    <Container sx={{ my: '0.5em' }}>
      <Typography variant="body1">
        <strong>
          {
            props.courseTopics[props.courseTopicIndex].documents
              ?.at(props.courseDocumentIndex)
              ?.contents?.at(props.courseContentIndex)?.stringValue
          }
        </strong>
      </Typography>
      {learningObjectivesBuilt && (
        <Tooltip
          title={
            !props.courseTopics[props.courseTopicIndex].documents
              ?.at(props.courseDocumentIndex)
              ?.contents?.at(props.courseContentIndex)?.uuid
              ? 'New Content'
              : 'Existing Content'
          }
        >
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={11}>
              <Container>
                {props.courseTopics[props.courseTopicIndex].documents
                  ?.at(props.courseDocumentIndex)
                  ?.contents?.at(props.courseContentIndex)
                  ?.learningObjectives?.map((lo, index) => (
                    <LearningObjectiveChip
                      key={`topic-document-content-learning-objective-${index}`}
                      courseLearningObjective={lo}
                      disableHighlight={true}
                    />
                  ))}
              </Container>
            </Grid>
            <Grid item xs={1}>
              {!props.courseTopics[props.courseTopicIndex].documents
                ?.at(props.courseDocumentIndex)
                ?.contents?.at(props.courseContentIndex)?.uuid && (
                  <Button fullWidth onClick={editLearningObjectives}>
                    Add/Edit LOs
                  </Button>
                )}
            </Grid>
          </Grid>
        </Tooltip>
      )}
      {!learningObjectivesBuilt && (
        <ListEditor
          title={''}
          listItemName={'Learning Objective'}
          listSelectChoices={props.availableLearningObjectives}
          includesPrefix={false}
          list={localLearningObjectives}
          setList={setLocalLearningObjectives}
        />
      )}
    </Container>
  );
};
