/* eslint-disable @typescript-eslint/no-unused-vars */
import { CourseBuilderBreadcrumbs } from './components/Breadcrumbs';
import { useEffect, useState } from 'react';
import MainCard from 'components/app/MainCard';
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { DesignCourseStructure } from './components/DesignCourseStructure';
import { ApplyLearningObjectives } from './components/ApplyLearningObjectives';
import { CourseTopic } from '../../../app/types/service/course-manager/course.types';
import { AddContent } from './components/AddContent';
import { PublishCourse } from './components/PublishCourse';
import { createCourse } from 'app/service/course-manager/create';
import useHangarAuth from 'hooks/useHangarAuth';
import { RegulatorType } from 'app/types/service/courses.types';
import { useNavigate } from 'react-router';

const courseBuilderSteps = [
  'Design Course Structure',
  'Apply Learning Objectives',
  'Add Content',
  'Publish Course'
];

export const CourseBuilder = () => {
  const { user } = useHangarAuth();
  const userId = user.id;
  const accessToken: string = user?.accessToken;

  const navigate = useNavigate();

  const [activeCourseBuilderStep, setActiveCourseBuilderStep] = useState(0);
  const handleNext = () => {
    const newStep = activeCourseBuilderStep + 1;
    if (newStep >= courseBuilderSteps.length) return;
    setActiveCourseBuilderStep(newStep);
  };
  const handlePrevious = () => {
    const newStep = activeCourseBuilderStep - 1;
    if (newStep < 0) return;
    setActiveCourseBuilderStep(newStep);
    setPreventContinue(false);
  };

  const [courseTaxonomyPath, setCourseTaxonomyPath] = useState<
    string | undefined
  >();

  const [courseTitle, setCourseTitle] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [courseTaxonomy, setCourseTaxonomy] = useState<string | undefined>();

  const saveCourseTaxonomy = () => {
    const newCourseTaxonomy = `${courseTaxonomyPath}\\${courseCode} ${courseTitle}`;
    setCourseTaxonomy(newCourseTaxonomy);
  };
  const changeCourseTaxonomy = () => setCourseTaxonomy(undefined);

  const [courseTopics, setCourseTopics] = useState<CourseTopic[]>([]);
  const [courseTopicsBuilt, setCourseTopicsBuilt] = useState(false);
  const saveTopics = () => setCourseTopicsBuilt(true);
  const editTopics = () => setCourseTopicsBuilt(false);

  const [editing, setEditing] = useState(false);
  const [preventBack, setPreventBack] = useState(false);
  const [preventContinue, setPreventContinue] = useState(false);

  const [creatingCourse, setCreatingCourse] = useState(false);
  const [courseCreated, setCourseCreated] = useState(false);

  useEffect(() => {
    if (courseTopics.length > 0) {
      saveTopics();
      setEditing(false);
    }
  }, [courseTopics]);

  const [readyToApplyLearningObjectives, setReadyToApplyLearningObjectives] =
    useState(false);
  const readyCourseStructure = () => setReadyToApplyLearningObjectives(true);
  const designCourseStructure = () => setReadyToApplyLearningObjectives(false);

  useEffect(() => {
    const determineCourseStructureState = () => {
      if (courseTopicsBuilt) {
        for (let i = 0; i < courseTopics.length; i++) {
          const courseTopic = courseTopics[i];

          if (!courseTopic.documents || courseTopic.documents.length === 0) {
            designCourseStructure();
            return;
          }
        }
        setEditing(false);
        readyCourseStructure();
      } else {
        designCourseStructure();
      }
    };
    determineCourseStructureState();
  }, [courseTopics, courseTopicsBuilt]);

  const restart = () => {
    setActiveCourseBuilderStep(0);
    setCourseTopicsBuilt(false);
    setCourseTopics([]);
    setCourseTaxonomy(undefined);
    setCourseTitle('');
    setCourseTaxonomyPath(undefined);
  };

  const publishCourse = async () => {
    if (!courseTaxonomy) return;
    const courseTaxonomySplit = courseTaxonomy.split('\\');
    const regulator = courseTaxonomySplit[0] as RegulatorType;

    setPreventBack(true);
    setPreventContinue(true);
    setCreatingCourse(true);
    const createCourseSuccessful = await createCourse(
      regulator,
      courseTaxonomy,
      courseCode,
      courseTitle,
      courseTopics,
      userId,
      accessToken
    );
    setCourseCreated(createCourseSuccessful);
    setCreatingCourse(false);
    setPreventBack(false);
    setPreventContinue(false);
  };

  useEffect(() => {
    if (courseCreated) {
      navigate(`/courses`);
    }
  }, [courseCreated]);

  return (
    <div>
      <CourseBuilderBreadcrumbs />
      <Container sx={{ my: '1em' }}>
        {courseTaxonomyPath && (
          <Button sx={{ my: '1em' }} onClick={restart} fullWidth>
            Restart Builder
          </Button>
        )}
        <MainCard>
          <Stepper activeStep={activeCourseBuilderStep}>
            {courseBuilderSteps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </MainCard>

        {activeCourseBuilderStep === 0 && (
          <DesignCourseStructure
            courseTaxonomyPath={courseTaxonomyPath}
            setCourseTaxonomyPath={setCourseTaxonomyPath}
            courseCode={courseCode}
            setCourseCode={setCourseCode}
            courseTitle={courseTitle}
            setCourseTitle={setCourseTitle}
            courseTaxonomy={courseTaxonomy}
            saveCourseTaxonomy={saveCourseTaxonomy}
            changeCourseTaxonomy={changeCourseTaxonomy}
            courseTopics={courseTopics}
            setCourseTopics={setCourseTopics}
            courseTopicsBuilt={courseTopicsBuilt}
            editTopics={editTopics}
            setEditing={setEditing}
          />
        )}

        {activeCourseBuilderStep === 1 && (
          <ApplyLearningObjectives
            courseCode={courseCode}
            courseTitle={courseTitle}
            courseTopics={courseTopics}
            setCourseTopics={setCourseTopics}
            setEditing={setEditing}
            setPreventNext={setPreventContinue}
          />
        )}

        {activeCourseBuilderStep === 2 && (
          <AddContent
            courseCode={courseCode}
            courseTitle={courseTitle}
            courseTopics={courseTopics}
            setCourseTopics={setCourseTopics}
            setEditing={setEditing}
            setPreventNext={setPreventContinue}
          />
        )}

        {activeCourseBuilderStep === 3 && (
          <PublishCourse
            courseCode={courseCode}
            courseTitle={courseTitle}
            courseTaxonomy={courseTaxonomy}
            courseTopics={courseTopics}
          />
        )}

        {creatingCourse && <LinearProgress color="secondary" />}

        <Grid container>
          <Grid item xs={3}>
            {activeCourseBuilderStep > 0 && (
              <Button
                sx={{ my: '1em' }}
                fullWidth
                variant="contained"
                onClick={handlePrevious}
                disabled={preventBack}
              >
                Back
              </Button>
            )}
          </Grid>

          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={3}>
            {courseTaxonomy &&
              activeCourseBuilderStep < courseBuilderSteps.length - 1 &&
              readyToApplyLearningObjectives &&
              !editing && (
                <Button
                  sx={{ my: '1em' }}
                  fullWidth
                  variant="contained"
                  onClick={handleNext}
                  disabled={preventContinue}
                >
                  Continue
                </Button>
              )}

            {activeCourseBuilderStep === 3 && (
              <Button
                sx={{ my: '1em' }}
                fullWidth
                variant="contained"
                onClick={publishCourse}
                color="success"
                disabled={preventContinue}
              >
                Publish
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
