import { axiosRequestJSON } from 'app/axios/axiosRequests';
import { getHangarApiHeaders } from '../headers/headers';
import { getContentEndpoint } from '../endpoints/content';
import {
  PostContentRequestBody,
  PutContentRequestBody
} from '../../../types/integration/content.api.types';
import { buildQueryParams } from '../utils/urls';

/**
 * ==========================================================>>
 * GET /content
 * ==========================================================>>
 */
export async function getContent(
  uuid: string,
  taxonomyOnly: boolean,
  accessToken: string
) {
  const queryParams = buildQueryParams({
    taxonomyOnly
  });
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}?${queryParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * POST /content
 * ==========================================================>>
 */
export async function createNewContent(
  data: PostContentRequestBody,
  accessToken: string
) {
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = baseUrl;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * POST /content/{contentUuid}
 * assumes 'new' property in request body is set to true
 * ==========================================================>>
 */
export async function createNewContentWithSuppliedUuid(
  uuid: string,
  data: PostContentRequestBody,
  accessToken: string
) {
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * POST /content/{contentUuid}
 * ==========================================================>>
 */
export async function updateExistingContent(
  uuid: string,
  data: PostContentRequestBody,
  accessToken: string
) {
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * Taxonomies
 * PUT /content/{contentUuid}
 * ==========================================================>>
 */
export async function putContentTaxonomies(
  contentUuid: string,
  data: PutContentRequestBody,
  accessToken: string
) {
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${contentUuid}`;
  const res = await axiosRequestJSON('PUT', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * MIGRATION FUNCTION ONLY
 * POST /content/{contentUuid}/migrate
 * ==========================================================>>
 */
export async function migrateContent(
  uuid: string,
  data: PostContentRequestBody,
  accessToken: string
) {
  const baseUrl = getContentEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/migrate`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}
