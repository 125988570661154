/**
 * Courses Function Endpoint
 */

/**
 * Get
 */
export function getDocumentsEndpoint() {
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  return `${baseUrl}/api/documents`;
}
