import { Alert, Container, Divider, Typography, useTheme } from '@mui/material';
import MainCard from 'components/app/MainCard';
import { LearningObjectiveChip } from 'pages/course-manager/components/LearningObjectiveChip';
import { CourseTopic } from 'app/types/service/course-manager/course.types';

export const PublishCourse = (props: {
  courseTaxonomy: string | undefined;
  courseCode: string;
  courseTitle: string;
  courseTopics: CourseTopic[];
}) => {
  const theme = useTheme();

  return (
    <MainCard sx={{ my: '1em' }}>
      <Alert icon={false} severity="info">
        {props.courseTaxonomy}
      </Alert>
      <Typography sx={{ mt: '1em' }} variant="h3">
        {props.courseCode} {props.courseTitle}
      </Typography>
      <Typography variant="caption">
        Topics ({props.courseTopics.length})
      </Typography>
      <Divider />
      {props.courseTopics.map((topic, topicIndex) => (
        <Container
          key={`topic-${topicIndex}`}
          sx={{
            py: '1em'
          }}
        >
          <Typography variant="h4">{`${topic.prefixStringValue} ${topic.stringValue}`}</Typography>

          {topic.documents && (
            <Typography variant="caption">
              Documents ({topic.documents.length})
            </Typography>
          )}
          <Divider />
          {topic.documents &&
            topic.documents.map((document, documentIndex) => (
              <Container
                key={`topic-${topicIndex}-document-${documentIndex}`}
                sx={{
                  py: '0.5em',
                  ':hover': {
                    backgroundColor: theme.palette.background.default
                  }
                }}
              >
                <Typography variant="h5">{document.stringValue}</Typography>
                {document.contents && (
                  <Typography variant="caption">
                    Content ({document.contents.length})
                  </Typography>
                )}
                <Divider />
                {document.contents &&
                  document.contents.map((content, contentIndex) => (
                    <Container
                      sx={{
                        py: '0.25em'
                      }}
                      key={`topic-${topicIndex}-document-${documentIndex}-content-${contentIndex}`}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: content.uuid
                            ? theme.palette.primary.main
                            : theme.palette.success.main
                        }}
                      >
                        <strong>{content.stringValue}</strong>
                      </Typography>
                      <Container>
                        {content.learningObjectives &&
                          content.learningObjectives.map((lo, loIndex) => (
                            <LearningObjectiveChip
                              key={`topic-${topicIndex}-document-${documentIndex}-content-${contentIndex}-lo-${loIndex}`}
                              courseLearningObjective={lo}
                              disableHighlight={true}
                            />
                          ))}
                      </Container>
                    </Container>
                  ))}
              </Container>
            ))}
        </Container>
      ))}
    </MainCard>
  );
};
