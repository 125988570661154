import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';

export const CourseBuilderBreadcrumbs = () => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Typography
        component={RouterLink}
        to="/dashboard"
        color="textSecondary"
        variant="h6"
        sx={{ textDecoration: 'none', m: 0, p: 0 }}
      >
        Home
      </Typography>
      <Typography
        component={RouterLink}
        to="/course-manager"
        color="textSecondary"
        variant="h6"
        sx={{ textDecoration: 'none', m: 0, p: 0 }}
      >
        Course Manager
      </Typography>
      <Typography
        variant="h6"
        sx={{ color: '#262626', fontWeight: '700', m: 0, p: 0 }}
      >
        Builder
      </Typography>
    </Breadcrumbs>
  );
};
