/**
 * Create HTML Maths element
 */
export function Maths(id: string, math: string, position: string) {
  const lines = math.split('\\\\');
  const html = lines.map((line) => `<p>$$${line}$$</p>`).join('');
  return `
    <div 
      id="block-element-${id}"  
      data-block
      data-type="maths" 
      data-maths="${math}" 
      data-element-pos="${position}" 
    >
      ${html}
    </div>
    `;
}
