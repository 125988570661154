import { ListArray } from '../blocks/lists.types';
import { removeFluffFromString, sanitiseParagraph } from './strings';

/**
 * Convert nested HTMLElement LI elements into a JS array variable
 * @param {*} divHtml
 * @return {array}
 */
export function nestedHtmlListIntoArray(divHtml: Element): any[] {
  const level1 = divHtml.children;
  const listArray = [];

  // Iterate over each element
  for (let index = 0; index < level1.length; index++) {
    const element = level1[index];

    if (element.tagName === 'UL' || element.tagName === 'OL') {
      const nestedArray = [];
      const level2 = element.children;

      for (let index = 0; index < level2.length; index++) {
        const element2 = level2[index];

        if (element2.tagName === 'UL' || element2.tagName === 'OL') {
          const doubleNestedArray = [];
          const level3 = element2.children;

          for (let index = 0; index < level2.length; index++) {
            const element3 = level3[index];

            if (element3.tagName === 'UL' || element3.tagName === 'OL') {
              console.error('Unhandled element in list - Nested too far');
              doubleNestedArray.push({
                content: sanitiseParagraph(element3.innerHTML),
                items: []
              });
            } else if (element3.tagName === 'LI') {
              doubleNestedArray.push({
                content: sanitiseParagraph(element3.innerHTML),
                items: []
              });
            } else {
              console.error('Unhandled element in list');
            }
          }

          nestedArray.push(doubleNestedArray);
        } else if (element2.tagName === 'LI') {
          nestedArray.push({
            content: sanitiseParagraph(element2.innerHTML),
            items: []
          });
        } else {
          console.error('Unhandled element in list');
        }
      }

      listArray.push(nestedArray);
    } else if (element.tagName === 'LI') {
      listArray.push({
        content: sanitiseParagraph(element.innerHTML),
        items: []
      });
    } else {
      console.error('Unhandled element in list');
    }
  }

  return listArray;
}

/**
 * Recursive HTML List into Array
 * @param element
 * @returns
 */
export function recursiveHtmlListIntoArray(element: Element): ListArray[] {
  if (element.tagName === 'UL' || element.tagName === 'OL') {
    const listItems = element.children;
    const result: ListArray[] = [];

    for (let index = 0; index < listItems.length; index++) {
      const element = listItems[index];

      /**
       * If last element is an LI
       */
      if (index === listItems.length - 1 && element.tagName === 'LI') {
        const content = removeFluffFromString(element.innerHTML) || '';
        result.push({ content, items: [] });
      } else if (element.tagName === 'LI') {
        /**
         * If element is an LI
         */
        const content = removeFluffFromString(element.innerHTML) || '';
        const nextElement = listItems[index + 1];
        if (nextElement.tagName === 'UL' || nextElement.tagName === 'OL') {
          /**
           * And next element is a UL or OL
           */
          const items = recursiveHtmlListIntoArray(nextElement);
          result.push({ content, items });
        } else {
          /**
           * Else just push the LI
           */
          result.push({ content, items: [] });
        }
      }
    }
    return result;
  }

  return [];
}
