/* eslint-disable no-fallthrough */
// Types

// Elements
import { H3, H4, H5 } from './elements/headings';
import { Paragraph } from './elements/paragraph';
import { TeachingPoint } from './elements/tp';
import { Maths } from './elements/maths';
import { List } from './elements/list';
import { Media } from './elements/media';
import { Slide } from './elements/slide';
import { Block } from 'app/types/libraries/block.types';
import { HeadingBlock } from 'app/libraries/html2blocks/blocks/heading.types';
import { inlineContentToExplicitHtml } from '../../utils/inlineContentToHtml';
import { ParagraphBlock } from 'app/libraries/html2blocks/blocks/paragraph.types';
import {
  MediaBlock,
  MediaProps
} from 'app/libraries/html2blocks/blocks/media.type';
import { TeachingPointBlock } from 'app/libraries/html2blocks/blocks/tp.types';
import { MathsBlock } from 'app/libraries/html2blocks/blocks/maths.types';
import {
  BulletListItemBlock,
  NumberedListItemBlock
} from 'app/libraries/html2blocks/blocks/lists.types';
import { SlideBlock } from 'app/libraries/html2blocks/blocks/slide.types';
import { ContentHTMLCreationProcess } from '..';

function getPosition(index: number, length: number) {
  if (index === 0) return 'first';
  else if (index === length - 1) return 'last';
  else return 'middle';
}

/**
 * Convert editor-js blocks to Aviation Australia HTML elements
 */
export function convertBlocksToRawHTML(
  blocks: Block[],
  process: ContentHTMLCreationProcess
): string {
  let html = '';
  let isList = false; // Flag to check if the current block is a list

  /**
   * DEBUG
   */
  // const tempListHtmlArr = [];
  // let tempListHtml = '';

  /**
   * Check process
   * If the process is 'view' perform conditional checks
   */

  for (let index = 0; index < blocks.length; index++) {
    /**
     * Get the current block
     */
    const block = blocks[index];
    if (!block) {
      console.error(`Element ${index} is undefined`);
      continue;
    }

    /**
     * Check the next element
     */
    let nextElement = null;
    if (index < blocks.length - 1) {
      nextElement = blocks[index + 1];
    }

    const elementType = block.type;
    const position = getPosition(index, blocks.length);

    switch (elementType) {
      /**
       * Headers
       */
      case 'heading':
        const header = block as HeadingBlock;
        const headerId = header?.id;
        const headerProps = header?.props;
        const headerLevel = headerProps.level;
        const headerText = inlineContentToExplicitHtml(header?.content);

        if (headerLevel === 1) {
          html += H3(headerId, headerText, position, headerProps);
          break;
        } else if (headerLevel === 2) {
          html += H4(headerId, headerText, position, headerProps);
          break;
        } else if (headerLevel === 3) {
          html += H5(headerId, headerText, position, headerProps);
          break;
        } else if (headerLevel === 4) {
          // This is not a typo, at this stage we are using H5 for H6
          html += H5(headerId, headerText, position, headerProps);
          break;
        } else break;

      /**
       * Paragraph Text
       */
      case 'paragraph':
        const paragraph = block as ParagraphBlock;
        const paragraphProps = paragraph.props;
        html += Paragraph(
          paragraph.id,
          inlineContentToExplicitHtml(paragraph.content),
          position,
          paragraphProps
        );
        break;

      /**
       * Media
       */
      case 'media':
        const media = block as MediaBlock;
        const props = media.props as MediaProps;
        const content = media.content;
        html += Media(media.id, content, props, position, process); // Includes process
        break;

      /**
       * Teaching Point
       */
      case 'tp':
        if (process === 'view') break; // SKIP in view mode
        const tp = block as TeachingPointBlock;
        html += TeachingPoint(
          tp.id,
          inlineContentToExplicitHtml(tp.content),
          tp.props,
          position
        );
        break;

      /**
       * Maths
       */
      case 'maths':
        const math = block as MathsBlock;
        html += Maths(math.id, math.props.maths, position);
        break;

      /**
       * Lists
       */
      case 'bulletListItem':
        const bulList = block as BulletListItemBlock;
        const bulListElement = List(
          bulList.id,
          bulList.type,
          bulList.content,
          bulList.props,
          bulList.children,
          position
        );

        if (!isList) {
          // tempListHtml = '<ul data-type="list">'; // TESTING ONLY
          html += '<ul data-type="list">';
          isList = true;
        }
        html += bulListElement;
        // tempListHtml += bulListElement; // TESTING ONLY
        if (isList && nextElement?.type !== 'bulletListItem') {
          html += '</ul>';
          isList = false;
          // tempListHtml += '</ul>'; // TESTING ONLY
          // tempListHtmlArr.push(tempListHtml); // TESTING ONLY
        }
        break;

      /**
       * Lists
       */
      case 'numberedListItem':
        const numList = block as NumberedListItemBlock;
        const numListElement = List(
          numList.id,
          numList.type,
          numList.content,
          numList.props,
          numList.children,
          position
        );

        if (!isList) {
          // tempListHtml = '<ul data-type="list">'; // TESTING ONLY
          html += '<ol data-type="list">';
          isList = true;
        }
        html += numListElement;
        // tempListHtml += numListElement; // TESTING ONLY
        if (isList && nextElement?.type !== 'numberedListItem') {
          html += '</ol>';
          isList = false;
          // tempListHtml += '</ol>'; // TESTING ONLY
          // tempListHtmlArr.push(tempListHtml); // TESTING ONLY
        }
        break;

      /**
       * Slide
       */
      case 'slide':
        if (process === 'view') break; // SKIP in view mode
        const slide = block as SlideBlock;
        const slideTitle = inlineContentToExplicitHtml(slide.content);
        const slideHtml = Slide(slide.id, slide.props, slideTitle, position);
        html += slideHtml;
        break;

      /**
       * Unknown
       */
      default:
        // console.log('====================================');
        console.log(`Unknown element type: ${block.type}`);
        // console.log(element);
        // console.log('====================================');
        break;
    }
  }

  // console.log('tempListHtmlArr:', tempListHtmlArr); // TESTING ONLY
  return html;
}
