import { createErrorBlock } from '../../blocks/error';
import { ErrorBlock } from '../../blocks/error.types';
import { createMediaBlock } from '../../blocks/media';
import { MediaBlock, MediaProps } from '../../blocks/media.type';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';

export function mediaElementToBlock(
  element: HTMLElement
): MediaBlock | ErrorBlock {
  const blockId = extractBlockIdFromElementId(element.id);

  let caption = element.dataset.caption || '';
  const blockPropsJSONEncoded = element.dataset.props;
  if (!blockPropsJSONEncoded) {
    return createErrorBlock(
      `Failed to block with id "${blockId}", missing props.`,
      '',
      blockId
    );
  }
  const blockPropsJSON = decodeURI(blockPropsJSONEncoded);
  let blockProps: MediaProps;
  try {
    blockProps = JSON.parse(blockPropsJSON);
  } catch (e) {
    return createErrorBlock(
      `Failed to parse media block props with id "${blockId}", ${e}`,
      '',
      blockId
    );
  }

  /**
   * Backward compatibility note
   * Previously the media block stored the caption in the props, it is now in a separate data attribute.
   * This is because the caption is inline content in blocknote.
   * The following line of code is to handle the backward compatibility and should be remove in the future.
   * - Nick
   */
  if (caption === '') {
    const legacyBlockProps: any = blockProps;
    if (legacyBlockProps?.caption && legacyBlockProps.caption !== '') {
      caption = legacyBlockProps.caption;
    }
  }

  /**
   * Check if the media block has an id and attachment id
   * Otherwise swap it out for an error block and let the user troubleshoot the issue
   */
  if (blockProps.itemUuid === '' || blockProps.attachmentUuid === '') {
    return createErrorBlock(
      `Media block with id ${blockId} has no URL, please investigate which media should be presenting here and check it exists in the Hangar.`,
      '',
      blockId
    );
  }

  return createMediaBlock(blockProps, caption, blockId);
}
