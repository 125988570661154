import { Block } from 'app/types/libraries/block.types';
import {
  AccordionSection,
  EditorBlockObject
} from '../../../../types/libraries/content.obj2html.types';
import { convertBlocksToStyledHTML } from './blocks/blocks2StyledHtml';
import { DocumentHTMLBuildProcess } from '..';
import { inlineContentToSemanticHtml } from '../../utils/inlineContentToHtml';

function AccordionHeading(
  id: string,
  index: number,
  heading: string,
  condition: string
): string {
  const sharedClasses =
    'flex items-center justify-between w-full p-5 text-base text-left text-gray-700 border border-gray-200';
  const firstClasses =
    sharedClasses +
    'border-b-0 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800';
  const middleClasses =
    sharedClasses +
    'border-b-0 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800';
  const lastClasses =
    sharedClasses +
    ' focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800';

  let classes = '';
  switch (condition) {
    case 'first':
      classes = firstClasses;
      break;
    case 'middle':
      classes = middleClasses;
      break;
    case 'last':
      classes = lastClasses;
      break;
  }

  return `
        <h3 
          id="accordion-content-${id}-heading-${index}" 
          class="${condition === 'first' ? 'rounded-t-xl' : ''}"
          data-type="accordion-heading"
        >
            <button type="button" class="${classes}" aria-expanded="true" aria-controls="accordion-content-${id}-body-${index}">
                <span class="text-lg md:text-xl">${heading}</span>
                <svg data-accordion-icon aria-hidden="true" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>
        </h3>
    `;
}

function AccordionBody(
  id: string,
  index: number,
  content: Block[],
  condition: string,
  process: DocumentHTMLBuildProcess
): string {
  const firstClasses =
    'pt-1 pb-5 px-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900';
  const middleClasses =
    'pt-1 pb-5 px-5 border border-b-0 border-gray-200 dark:border-gray-700';
  const lastClasses =
    'pt-1 pb-5 px-5 border border-t-0 border-gray-200 dark:border-gray-700';

  let classes = '';
  switch (condition) {
    case 'first':
      classes = firstClasses;
      break;
    case 'middle':
      classes = middleClasses;
      break;
    case 'last':
      classes = lastClasses;
      break;
  }

  /**
   * Convert editor-js blocks to Aviation Australia HTML elements
   */
  const html = convertBlocksToStyledHTML(content, process);

  return `
    <div 
      id="accordion-content-${id}-body-${index}" 
      class="hidden"
      aria-labelledby="accordion-content-${id}-heading-${index}"
      data-type="accordion-body"
    >
        <div 
          class="${classes}"
          data-type="block-content"
        >
            ${html}
        </div>
    </div>
    `;
}

/**
 * HTML Accordion
 * Create the Accordions based on the block content structure
 */
export function GenerateAccordion(
  id: string,
  content: EditorBlockObject,
  process: DocumentHTMLBuildProcess
): string {
  /**
   * 1. Split blocks into accordion sections
   */
  const blocks = content.blocks;

  let accordion: AccordionSection[] = [];
  let tempAccordionSection: AccordionSection = {
    heading: '',
    type: 'accordion',
    content: []
  };

  let innerFlag = false;
  for (let index = 0; index < blocks.length; index++) {
    const blockElement = blocks[index];

    if (!innerFlag) {
      if (blockElement.type === 'heading' && blockElement.props.level === 1) {
        tempAccordionSection.heading = inlineContentToSemanticHtml(
          blockElement.content
        );
        innerFlag = true;
      } else {
        tempAccordionSection.type = 'summary';
        tempAccordionSection.content.push(blockElement);
      }
    } else {
      if (blockElement.type === 'heading' && blockElement.props.level === 1) {
        accordion.push(tempAccordionSection);
        tempAccordionSection = {
          heading: '',
          type: 'accordion',
          content: []
        };
        tempAccordionSection.heading = inlineContentToSemanticHtml(
          blockElement.content
        );
      } else {
        tempAccordionSection.content.push(blockElement);
      }
    }
  }

  if (tempAccordionSection.content.length > 0) {
    accordion.push(tempAccordionSection);
  }

  /**
   * 2. Create the HTML
   */
  let html = '';

  for (let index = 0; index < accordion.length; index++) {
    const accordionSection = accordion[index];
    if (!accordionSection) {
      console.error(`Accordion section ${index} is undefined`);
      continue;
    }
    const lastIndex = accordion.length - 1;
    let condition = 'first';
    if (index === lastIndex) {
      condition = 'last';
    } else if (index > 0 && index < lastIndex) {
      condition = 'middle';
    }
    html += AccordionHeading(id, index, accordionSection.heading, condition);
    html += AccordionBody(
      id,
      index,
      accordionSection.content,
      condition,
      process
    );
  }

  return `<div class="mt-2">${html}</div>`;
}
