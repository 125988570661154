import React, { useEffect, useState } from 'react';
import { ListEditor } from './ListEditor';
import { Container } from '@mui/system';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { ListItem } from '../../../app/types/service/course-manager/common.types';
import { CourseTopic } from '../../../app/types/service/course-manager/course.types';

export const TopicDocumentsEditor = (props: {
  courseTopics: CourseTopic[];
  courseTopicIndex: number;
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;

  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const courseTopic = props.courseTopics[props.courseTopicIndex];

  const [documents, setDocuments] = useState<ListItem[]>(
    courseTopic.documents ?? []
  );

  const [documentsBuilt, setDocumentsBuilt] = useState(false);
  const saveDocuments = () => setDocumentsBuilt(true);
  const editDocuments = () => {
    props.setEditing(true);
    setDocumentsBuilt(false);
  };

  const saveTopics = () => {
    let newCourseTopics = [...props.courseTopics];
    newCourseTopics[props.courseTopicIndex] = { ...courseTopic, documents };
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    if (documents.length > 0) {
      saveDocuments();
      saveTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <Container sx={{ my: '1em' }}>
      <Typography variant="h4">{`${courseTopic.prefixStringValue} ${courseTopic.stringValue}`}</Typography>

      {documentsBuilt && (
        <Box>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="caption">{`Documents (${documents.length})`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={editDocuments}>
                Edit Documents
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Container>
            {documents.map((d, index) => (
              <Typography key={`topic-document-${index}`} variant="h5">
                {d.stringValue}
              </Typography>
            ))}
          </Container>
        </Box>
      )}
      {!documentsBuilt && (
        <ListEditor
          title={``}
          listItemName="Document Title"
          includesPrefix={false}
          list={documents}
          setList={setDocuments}
        />
      )}
    </Container>
  );
};
