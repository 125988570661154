import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  CourseContent,
  CourseLearningObjective,
  CourseTopic
} from '../../../app/types/service/course-manager/course.types';
import { LearningObjectiveChip } from './LearningObjectiveChip';
import {
  FoundInContent,
  LearningObjectivesResponse
} from 'app/types/integration/learningobjectives.api.types';
import { useEffect, useState } from 'react';
import { getUniqueLearningObjective } from 'app/integration/server/library/learningobjectives';
import useHangarAuth from 'hooks/useHangarAuth';
import { getContent } from 'app/integration/server/library/content';
import { ContentTaxonomyResponse } from 'app/types/integration/content.api.types';
import { ListEditor } from './ListEditor';
import { ContentLearningObjectivesEditor } from './ContentLearningObjectivesEditor';

export const DocumentContentsEditor = (props: {
  courseTopics: CourseTopic[];
  courseTopicIndex: number;
  courseDocumentIndex: number;
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setPreventNext: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useHangarAuth();
  const accessToken: string = user?.accessToken;

  const theme = useTheme();

  const document = props.courseTopics[props.courseTopicIndex].documents?.at(
    props.courseDocumentIndex
  );
  const documentLearningObjectives = document?.learningObjectives ?? [];

  const [courseContent, setCourseContent] = useState<CourseContent[]>(
    document?.contents?.filter(
      (c) =>
        !c.learningObjectives?.find(
          (lo) =>
            !props.courseTopics[props.courseTopicIndex].documents
              ?.at(props.courseDocumentIndex)
              ?.learningObjectives?.map((lo) => lo.stringValue)
              .includes(lo.stringValue)
        )
    ) ?? []
  );

  const [fetchingContentFoundIn, setFetchingContentFoundIn] = useState(false);

  useEffect(() => {
    const fetchContentFoundIn = async () => {
      setFetchingContentFoundIn(true);
      props.setPreventNext(true);
      const learningObjectives = documentLearningObjectives;

      /**
       * Get all content found in for each existing learning objective
       */
      let allFoundIn: FoundInContent = [];
      for (let i = 0; i < learningObjectives.length; i++) {
        const ulo = learningObjectives[i].ulo; // existing lo
        if (!ulo) continue;
        const uniqueLeaningObjectiveUuid = ulo.LearningObjectiveUuid;
        const includeClo = false;
        const includeFoundIn = true;
        const getUniqueLearningObjectiveResponse =
          await getUniqueLearningObjective(
            undefined,
            uniqueLeaningObjectiveUuid,
            includeClo,
            includeFoundIn,
            accessToken
          );
        if (getUniqueLearningObjectiveResponse.status !== 200) {
          console.error(getUniqueLearningObjectiveResponse);
          continue;
        }
        const learningObjectivesResponse: LearningObjectivesResponse =
          getUniqueLearningObjectiveResponse.data;
        const uniqueLearningObjectiveResponse =
          learningObjectivesResponse.unique;
        if (!uniqueLearningObjectiveResponse) continue;
        for (let j = 0; j < uniqueLearningObjectiveResponse.length; j++) {
          const foundIn = uniqueLearningObjectiveResponse[j].foundIn;
          if (foundIn) {
            allFoundIn.push(...foundIn);
          }
        }
      }

      /**
       * filter out found in content for instances
       * where their unique los are in this document
       */
      let contentToReuse: CourseContent[] = [];
      for (let i = 0; i < allFoundIn.length; i++) {
        const content = allFoundIn[i];
        const contentResourceUuid = content.resourceUuid;
        const taxonomyOnly = true;
        const getContentResponse = await getContent(
          contentResourceUuid,
          taxonomyOnly,
          accessToken
        );
        if (getContentResponse.status !== 200) {
          console.error(getContentResponse);
          continue;
        }
        const contentTaxonomyResponse: ContentTaxonomyResponse =
          getContentResponse.data;
        let contentTaxonomyEntries =
          contentTaxonomyResponse.taxonomy.entries.entry;
        if (!Array.isArray(contentTaxonomyEntries))
          contentTaxonomyEntries = [contentTaxonomyEntries];

        let applicableContent = true;
        const contentLearningObjectives: CourseLearningObjective[] = [];
        for (let j = 0; j < contentTaxonomyEntries.length; j++) {
          const contentTaxonomyEntry = contentTaxonomyEntries[j];
          const contentLearningObjective =
            contentTaxonomyEntry.learningObjective;
          if (!contentLearningObjective) continue;
          if (
            !learningObjectives
              .map((lo) => lo.stringValue)
              .includes(contentLearningObjective)
          ) {
            applicableContent = false;
            break;
          } else if (
            !contentLearningObjectives.find(
              (lo) => lo.stringValue === contentLearningObjective
            )
          ) {
            contentLearningObjectives.push({
              stringValue: contentLearningObjective,
              loId: learningObjectives.find(
                (lo) => lo.stringValue === contentLearningObjective
              )?.loId
            });
          }
        }

        if (
          applicableContent &&
          !contentToReuse.find((c) => c.uuid === content.resourceUuid)
        ) {
          const contentNameWithoutLevel = content.resourceName
            .split(' ')
            .slice(0, -2)
            .join(' ');
          contentToReuse.push({
            stringValue: contentNameWithoutLevel,
            uuid: content.resourceUuid,
            learningObjectives: contentLearningObjectives
          });
        }
      }

      setCourseContent([
        ...courseContent.filter((c) => !c.uuid), // keep new content added from next step
        ...contentToReuse
      ]);
      setFetchingContentFoundIn(false);
      props.setPreventNext(false);
    };

    fetchContentFoundIn();
  }, []);

  const [contentBuilt, setContentBuilt] = useState(false);
  const editContent = () => {
    props.setEditing(true);
    setContentBuilt(false);
  };
  const saveContent = () => setContentBuilt(true);

  const saveTopics = () => {
    const newCourseTopics = [...props.courseTopics];
    let documents = newCourseTopics[props.courseTopicIndex].documents;
    if (!documents) return;
    documents[props.courseDocumentIndex].contents = courseContent;
    newCourseTopics[props.courseTopicIndex].documents = documents;
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    saveContent();
    saveTopics();
  }, [courseContent]);

  if (!documentLearningObjectives || documentLearningObjectives.length === 0) {
    return (
      <Container>
        <Alert severity="warning">
          {document?.stringValue} has no learning objectives.
        </Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ my: '0.5em' }}>
      <Typography variant="h5">{document?.stringValue}</Typography>
      {documentLearningObjectives && (
        <Typography variant="caption">
          Learning Objectives ({documentLearningObjectives.length})
        </Typography>
      )}
      <Divider />
      <Container>
        {documentLearningObjectives.map((lo, loIndex) => {
          return (
            <LearningObjectiveChip
              key={`document-content-editor-lo-${loIndex}`}
              courseLearningObjective={lo}
            />
          );
        })}
      </Container>
      {fetchingContentFoundIn && <LinearProgress />}
      {contentBuilt && (
        <Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <Typography variant="caption">
                Contents ({courseContent.length})
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={editContent}>
                Add/Edit Contents
              </Button>
            </Grid>
          </Grid>

          <Divider />
          <Container>
            <Stack>
              {courseContent.map((content, contentIndex) => (
                <Box
                  key={`document-content-${contentIndex}`}
                  sx={{
                    margin: '1px',
                    borderLeft: 'solid',
                    borderLeftWidth: '1em',
                    borderRight: 'solid',
                    borderRightWidth: '1em',

                    borderTop: 'solid',
                    borderTopWidth: '1px',
                    borderBottom: 'solid',
                    borderBottomWidth: '1px',
                    borderColor: content.uuid
                      ? theme.palette.primary.lighter
                      : theme.palette.success.lighter,
                    ':hover': {
                      backgroundColor: theme.palette.background.default
                    }
                  }}
                >
                  <ContentLearningObjectivesEditor
                    courseTopics={props.courseTopics}
                    courseTopicIndex={props.courseTopicIndex}
                    courseDocumentIndex={props.courseDocumentIndex}
                    courseContentIndex={contentIndex}
                    availableLearningObjectives={documentLearningObjectives}
                    setCourseTopics={props.setCourseTopics}
                    setEditing={props.setEditing}
                  />
                </Box>
              ))}
            </Stack>
          </Container>
        </Box>
      )}
      {!contentBuilt && (
        <Container sx={{ my: '0.5em' }}>
          <ListEditor
            title={''}
            listItemName={'Content'}
            includesPrefix={false}
            list={courseContent}
            setList={setCourseContent}
          />
        </Container>
      )}
    </Container>
  );
};
