// types
import { getEnvironmentVariable } from 'app/service/utils/environment';
import { TaxonomyDefinition } from 'app/types/course-migration.types';
import {
  DefaultConfigProps,
  MenuOrientation,
  ThemeDirection,
  ThemeMode
} from 'types/mantis/config';

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = '/dashboard';
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;

// ==============================|| CUSTOM CONFIG  ||============================== //

/**
 * Exclude the page title in the layout where route contains the following:
 */
export const noBreadcrumb = [
  '/dashboard',
  '/editor',
  '/course/',
  '/document/',
  '/course-manager/'
];
export const noTitle = [
  '/dashboard',
  '/editor',
  '/course/',
  '/document/',
  '/course-manager'
];

// ==============================|| STATIC VALUES ||============================== //
export const taxonomiesPathSelect: TaxonomyDefinition[] = [
  {
    name: 'Regulatory Course',
    value: 'NAA',
    uuid: getEnvironmentVariable(
      'REACT_APP_HANGAR_REGULATORY_COURSE_CONTENT_TAXONOMY_UUID'
    ),
    steps: [
      'Select an NAA authority',
      'Select the regulatory part',
      'Select licence category',
      'Select course offering'
    ]
  },
  {
    name: 'Competency Course',
    value: 'ASQA',
    uuid: getEnvironmentVariable(
      'REACT_APP_HANGAR_COMPETENCY_COURSE_CONTENT_TAXONOMY_UUID'
    ),
    steps: [
      'Select AQF training package',
      'Select qualification',
      'Select unit of competency',
      'Select course offering'
    ]
  },
  {
    name: 'Short Course',
    value: 'AA',
    uuid: getEnvironmentVariable(
      'REACT_APP_HANGAR_SHORT_COURSE_CONTENT_TAXONOMY_UUID'
    ),
    steps: ['Select the responsible business unit', 'Select course offering']
  }
];

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  // fontFamily: `'Public Sans', sans-serif`,
  // fontFamily: `'Lato', sans-serif`, // Used to match Launchpad's font
  fontFamily: `'Inter', sans-serif`,
  i18n: 'en',
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: 'default',
  themeDirection: ThemeDirection.LTR,
  showLayout: true
};

export default config;
