import { axiosRequestJSON } from 'app/axios/axiosRequests';
import { getLearningOjectivesEndpoint } from '../endpoints/learningobjectives';
import { getHangarApiHeaders } from '../headers/headers';
import { buildQueryParams } from '../utils/urls';

export const searchLearningObjectives = async (
  taxonomyContains: string | undefined,
  topicStartsWith: string | undefined,
  onlyContent: boolean,
  excludeFoundIn: boolean,
  accessToken: string
) => {
  const queryParams = buildQueryParams({
    taxonomyContains,
    topicStartsWith,
    onlyContent,
    excludeFoundIn
  });
  const baseUrl = getLearningOjectivesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/search?${queryParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
};

export const getUniqueLearningObjective = async (
  learningObjective: string | undefined,
  learningObjectiveUuid: string | undefined,
  includeClo: boolean,
  includeFoundIn: boolean,
  accessToken: string
) => {
  const queryParams = buildQueryParams({
    learningObjective,
    learningObjectiveUuid,
    includeClo,
    includeFoundIn
  });
  const baseUrl = getLearningOjectivesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/read/unique?${queryParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
};

export const populateLearningObjectivesDatabase = async (
  taxonomyUuid: string,
  taxonomyPath: string | undefined,
  depthRemaining: string | undefined,
  accessToken: string
) => {
  const queryParams = buildQueryParams({
    path: taxonomyPath,
    depth: depthRemaining
  });
  const baseUrl = getLearningOjectivesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${taxonomyUuid}/populate?${queryParams}`;
  const res = await axiosRequestJSON('POST', url, headers, null);
  return res;
};
