/* eslint-disable no-useless-escape */

import { loIdRegexPattern } from 'app/service/utils/learningObjectives';

/**
 * Learning Objective
 */
export function deconstructLearningObjective(lo: string) {
  /**
   * Extract the identifier
   */
  const regex = loIdRegexPattern;
  const match = lo.match(regex);
  const identifier = match && typeof match[0] === 'string' ? match[0] : '';

  /**
   * Determine the level
   */
  const levelRegex = /\(Level \d+\)/;
  const supRegex = /\(S\)/;
  const match2 = lo.match(levelRegex);
  const match3 = lo.match(supRegex);

  /**
   * Suplementary
   */
  if (match3?.length === 1) {
    return {
      identifier,
      learningObjective: removeFullstop(
        lo.replace(identifier, '').replace(match3[0], '').trim()
      ),
      level: 'Supplementary'
    };
  }

  /**
   * Knowledge level 1-6
   */
  if (match2?.length === 1) {
    return {
      identifier,
      learningObjective: removeFullstop(
        lo.replace(identifier, '').replace(match2[0], '').trim()
      ),
      level: match2[0].replace(/[\(\)]/g, '')
    };
  }

  return null;
}

function removeFullstop(str: string) {
  if (str.endsWith('.')) {
    str = str.slice(0, -1);
  }
  return str;
}
