import beautify from 'js-beautify';

/**
 * Configuration of beautify
 * Documentation: https://www.npmjs.com/package/js-beautify
 */
const config = {
  unformatted: ['code', 'pre', 'em', 'strong', 'span'],
  indent_inner_html: true,
  indent_char: ' ',
  indent_size: 2,
  sep: '\n'
};

export function htmlBeautify(str: string) {
  return beautify.html(str, config);
}
