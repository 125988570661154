import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';
import { sanitiseParagraph } from './strings';

interface MappedStyles {
  bold?: boolean;
  italic?: boolean;
  superscript?: boolean;
  subscript?: boolean;
}

function splitAndKeepTags(inputString: string, tags: string[]): string[] {
  // Create a regex pattern to match any of the tags
  const tagPattern = tags.join('|').replace(/[<>]/g, (match) => `\\${match}`);
  const regex = new RegExp(`(${tagPattern})`);
  // Split the string by the regex, ensuring to keep the delimiters (tags)
  const parts = inputString.split(regex).filter((part) => part !== '');
  return parts;
}

function arrayToInlineBlockContent(arr: string[]): BlockInlineContent[] {
  const result: BlockInlineContent[] = [];
  let currentStyles: MappedStyles = {};

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];

    switch (element) {
      case '<b>':
      case '<strong>':
        currentStyles.bold = true;
        break;
      case '</b>':
      case '</strong>':
        currentStyles.bold = false;
        break;
      case '<i>':
      case '<em>':
        currentStyles.italic = true;
        break;
      case '</i>':
      case '</em>':
        currentStyles.italic = false;
        break;
      case '<sup>':
        currentStyles.superscript = true;
        break;
      case '</sup>':
        currentStyles.superscript = false;
        break;
      case '<sub>':
        currentStyles.subscript = true;
        break;
      case '</sub>':
        currentStyles.subscript = false;
        break;
      default:
        // When the element is not a tag, it's a text element
        const styles = { ...currentStyles };
        // Clean up false styles for cleaner output
        Object.keys(styles).forEach((key: string) => {
          // @ts-ignore
          if (!styles[key]) delete styles[key];
        });
        result.push({ text: element, type: 'text', styles });
        break;
    }
  }

  return result;
}

export function htmlToInlineContent(inputString: string): BlockInlineContent[] {
  /**
   * Accepted tags
   */
  const tags = [
    '<b>',
    '</b>',
    '<i>',
    '</i>',
    '<strong>',
    '</strong>',
    '<em>',
    '</em>',
    '<sup>',
    '</sup>',
    '<sub>',
    '</sub>'
  ];

  const sanitisedInput = sanitiseParagraph(inputString);
  const inputAsArray = splitAndKeepTags(sanitisedInput, tags);
  const output = arrayToInlineBlockContent(inputAsArray);
  return output;
}
