/**
 * Iterate over an array and return true if all items are true for the given key.
 * This only works for arrays of objects (single level).
 */
export function areAllTrueForKey<T extends object>(
  items: T[],
  key: keyof T
): boolean {
  for (const item of items) {
    if (!item[key]) {
      return false;
    }
  }
  return true;
}

export function orderStringsByNumbers(strings: string[]): string[] {
  return strings
    .map((s) => {
      const firstSpaceIndex = s.indexOf(' ');
      const prefix =
        firstSpaceIndex !== -1 ? s.substring(0, firstSpaceIndex) : s;
      const numericPrefix = prefix.split('.').map((part) => {
        const num = Number(part);
        return isNaN(num) ? -1 : num; // Treat non-numeric parts as -1
      });
      const isValid = numericPrefix.every((part) => part >= 0); // Check if all parts of the prefix are valid numbers

      return {
        original: s,
        prefix: numericPrefix,
        isValid: isValid
      };
    })
    .sort((a, b) => {
      if (!a.isValid && !b.isValid) return 0; // Both invalid, keep original order
      if (!a.isValid) return 1; // Invalids go to the end
      if (!b.isValid) return -1;

      const minLength = Math.min(a.prefix.length, b.prefix.length);
      for (let i = 0; i < minLength; i++) {
        if (a.prefix[i] !== b.prefix[i]) {
          return a.prefix[i] - b.prefix[i]; // Compare each element of the prefix arrays
        }
      }
      if (a.prefix.length !== b.prefix.length) {
        return a.prefix.length - b.prefix.length; // Shorter prefixes first if one is a subset of the other
      }
      return 0; // Same prefix, keep original order
    })
    .map(({ original }) => original); // Extract the original strings from the sorted array
}
