import { createErrorBlock } from '../../blocks/error';
import { ErrorBlock } from '../../blocks/error.types';
import { createSlideBlock } from '../../blocks/slide';
import {
  Element,
  MediaElement,
  SlideBlock,
  SlideObjectData,
  SlideElement,
  SlideFormat
} from '../../blocks/slide.types';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';
import { htmlToInlineContent } from '../../utils/htmlToInlineContent';

// export function slideElementToBlock(
//   element: HTMLElement
// ): ParagraphBlock | ErrorBlock {
//   const blockId = extractBlockIdFromElementId(element.id);
//   return null;
// }

const acceptedSlideFormats: string[] = [
  'text',
  'media',
  'media-text',
  'text-media'
];

export function slideElementToBlock(
  element: HTMLElement
): SlideBlock | ErrorBlock {
  const blockId = extractBlockIdFromElementId(element.id);
  let slideElements: SlideElement[] = [];
  let notes = '';
  const format = (element.dataset.slideFormat || '') as SlideFormat;
  const slideTitle = element.dataset.slideTitle;
  let content = htmlToInlineContent(slideTitle ? slideTitle : '');
  const containsMedia = element.dataset.slideContainsMedia ? true : undefined;
  const showTitle =
    element.dataset.slideShowTitle === 'true'
      ? true
      : element.dataset.slideShowTitle === 'false'
      ? false
      : undefined;

  if (!acceptedSlideFormats.includes(format))
    return createErrorBlock(
      `Invalid slide format "${format}" for slide with ID ${blockId}. Cannot create slide block.`,
      '',
      blockId
    );

  const htmlCollection = element.children;
  for (let i = 0; i < htmlCollection.length; i++) {
    const childElement = htmlCollection.item(i)! as HTMLElement;

    if (childElement.hasAttribute('data-slide-notes')) {
      notes = notes + childElement.innerHTML.trim();
    }

    if (childElement.hasAttribute('data-slide-element')) {
      let slideElement = {
        type: childElement.dataset.slideElementType!,
        html: childElement.innerHTML.trim(),
        fragment: childElement.dataset.slideElementFragment !== undefined,
        animation: childElement.dataset.slideElementAnimation!
      } as Element;

      if (slideElement.type === 'text') slideElements.push(slideElement);
      else if (slideElement.type === 'media') {
        const element = childElement.dataset.slideElementMedia!;
        const url = childElement.dataset.slideElementUrl!;
        const caption = childElement.dataset.slideElementCaption!;
        const mediaElement = {
          ...slideElement,
          media: element,
          url: url,
          caption: caption
        } as MediaElement;
        slideElements.push(mediaElement);
      } else if (slideElement.type === 'list') slideElements.push(slideElement);
      /**
       * TODO implement list type element
       */
    }
  }

  const data: SlideObjectData = {
    slide: slideElements,
    notes: notes,
    containsMedia,
    showTitle
  };
  return createSlideBlock(data, content, format, blockId);
}
