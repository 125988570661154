import { validateOrCreateUuidV4 } from '../utils/uuid';
import { BlockInlineContent } from './common.types';
import { SlideBlock, SlideObjectData, SlideFormat } from './slide.types';

/**
 * Create slide editor-js block data
 */
export function createSlideBlock(
  data: SlideObjectData,
  content: BlockInlineContent[],
  format: SlideFormat,
  id: string
): SlideBlock {
  const blockId = validateOrCreateUuidV4(id);
  const stringFormattedData: string = JSON.stringify(data);
  const slideBlock: SlideBlock = {
    id: blockId,
    type: 'slide',
    props: {
      format,
      slideJSON: stringFormattedData
    },
    content
  };
  return slideBlock;
}
