import html2blocksV1 from './map-legacy';
import html2blocksV2 from './map';

/**
 * Export the functions as an object
 */
const html2blocks = {
  content: html2blocksV2,
  legacy: {
    content: html2blocksV1
  }
};

export default html2blocks;
