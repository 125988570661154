import { getEnvironmentVariable } from 'app/service/utils/environment';
import { createHangarFileUrl } from 'app/service/utils/hangar-urls';

export function createRuffleUrl(apiUrl: string) {
  const ruffleAssetUuid = getEnvironmentVariable('REACT_APP_FWA_RUFFLE_UUID');
  const ruffleAssetVersion = getEnvironmentVariable(
    'REACT_APP_FWA_RUFFLE_VERSION'
  );
  const ruffleUrl = createHangarFileUrl(
    ruffleAssetUuid,
    ruffleAssetVersion,
    'index.html'
  );
  return `${ruffleUrl}?url=${encodeURI(apiUrl)}`;
}
