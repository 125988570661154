import { axiosRequestJSON } from 'app/axios/axiosRequests';
import { getCoursesEndpoint } from '../endpoints/courses';
import { getHangarApiHeaders } from '../headers/headers';
import { buildQueryParams } from '../utils/urls';
import {
  PostCourseAction,
  PostCourseRequestBody
} from '../../../types/integration/courses.api.types';
import { validate } from 'uuid';

/**
 * ==========================================================>>
 * GET /courses - Searches for course templates
 * ==========================================================>>
 */
export async function searchCourses(params: object, accessToken: string) {
  const searchParams = buildQueryParams(params);
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}?${searchParams}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * GET /courses/{courseId} - Gets a course template
 * ==========================================================>>
 */
export async function getCourseTemplate(
  uuid: string,
  infoLevel: string,
  accessToken: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}?info=${infoLevel}`; // note: resolve is set to false
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * GET /courses/{courseId}/actions - Gets actions for a course
 * ==========================================================>>
 */
export async function getCourseActions(
  uuid: string,
  accessToken: string,
  type: 'jobs' | 'logs'
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/actions?type=${type}`; // note: resolve is set to false
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * GET /courses/{courseId} - Gets a course template
 * ==========================================================>>
 */
export async function getCourseWithMasters(uuid: string, accessToken: string) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}?info=master`; // note: resolve is set to false
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * GET /courses/{courseId} - Gets a course template
 * ==========================================================>>
 */
export async function getLegacyCourseTemplate(
  uuid: string,
  accessToken: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}?legacy=true`; // note: resolve is set to false
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * GET /courses/{courseId} - Gets a course template
 * ==========================================================>>
 */
export async function getLegacyCourseContent(
  uuid: string,
  accessToken: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}?info=content&legacy=true`; // note: resolve is set to false
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ==========================================================>>
 * POST /courses - Creates a new course template
 * ==========================================================>>
 */
export async function postNewCourse(
  data: PostCourseRequestBody,
  accessToken: string,
  migrationUuid?: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const isValidUuid = validate(migrationUuid || '');
  const url = isValidUuid
    ? `${baseUrl}?migrationUuid=${migrationUuid}`
    : baseUrl;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * POST /courses/{courseId} - Updates an existing course template
 * ==========================================================>>
 */
export async function postExistingCourse(
  uuid: string,
  data: PostCourseRequestBody,
  accessToken: string,
  migrationUuid?: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const isValidUuid = validate(migrationUuid || '');
  const url = isValidUuid
    ? `${baseUrl}/${uuid}?migrationUuid=${migrationUuid}`
    : `${baseUrl}/${uuid}`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * ==========================================================>>
 * POST /courses/{courseId}/actions - Create an action for a course
 * ==========================================================>>
 */
export async function postCourseAction(
  uuid: string,
  data: PostCourseAction,
  accessToken: string
) {
  const baseUrl = getCoursesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/actions`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}
