import { htmlCollectionToArray } from '../utils/html';

/**
 * HTMl Summary Content to Editor Object
 */
export function htmlSummary2Obj(html: string): string | null {
  /**
   * Parse HTML as virtual DOM
   */
  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  const bodyElement = document.getElementsByTagName('BODY')[0];
  if (!bodyElement) {
    console.error('Error: No body element found in the HTML');
    return null;
    // return [
    //   createErrorBlock(
    //     'An error occurred trying to load this content from the Hangar, please let a PDD team member know ASAP!'
    //   )
    // ];
  }

  /**
   * Check that the heading is "Summary"
   * Anything else is invalid.
   */
  if (
    bodyElement
      .getElementsByClassName('i-heading')[0]
      ?.textContent?.toLowerCase() === 'summary'
  ) {
    /**
     * Find paragraph elements and convert to paragraph blocks
     * Ignore ALL other elements
     */
    const paragraphs = htmlCollectionToArray(
      bodyElement.getElementsByTagName('P')
    );
    const paragraphEditorBlocks = paragraphs.map((e) => {
      return e.textContent;
    });
    return paragraphEditorBlocks.join('');
  } else {
    // if no heading found - return an error
    console.error(
      'Error: No accordions found and the child block is not a Summary block'
    );
    return null;
    // return [
    //   createErrorBlock(
    //     'An error occurred trying to load this content from the Hangar, please let a PDD team member know ASAP!'
    //   )
    // ];
  }
}
