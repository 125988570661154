import { BlockInlineContent, DefaultProps } from '../blocks/common.types';
import {
  createBulletListBlock,
  createNumberedListBlock
} from '../blocks/lists';
import { ListArray, ListBlock } from '../blocks/lists.types';
import { sanitiseParagraph } from '../utils/strings';

/**
 * Default Blocknote props
 */
export const defaultProps: DefaultProps = {
  backgroundColor: 'default',
  textAlignment: 'left',
  textColor: 'default'
};

export function createBlockInlineContent(text: string): BlockInlineContent {
  return {
    text: sanitiseParagraph(text || ''),
    type: 'text',
    styles: {}
  };
}

export function recursiveConvertListArrayToBlocks(
  listArray: ListArray[],
  type: 'ol' | 'ul'
): ListBlock[] {
  return listArray.map((item) => {
    // Convert the content string to BlockInlineContent
    const content: BlockInlineContent = createBlockInlineContent(item.content);
    // Recursively process the children items, if any
    const children: ListBlock[] = item.items
      ? recursiveConvertListArrayToBlocks(item.items, type)
      : [];
    // Use the provided function to create a ListBlock
    if (type === 'ol') {
      return createNumberedListBlock([content], defaultProps, children);
    } else {
      return createBulletListBlock([content], defaultProps, children);
    }
  });
}
