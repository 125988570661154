import { DocumentMetadataObject } from 'app/types/integration/documents.api.types';
import {
  TaxonomyTerms,
  TaxonomyMetadata,
  TaxonomyComponentObject
} from 'app/types/integration/taxonomies.api.types';
import { RegulatorType } from 'app/types/service/courses.types';
import { DocumentMetadataEditorObject } from 'app/types/service/document.types';
import { taxonomyMetadataToLearningObjectives } from './learningObjectives';
import { ensureArray, removeDuplicates } from 'utils/arrays';
import { deconstructLearningObjective } from './deconstructLO';

export type TaxonomyComponents = {
  authority: string;
  fullTitle: string;
  taxonomy: string;
};

/**
 * This has been made a switch in case we need to add more custom logic
 */
export function extractTaxonomyComponentsFromTaxonomyString(
  regulator: RegulatorType,
  taxonomyString: string
): TaxonomyComponents | null {
  const taxonomyComponents = taxonomyString.split('\\');
  const courseTitle = taxonomyComponents.pop();
  switch (regulator) {
    case 'NAA':
      return {
        authority: taxonomyComponents[0],
        fullTitle: courseTitle || '',
        taxonomy: taxonomyString
      };
    case 'ASQA':
      return {
        authority: taxonomyComponents[0],
        fullTitle: courseTitle || '',
        taxonomy: taxonomyString
      };
    case 'AA':
      return {
        authority: taxonomyComponents[0],
        fullTitle: courseTitle || '',
        taxonomy: taxonomyString
      };
    default:
      return null;
  }
}

export const createDocumentRegulatoryTaxonomyEntries = (
  courseRegulatorType: RegulatorType,
  documentMetadataObject: DocumentMetadataObject | DocumentMetadataEditorObject
): string[] => {
  const documentTaxonomies: string[] = [];
  if (documentMetadataObject.content) {
    documentMetadataObject.content.forEach((contentResponse) => {
      if (contentResponse) {
        const documentLocation = documentMetadataObject.location;
        const taxonomyMetadata = contentResponse.taxonomy;
        if (!taxonomyMetadata) {
          console.error(
            `Content with heading ${contentResponse.content.heading} has no taxonomy metadata`
          );
          console.error(contentResponse);
        } else {
          const contentTaxonomies = taxonomyMetadataToLearningObjectives(
            documentLocation,
            taxonomyMetadata
          );
          documentTaxonomies.push(...contentTaxonomies);
        }
      }
    });
  }
  return documentTaxonomies;
};

export const taxonomyMetadataToFullTermTaxonomies = (
  metadata: TaxonomyMetadata | undefined
): string[] => {
  let fullTermTaxonomies: string[] = [];
  if (!metadata) return fullTermTaxonomies;

  if (metadata.competency.entry && metadata.competency.entry.length > 0) {
    const courseType: RegulatorType = 'ASQA';
    const entries = ensureArray(metadata.competency.entry);
    const competencyEntries = entries.map((entry) => `${courseType}\\${entry}`);
    fullTermTaxonomies.push(...competencyEntries);
  }

  if (metadata.regulatory.entry && metadata.regulatory.entry.length > 0) {
    const courseType: RegulatorType = 'NAA';
    const entries = ensureArray(metadata.regulatory.entry);
    const regulatorEntries = entries.map((entry) => `${courseType}\\${entry}`);
    fullTermTaxonomies.push(...regulatorEntries);
  }

  if (metadata.short.entry && metadata.short.entry.length > 0) {
    const courseType: RegulatorType = 'AA';
    const entries = ensureArray(metadata.short.entry);

    const shortEntries = entries.map((entry) => `${courseType}\\${entry}`);
    fullTermTaxonomies.push(...shortEntries);
  }

  return fullTermTaxonomies;
};

export const createTaxonomyMetadata = (
  fullTermTaxonomies: string[]
): TaxonomyMetadata => {
  /**
   * Extract knowledge levels and learning objectives from the taxonomy input data
   * Create a JSON representation of the taxonomy metadata
   */
  const regulators: string[] = [];
  const regulatory: TaxonomyTerms = { entry: [] };
  const competency: TaxonomyTerms = { entry: [] };
  const short: TaxonomyTerms = { entry: [] };

  const taxonomyComponentObjects: TaxonomyComponentObject[] = [];
  /**
   * Iterate through the taxonomy input data
   * > Split the taxonomy into the regulator groups
   * > Extract the knowledge levels
   */
  const uniqueTaxonomies = removeDuplicates(fullTermTaxonomies);
  for (let index = 0; index < uniqueTaxonomies.length; index++) {
    const inputTaxon = uniqueTaxonomies[index];
    const inputTaxonArr = inputTaxon.split('\\');
    const taxon = inputTaxonArr.slice(1).join('\\');

    const regulator = inputTaxonArr[0];
    switch (regulator) {
      case 'NAA':
        const naaAuthority = inputTaxonArr[1];
        const naaRegulation = inputTaxonArr[2];
        const naaLicenceCategory = inputTaxonArr[3];
        const naaCourseOffering = inputTaxonArr[4];
        const naaTopic = inputTaxonArr[5];
        const naaContentLearningObjective = inputTaxonArr[6];
        const naaLoDeconstructed = deconstructLearningObjective(
          naaContentLearningObjective
        );
        const naaContentId = naaLoDeconstructed.id ?? '';
        const naaLearningObjective = naaLoDeconstructed.ulo ?? '';

        taxonomyComponentObjects.push({
          regulator,
          authority: naaAuthority,
          regulation: naaRegulation,
          licenceCategory: naaLicenceCategory,
          courseOffering: naaCourseOffering,
          topic: naaTopic,
          contentLearningObjective: naaContentLearningObjective,
          contentId: naaContentId,
          learningObjective: naaLearningObjective
        });
        regulatory.entry.push(taxon);
        regulators.push(regulator);
        break;
      case 'ASQA':
        const asqaTrainingPackage = inputTaxonArr[1];
        const asqaQualification = inputTaxonArr[2];
        const asqaUoc = inputTaxonArr[3];
        const asqaCourseOffering = inputTaxonArr[4];
        const asqaTopic = inputTaxonArr[5];
        const asqaContentLearningObjective = inputTaxonArr[6];
        const asqaLoDeconstructed = deconstructLearningObjective(
          asqaContentLearningObjective
        );
        const asqaContentId = asqaLoDeconstructed.id ?? '';
        const asqaLearningObjective = asqaLoDeconstructed.ulo ?? '';

        competency.entry.push(taxon);
        regulators.push(regulator);
        taxonomyComponentObjects.push({
          regulator,
          trainingPackage: asqaTrainingPackage,
          qualification: asqaQualification,
          uoc: asqaUoc,
          courseOffering: asqaCourseOffering,
          topic: asqaTopic,
          contentLearningObjective: asqaContentLearningObjective,
          contentId: asqaContentId,
          learningObjective: asqaLearningObjective
        });
        break;
      case 'AA':
        const aaBusinessUnit = inputTaxonArr[1];
        const aaCourseOffering = inputTaxonArr[2];
        const aaTopic = inputTaxonArr[3];
        const aaContentLearningObjective = inputTaxonArr[4];
        const aaLoDeconstructed = deconstructLearningObjective(
          aaContentLearningObjective
        );
        const aaContentId = aaLoDeconstructed.id ?? '';
        const aaLearningObjective = aaLoDeconstructed.ulo ?? '';

        short.entry.push(taxon);
        regulators.push(regulator);
        taxonomyComponentObjects.push({
          regulator,
          businessUnit: aaBusinessUnit,
          courseOffering: aaCourseOffering,
          topic: aaTopic,
          contentLearningObjective: aaContentLearningObjective,
          contentId: aaContentId,
          learningObjective: aaLearningObjective
        });

        break;
      default:
        break;
    }
  }

  const metadata: TaxonomyMetadata = {
    taxonType: removeDuplicates(regulators),
    regulatory: regulatory,
    competency: competency,
    short: short,
    entries: {
      entry: taxonomyComponentObjects
    }
  };

  return metadata;
};
