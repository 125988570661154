import { HeadingProps } from 'app/libraries/html2blocks/blocks/heading.types';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';

/**
 * Create Heading 3 element
 */
export function H3(
  id: string,
  heading: string,
  position: string,
  props: HeadingProps
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
    <h3 
      id="block-element-${id}" 
      data-block
      data-type="heading" 
      data-element-pos="${position}" 
      data-props="${propsJSON}"
    >
      ${heading}
    </h3>`;
}

/**
 * Create Heading 4 element
 */
export function H4(
  id: string,
  heading: string,
  position: string,
  props: HeadingProps
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
    <h4 
      id="block-element-${id}" 
      data-block
      data-type="heading" 
      data-element-pos="${position}" 
      data-props="${propsJSON}"
    >
      ${heading}
    </h4>`;
}

/**
 * Create Heading 5 element
 */
export function H5(
  id: string,
  heading: string,
  position: string,
  props: HeadingProps
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
    <h5 
      id="block-element-${id}" 
      data-block
      data-type="heading"
      data-element-pos="${position}" 
      data-props="${propsJSON}"
    >
      ${heading}
    </h5>`;
}

/**
 * Create Heading 6 element
 */
export function H6(
  id: string,
  heading: string,
  position: string,
  props: HeadingProps
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
    <h6 
      id="block-element-${id}" 
      data-block
      data-type="heading"
      data-element-pos="${position}" 
      data-props="${propsJSON}"
    >
      ${heading}
    </h6>`;
}
