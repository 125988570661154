/**
 * HTML Teaching Point
 */

import { DefaultProps } from 'app/libraries/html2blocks/blocks/common.types';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';

export function TeachingPoint(
  id: string,
  text: string,
  props: DefaultProps,
  position: string
) {
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
  <p 
    id="block-element-${id}" 
    data-block 
    data-type="tp"
    data-tp-text="${text}"
    data-props="${propsJSON}"
    data-element-pos="${position}"
  >
    ${text}
  </p>
  `;
}
