import { loIdRegexPattern } from './learningObjectives';

/**
 * Deconstruct Learning Objective into three components
 */
export function deconstructLearningObjective(lo: string) {
  /**
   * Extract the identifier
   */
  const regex = loIdRegexPattern;
  const match = lo.match(regex);
  const identifier = match && typeof match[0] === 'string' ? match[0] : '';

  /**
   * Determine the level
   */
  const legacyLevelRegex = /\[L(\d+)\]/;
  const levelRegex = /\(Level (\d)+\)/;
  const supRegex = /\(S\)/;
  const match1 = lo.match(legacyLevelRegex); // Legacy Level match
  const match2 = lo.match(levelRegex); // Level match
  const match3 = lo.match(supRegex); // Suplementary match

  /**
   * Suplementary
   */
  if (match3 !== null) {
    const loText = removeFullstop(
      lo.replace(identifier, '').replace(match3[0], '').trim()
    );
    return {
      id: identifier,
      text: loText,
      ulo: `${loText} (S)`,
      level: 0,
      error: false,
      full: `${identifier} ${loText} (S)`,
      origin: lo
    };
  }

  /**
   * Knowledge level 1-6
   */
  if (match2 !== null) {
    const levelString = match2?.[0].trim();
    const levelNumber = match2?.[1].trim();
    const loText = removeFullstop(
      lo.replace(identifier, '').replace(levelString, '').trim()
    ).trim();
    return {
      id: identifier,
      text: loText,
      ulo: `${loText} (Level ${levelNumber})`,
      level: parseInt(levelNumber),
      error: false,
      full: `${identifier} ${loText} (Level ${levelNumber})`,
      origin: lo
    };
  }

  if (match1 !== null) {
    const levelString = match1?.[0].trim();
    const levelNumber = match1?.[1].trim();
    const loText = removeFullstop(
      lo.replace(identifier, '').replace(levelString, '').trim()
    ).trim();
    return {
      id: identifier,
      text: loText,
      ulo: `${loText} (Level ${levelNumber})`,
      level: parseInt(levelNumber),
      error: false,
      full: `${identifier} ${loText} (Level ${levelNumber})`,
      origin: lo
    };
  }

  // console.error(`Failed to deconstruct: ${lo}`);
  return {
    id: null,
    text: null,
    ulo: null,
    level: null,
    error: true,
    full: lo,
    origin: lo
  };
}

function removeFullstop(str: string) {
  if (str.endsWith('.')) {
    str = str.slice(0, -1);
  }
  return str;
}
