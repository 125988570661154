import { createParagraphBlock } from '../../blocks/paragraph';
import { ParagraphBlock, ParagraphProps } from '../../blocks/paragraph.types';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';
import { htmlToInlineContent } from '../../utils/htmlToInlineContent';
import { createErrorBlock } from '../../blocks/error';
import { ErrorBlock } from '../../blocks/error.types';

export function paragraphElementToBlock(
  element: HTMLElement
): ParagraphBlock | ErrorBlock {
  const blockId = extractBlockIdFromElementId(element.id);

  const blockPropsJSONEncoded = element.dataset.props;
  if (!blockPropsJSONEncoded) {
    return createErrorBlock(
      `Failed to create Paragraph with ID "${blockId}", missing props.`,
      '',
      blockId
    );
  }
  const blockPropsJSON = decodeURI(blockPropsJSONEncoded);
  let blocksProps: ParagraphProps;
  try {
    blocksProps = JSON.parse(blockPropsJSON);
  } catch (e) {
    return createErrorBlock(
      `Failed to parse Paragraph block props with ID "${blockId}", ${e}`,
      '',
      blockId
    );
  }

  const blockInlineContent = htmlToInlineContent(element.innerHTML);
  return createParagraphBlock(blockInlineContent, blocksProps, blockId);
}
