import { orderStringsByNumbers } from 'app/service/utils/arrays';
import { deconstructLearningObjective } from '../../../../utils/deconstructLO';

/**
 * Creates a list of learning objectives HTML elements
 */
export function LearningObjectives(id: string, array: string[]) {
  const orderedLos = orderStringsByNumbers(array);
  const los = orderedLos
    .map((lo: string) => {
      const loObj = deconstructLearningObjective(lo);
      if (loObj) {
        const { identifier, learningObjective, level } = loObj;

        const LOFontClasses = 'text-base md:text-lg';

        let levelHtml = '';
        if (level === 'Supplementary') {
          levelHtml = `<span class="${LOFontClasses} inline-block bg-yellow-100 dark:bg-yellow-700 text-yellow-800 dark:text-yellow-200 ml-0.5 font-medium mr-2 px-2.5 py-0.5 rounded-full">${level}</span>`;
        } else {
          levelHtml = `<span class="${LOFontClasses} inline-block bg-green-100 dark:bg-green-700 text-green-800 dark:text-green-200 ml-0.5 font-medium mr-2 px-2.5 py-0.5 rounded-full">${level}</span>`;
        }

        return `
        <div 
          data-lo="${lo}"
          class="inline-flex text-left items-center text-sm font-medium">
            <span class="${LOFontClasses} bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">${identifier}</span>
            <p class="${LOFontClasses} font-medium text-gray-600 dark:text-gray-400 mt-1 mb-1">
                ${learningObjective}
                ${levelHtml}
            </p>
        </div><br/>`;
      } else return '';
    })
    .join('\n');

  /**
   * NOTE: This is not a block element
   */
  return `
  <div 
    id="content-lo-group-${id}" 
    data-type="lo-group" 
  >${los}</div>`;
}
