import { BlockInlineContent } from '../blocks/common.types';
import { createSlideBlock } from '../blocks/slide';
import {
  SlideBlock,
  SlideObjectData,
  SlideElement,
  SlideFormat
} from '../blocks/slide.types';
import { removeFluffFromString } from '../utils/strings';
import { validateOrCreateUuidV4 } from '../utils/uuid';

// Helper
function replaceCarrotsWithDotPoints(input: string): string {
  // First, replace all '>' characters with '&gt;'
  let sanitizedInput = input.replace(/>/g, '&gt;');

  // Regular expression to match '&gt;' characters (and any spaces between them) at the start of the string
  const regex = /^(&gt;\s*)+/;

  return sanitizedInput.replace(regex, (match) => {
    // Count the number of '&gt;' occurrences in the match to determine the number of four-space groups
    const count = match.split('&gt;').length - 1;
    const spaces = '    '.repeat(count - 1);
    return spaces + '• ';
  });
}

export function generateSlidesFromLegacyHtml(
  summaryPointList: string[]
): SlideBlock[] {
  const blocks: SlideBlock[] = [];
  let tempSlideInfo: string[] = [];
  let tempSlideFormat: SlideFormat = 'media';
  let tempSlideTitle = '';
  let tempSlideBlockContent: BlockInlineContent[] = [
    {
      text: tempSlideTitle,
      type: 'text',
      styles: {}
    }
  ];

  for (let index = 0; index < summaryPointList.length; index++) {
    const rawElement = summaryPointList[index];
    const element = replaceCarrotsWithDotPoints(rawElement);

    /**
     * Check summary point commands
     */
    if (element === '[NEXT_SLIDE]') {
      if (tempSlideInfo.length === 0) {
        // If tempSlideInfo is empty, create media slide.
        const newSlideConfig: SlideObjectData = {
          slide: [
            {
              type: 'media',
              html: '',
              fragment: false,
              animation: 'none',
              media: 'img',
              url: '',
              caption: ''
            }
          ],
          notes: ''
        };
        const newElementId = validateOrCreateUuidV4();
        blocks.push(
          createSlideBlock(
            newSlideConfig,
            tempSlideBlockContent,
            'media',
            newElementId
          )
        );
      } else {
        // If tempSlideInfo is not empty, create text slide.
        const newSlideConfig: SlideObjectData = {
          slide: tempSlideInfo.map((e: string): SlideElement => {
            const sanitizedHtml = removeFluffFromString(e);
            return {
              type: 'text',
              html: sanitizedHtml,
              fragment: true,
              animation: 'fade-in'
            };
          }),
          notes: ''
        };
        const newElementId = validateOrCreateUuidV4();
        blocks.push(createSlideBlock(newSlideConfig, [], 'text', newElementId));
      }
      tempSlideFormat = 'media';
      tempSlideInfo = [];
    } else if (element === '[TEXT_SLIDE]') {
      tempSlideFormat = 'text';
    } else {
      if (tempSlideFormat === 'media') {
      } // Do nothing
      else if (tempSlideFormat === 'text') {
        tempSlideInfo.push(element);
      }
    }

    /**
     * Check if last element
     */
    if (index === summaryPointList.length - 1) {
      if (element !== '[NEXT_SLIDE]') {
        if (tempSlideInfo.length === 0) {
          // If tempSlideInfo is empty, create media slide.
          const newSlideConfig: SlideObjectData = {
            slide: [
              {
                type: 'media',
                html: '',
                fragment: false,
                animation: 'none',
                media: 'img',
                url: '',
                caption: ''
              }
            ],
            notes: ''
          };
          const newElementId = validateOrCreateUuidV4();
          blocks.push(
            createSlideBlock(newSlideConfig, [], 'media', newElementId)
          );
        } else {
          // If tempSlideInfo is not empty, create text slide.
          const newSlideConfig: SlideObjectData = {
            slide: tempSlideInfo.map((e: string): SlideElement => {
              const sanitizedHtml = removeFluffFromString(e);
              return {
                type: 'text',
                html: sanitizedHtml,
                fragment: true,
                animation: 'fade-in'
              };
            }),
            notes: ''
          };
          const newElementId = validateOrCreateUuidV4();
          blocks.push(
            createSlideBlock(newSlideConfig, [], 'text', newElementId)
          );
        }
      }
    }
  }

  return blocks;
}
