/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme
} from '@mui/material';
import { getTaxonomyTerms } from 'app/integration/server/library/taxonomies';
import React, { useState, useEffect } from 'react';
import useHangarAuth from 'hooks/useHangarAuth';
import { Stack } from '@mui/system';
import { taxonomiesPathSelect } from 'config';
import {
  TaxonomyDefinition,
  TaxonomyTermObj
} from 'app/types/course-migration.types';
import MainCard from 'components/app/MainCard';

export const TaxonomyTermSelector = (props: {
  excludeLastStep: boolean;
  setTaxonomyPath: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { user } = useHangarAuth();
  const accessToken: string = user?.accessToken;
  const theme = useTheme();

  const taxonomyUuids = taxonomiesPathSelect.map((t) => t.uuid);
  const [selectedTaxonomyUuid, setSelectedTaxonomyUuid] = useState<
    string | undefined
  >();
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<
    TaxonomyDefinition | undefined
  >();

  const [selectedTaxonomyTerms, setSelectedTaxonomyTerms] = useState<string[]>(
    []
  );
  const [taxonomyTermsToSelectFrom, setTaxonomyTermsToSelectFrom] = useState<
    TaxonomyTermObj[][]
  >([]);

  const handleSelectTaxonomy = (event: SelectChangeEvent) => {
    const uuid = event.target.value;
    setSelectedTaxonomyUuid(uuid);
    setSelectedTaxonomy(taxonomiesPathSelect.find((t) => t.uuid === uuid));
    setSelectedTaxonomyTerms([]);
  };
  const [fetchingTerms, setFetchingTerms] = useState(false);

  const termsUuidToPath = (termsUuid: string[]): string =>
    termsUuid
      .map(
        (selected, selectedIndex) =>
          taxonomyTermsToSelectFrom[selectedIndex].find(
            (t) => t.uuid === selected
          )?.term
      )
      .join('\\');

  /**
   * Fetch initial terms when taxonomy tree is selected
   */
  useEffect(() => {
    const fetchInitialTerms = async () => {
      if (!selectedTaxonomy) return;
      setFetchingTerms(true);
      const getTaxonomyTermsResponse = await getTaxonomyTerms(
        selectedTaxonomy.uuid,
        '',
        accessToken
      );
      if (getTaxonomyTermsResponse.status !== 200) return;

      const terms = [getTaxonomyTermsResponse.data.terms];
      console.log(terms);
      setTaxonomyTermsToSelectFrom(terms);
      setSelectedTaxonomyTerms([]);
      setFetchingTerms(false);
    };

    fetchInitialTerms();
  }, [selectedTaxonomy, accessToken]);

  /**
   * Handle term selection
   */
  const handleSelectTaxonomyTerm = async (
    event: SelectChangeEvent,
    stepIndex: number
  ) => {
    if (!selectedTaxonomy) return;
    setFetchingTerms(true);
    const newSelectedTaxonomyTerms = [
      ...selectedTaxonomyTerms.slice(0, stepIndex),
      event.target.value
    ];
    setSelectedTaxonomyTerms(newSelectedTaxonomyTerms);

    const selectedTaxonomyTermsPath = termsUuidToPath(newSelectedTaxonomyTerms);
    const getTaxonomyTermsResponse = await getTaxonomyTerms(
      selectedTaxonomy.uuid,
      selectedTaxonomyTermsPath,
      accessToken
    );
    if (getTaxonomyTermsResponse.status !== 200) return;
    const newTaxonomyTermsToSelectFrom = getTaxonomyTermsResponse.data.terms;
    setTaxonomyTermsToSelectFrom((taxonomyTermsToSelectFrom) => [
      ...taxonomyTermsToSelectFrom.slice(0, stepIndex + 1),
      newTaxonomyTermsToSelectFrom
    ]);
    setFetchingTerms(false);
  };

  /**
   * Set taxonomy path for parent component
   */
  useEffect(() => {
    if (!selectedTaxonomy) return;

    const nStepsToComplete = props.excludeLastStep
      ? selectedTaxonomy.steps.length - 1
      : selectedTaxonomy.steps.length;

    if (selectedTaxonomyTerms.length === nStepsToComplete) {
      const termsPath = `${selectedTaxonomy.value}\\${termsUuidToPath(
        selectedTaxonomyTerms
      )}`;
      props.setTaxonomyPath(termsPath);
    }
  });

  return (
    <div>
      <Box
        sx={{
          mt: '1em',
          border: '1px solid',
          borderColor: theme.palette.secondary.light,
          borderRadius: '4px',
          background: 'white',
          minHeight: '44px',
          pl: '12px',
          textAlign: 'center'
        }}
      >
        <p>{`${selectedTaxonomy?.value ? `${selectedTaxonomy.value}\\` : ''
          }${termsUuidToPath(selectedTaxonomyTerms)}`}</p>
      </Box>
      <Box sx={{ mt: '1em', mb: '0.5em' }}>
        <Typography>
          <strong>Term Selector</strong>
        </Typography>

        <MainCard sx={{ p: '1em' }}>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="taxonomy-select-label">
                What type of course is this?
              </InputLabel>
              <Select
                value={selectedTaxonomyUuid ?? ''}
                labelId="taxonomy-select-label"
                onChange={handleSelectTaxonomy}
              >
                {taxonomiesPathSelect.map((t, index) => {
                  return taxonomyUuids.includes(t.uuid) ? (
                    <MenuItem
                      key={`select-taxonomy-item-${index}`}
                      value={t.uuid}
                    >
                      {t.name}
                    </MenuItem>
                  ) : null;
                })}
              </Select>
            </FormControl>
            {selectedTaxonomy &&
              taxonomyTermsToSelectFrom.length > 0 &&
              selectedTaxonomy.steps
                .slice(0, selectedTaxonomyTerms.length + 1)
                .map((s, stepIndex) => {
                  if (taxonomyTermsToSelectFrom.length === stepIndex) {
                    console.log('return null');
                    return null;
                  }
                  if (
                    props.excludeLastStep &&
                    stepIndex + 1 === selectedTaxonomy.steps.length
                  )
                    return null;
                  return (
                    <FormControl
                      key={`taxonomy-step-form-control-${stepIndex}`}
                      fullWidth
                    >
                      <InputLabel id={`taxonomy-step-select-label`}>
                        {s}
                      </InputLabel>
                      <Select
                        value={
                          selectedTaxonomyTerms.length > 0
                            ? selectedTaxonomyTerms[stepIndex] ?? ''
                            : ''
                        }
                        onChange={(event) =>
                          handleSelectTaxonomyTerm(event, stepIndex)
                        }
                      >
                        {taxonomyTermsToSelectFrom[stepIndex].map(
                          (t, termIndex) => {
                            return (
                              <MenuItem
                                value={t.uuid}
                                key={`taxonomy-step-${stepIndex}-term-${termIndex}`}
                              >
                                {t.term}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  );
                })}
          </Stack>
        </MainCard>

        {fetchingTerms && <LinearProgress />}
      </Box>
    </div>
  );
};
