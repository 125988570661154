/**
 * HTML Navbar
 */
export function Navbar(logoUrl: string) {
  return `
    <nav id="page-navbar" class="border-gray-200 bg-aa-red">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

            <!-- Table of Contents Button -->
            <button id="page-navbar-toc-button"
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                type="button" aria-controls="drawer-left-toc" onclick="tocHandler();">
                <span class="sr-only">Open table of contents</span>
                <svg class="w-6 h-6 fill-white" aria-hidden="true" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"></path>
                </svg>
            </button>

            <!-- Aviation Australia Logo -->
            <a id="page-logo" class="flex items-center">
                <img src="${logoUrl}"
                    class="h-8 mr-3" alt="Aviation Australia Logo" />
            </a>

            <div class="flex items-center">

                <!-- Button -->
                <button
                    class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                    type="button" aria-controls="expand-all-accordions" onclick="toggleAllAccordions();">
                    <span class="sr-only">Toggle all accordions</span>
                    <span id="all-accordion-toggle-button"></span>
                </button>

                <!-- Button -->
                <button
                    class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                    type="button" aria-controls="drawer-right-settings" onclick="toggleDrawer(1);">
                    <span class="sr-only">Open settings</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="white" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                    </svg>
                </button>

            </div>
        </div>
    </nav>
    `;
}
