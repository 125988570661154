import {
  getCurrentServerEnvironmentCode,
  getEnvironmentVariable
} from './environment';

interface HangarUrlObject {
  classification: string;
  environment: string;
  uuid: string;
  version: string;
  filename: string | null;
  attachmentUuid: string | null;
  url: {
    input: string;
    output: string;
  };
}

/**
 * Create a Hangar API URL from a resource UUID and attachment UUID
 */
export function createHangarLatestItemsUrl(uuid: string) {
  const envCode = getCurrentServerEnvironmentCode();
  return `https://${envCode}.aviationaustralia.aero/items/${uuid}/0/`;
}

/**
 * Create a Hangar API URL from a resource UUID and attachment UUID
 */
export function createHangarApiUrl(
  uuid: string,
  version: string | number,
  attachmentUuid: string
) {
  const envCode = getCurrentServerEnvironmentCode();
  return `https://${envCode}.aviationaustralia.aero/integ/gen/${uuid}/${version}/?attachment.uuid=${attachmentUuid}`;
}

/**
 * Create a Hangar File URL from a resource UUID and attachment UUID
 */
export function createHangarFileUrl(
  uuid: string,
  version: string,
  filename: string
) {
  const envCode = getCurrentServerEnvironmentCode();
  return `https://${envCode}.aviationaustralia.aero/file/${uuid}/${version}/${filename}`;
}

/**
 * General classification
 * Checks the input URL to see if it is a hangar URL against any of the environments.
 */
export function quickClassifyHangarUrl(url: string) {
  const environments = ['prod', 'dev', 'preprod'];
  for (let index = 0; index < environments.length; index++) {
    const envCode = environments[index];
    if (url.startsWith(`https://${envCode}.aviationaustralia.aero/file/`))
      return 'file';
    else if (url.startsWith(`https://${envCode}.aviationaustralia.aero/items/`))
      return 'items';
    else if (
      url.startsWith(`https://${envCode}.aviationaustralia.aero/integ/gen/`)
    )
      return 'integ';
    else if (
      url.startsWith(`https://${envCode}.aviationaustralia.aero/api/item/`)
    )
      return 'api';
  }
  return null;
}

/**
 * Classify the type of absolute URl that is being passed in.
 */
function classifyAbsoluteHangarUrl(url: string): HangarUrlObject | null {
  const appEnv = getCurrentServerEnvironmentCode();
  const classification = quickClassifyHangarUrl(url);
  const urlArr = url.split('/');
  const envCode = urlArr?.[2]?.split('.')?.[0];
  switch (classification) {
    case 'file':
      /**
       * Accepted File format: https://<env>.aviationaustralia.aero/file/<uuid>/<version>/<filename>
       */
      const uuid1 = urlArr?.[4];
      const version1 = urlArr?.[5];
      const filename1 = urlArr?.[6];
      return {
        classification,
        environment: envCode,
        uuid: uuid1,
        version: version1,
        filename: filename1,
        attachmentUuid: null,
        url: {
          input: url,
          output: `https://${appEnv}.aviationaustralia.aero/file/${uuid1}/${version1}/${filename1}`
        }
      };
    case 'integ':
      /**
       * Accepted Integ format: https://<env>.aviationaustralia.aero/integ/gen/<uuid>/<version>/?attachment.uuid=<attachmentUuid>
       */
      const uuid2 = urlArr?.[5];
      const version2 = urlArr?.[6];
      const attachmentUuid = urlArr?.[7]?.split('=')?.[1];
      return {
        classification,
        environment: envCode,
        uuid: uuid2,
        version: version2,
        filename: null,
        attachmentUuid,
        url: {
          input: url,
          output: `https://${appEnv}.aviationaustralia.aero/integ/gen/${uuid2}/${version2}/?attachment.uuid=${attachmentUuid}`
        }
      };
    case 'items':
      /**
       * Accepted Items format: https://<env>.aviationaustralia.aero/items/<uuid>/<version>/
       */
      const uuid3 = urlArr?.[4];
      const version3 = urlArr?.[5];
      return {
        classification,
        environment: envCode,
        uuid: uuid3,
        version: version3,
        filename: null,
        attachmentUuid: null,
        url: {
          input: url,
          output: `https://${appEnv}.aviationaustralia.aero/items/${uuid3}/${version3}/`
        }
      };
    case 'api':
      /**
       * Accepted API format: https://<env>.aviationaustralia.aero/api/item/<uuid>/<version>/
       */
      const uuid4 = urlArr?.[5];
      const version4 = urlArr?.[6];
      return {
        classification,
        environment: envCode,
        uuid: uuid4,
        version: version4,
        filename: null,
        attachmentUuid: null,
        url: {
          input: url,
          output: `https://${appEnv}.aviationaustralia.aero/api/item/${uuid4}/${version4}/`
        }
      };
    default:
      /**
       * If the URL is not a hangar URL, return null.
       */
      return null;
  }
}

/**
 * Any time a URL is translated, a check should be made to ensure it's absolute path exists in
 * the correct environment.
 */
export function parseAbsoluteHangarUrl(url: string | undefined): string {
  if (!url) return '';
  const result = classifyAbsoluteHangarUrl(url);
  if (result) {
    const { url } = result;
    // if (url.input !== url.output) console.log(`URL translated: ${url.input} -> ${url.output}`);
    return url.output;
  } else return url;
}

/**
 * Convert a Hangar API URL to a Hangar Resource URL
 */
export function convertApiUrlToResourceUrl(url: string) {
  if (url.includes('/integ/gen/')) {
    const appEnv = getCurrentServerEnvironmentCode();
    const urlArr = url.split('/');
    const uuid = urlArr?.[5];
    return `https://${appEnv}.aviationaustralia.aero/items/${uuid}/0/`;
  } else return url;
}

/**
 * Get UUID from a HANGAR API Url (specifically)
 */
export function getUuidFromEquellaApiUrl(url: string): string | null {
  if (typeof url !== 'string') return null;
  if (url.includes('/items/')) {
    const urlArray = url.split('/');
    return urlArray[4];
  } else if (url.includes('/file/')) {
    const urlArray = url.split('/');
    return urlArray[4];
  } else if (url.includes('/integ/gen/')) {
    const urlArray = url.split('/');
    return urlArray[5];
  } else if (url.includes('/api/item/')) {
    const urlArray = url.split('/');
    return urlArray[5];
  }
  return null;
}

export function getVersionFromEquellaApiUrl(url: string): number | null {
  if (url.includes('/items/')) {
    const urlArray = url.split('/');
    return parseInt(urlArray[5]);
  } else if (url.includes('/file/')) {
    const urlArray = url.split('/');
    return parseInt(urlArray[5]);
  } else if (url.includes('/integ/gen/')) {
    const urlArray = url.split('/');
    return parseInt(urlArray[6]);
  } else if (url.includes('/api/item/')) {
    const urlArray = url.split('/');
    return parseInt(urlArray[6]);
  }
  return null;
}

export function getAttachmentUuidFromHangarApiUrl(url: string): string | null {
  if (url.includes('?attachment.uuid=')) {
    if (url.includes('/items/')) {
      const urlArray = url.split('/');
      return urlArray[6].split('=')[1];
    } else {
      const urlArray = url.split('/');
      return urlArray[7].split('=')[1];
    }
  } else return null;
}

interface HangarAPIUrlObject {
  uuid: string | null;
  version: number | null;
  attachmentUuid: string | null;
}

export function extractValuesFromHangarUrl(url: string): HangarAPIUrlObject {
  return {
    uuid: getUuidFromEquellaApiUrl(url),
    version: getVersionFromEquellaApiUrl(url),
    attachmentUuid: getAttachmentUuidFromHangarApiUrl(url)
  };
}

export function convertValuesToEquellaApiUrl(obj: HangarAPIUrlObject) {
  const baseUrl = getEnvironmentVariable('REACT_APP_HANGAR_BASE_URL');
  return `${baseUrl}/integ/gen/${obj.uuid}/${obj.version}/?attachment.uuid=${obj.attachmentUuid}`;
}
