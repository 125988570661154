import { MediaProps } from 'app/libraries/html2blocks/blocks/media.type';
import { DocumentHTMLBuildProcess } from '../../..';
import { getMediaTypeFromFormat } from 'app/libraries/html2blocks/blocks/media';
import { createHangarApiUrl } from 'app/service/utils/hangar-urls';
import { createStyledImageInnerHTML } from './media/createImageHTML';
import { createStyledIframeInnerHTML } from './media/createIframeHTML';
import { createRuffleUrl } from 'app/libraries/blocks2html/utils/createRuffleUrl';
import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';
import { inlineContentToPlainText } from 'app/libraries/blocks2html/utils/inlineContentToHtml';

/**
 * Media
 */
export function Media(
  id: string,
  content: BlockInlineContent[],
  props: MediaProps,
  position: string,
  process: DocumentHTMLBuildProcess
) {
  /**
   * Create HTML based on the type of media
   */
  let innerHtml = '';
  const mediaType = getMediaTypeFromFormat(props.format);
  const caption = inlineContentToPlainText(content);
  let mediaUrl = '';

  switch (mediaType) {
    case 'image':
      mediaUrl = createHangarApiUrl(
        props.itemUuid,
        props.itemVersion,
        props.attachmentUuid
      );
      innerHtml = createStyledImageInnerHTML(id, mediaUrl, caption, props);
      break;
    case 'iframe':
      mediaUrl = createHangarApiUrl(
        props.itemUuid,
        props.itemVersion,
        props.attachmentUuid
      );
      innerHtml = createStyledIframeInnerHTML(id, mediaUrl, caption, props);
      break;
    case 'ruffle':
      const swfUrl = createHangarApiUrl(
        props.itemUuid,
        props.itemVersion,
        props.attachmentUuid
      );
      mediaUrl = createRuffleUrl(swfUrl);
      innerHtml = createStyledIframeInnerHTML(id, mediaUrl, caption, props);
      break;
    case 'html5':
      innerHtml = `<p class="mt-2 mb-2 font-bold text-sm text-center text-gray-700 dark:text-gray-400">Styled render for media type ${mediaType} not built yet.</p>`;
      break;
    default:
      console.warn(
        `An unsupported media type "${mediaType}" has been ignored during the obj2html conversion.`
      );
      break;
  }

  const outerHtml = `
  <div 
    id="block-element-${id}" 
    class="mb-2"
    data-block
    data-type="media"
    data-element-pos="${position}"
    ${mediaType === 'ruffle' || mediaType === 'html5' ? 'data-online-only' : ''}
  >
    ${innerHtml}
  </div>
`;

  return outerHtml;
}

// switch (mediaType) {
//   case 'video':
//     embedType = 'video';
//     iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
//       <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5" />
//     </svg>
//     `;
//     break;
//   case '3dobj':
//     embedType = '3dobj';
//     iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
//       <path stroke-linecap="round" stroke-linejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
//     </svg>
//     `;

//     break;
//   case 'flash':
//     embedType = 'flash';
//     iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
//       <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
//     </svg>
//     `;

//     break;
//   case 'html5':
//     embedType = 'html5';
//     iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
//       <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
//     </svg>
//     `;

//     break;
//   default:
//     embedType = 'unknown';
//     iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
//       <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
//     </svg>
//     `;
//     console.error(`Unknown embed service: ${mediaType}`);
//     break;
// }
