/* eslint-disable prettier/prettier */
import { validateAttributionHTML } from 'app/libraries/blocks2html/utils/attribution';
import { MediaProps } from 'app/libraries/html2blocks/blocks/media.type';
// import { paragraphFontClasses } from '../@shared';

export function createStyledImageInnerHTML(
  id: string,
  url: string,
  caption: string,
  props: MediaProps
) {

  const mediaAttribution = validateAttributionHTML(props?.attribution);
  const attributionIsValid = mediaAttribution.trim() !== '' && mediaAttribution.toLowerCase().trim() !== 'unknown';

  return `
  <!-- Spotlight definition -->
  <a class="spotlight" data-title='${caption}' data-description="" href='${url}'>

    <!-- Card -->
    <div class="relative pt-1">

      <!-- Image -->
      <div>
        <img 
          class="m-auto dark:bg-white" 
          src='${url}'
          style='max-height: ${props.maxHeight}px;'
          alt='${caption}'></img>
      </div>

      ${attributionIsValid ? `
      <!-- Image attribution -->
      <div>
          <p data-media-attribution class="mt-2 text-xs text-right text-gray-300 dark:text-gray-700">${mediaAttribution}</p>
      </div>
      ` : ``}

      <!-- Image caption -->
      <div>
          <p ${attributionIsValid ? 'data-media-attribution-title' : ''} class="text-sm md:text-base text-gray-700 dark:text-gray-400 font-bold text-center mb-2">${caption}</p>
      </div>
    </div>
  </a>
`;
}
