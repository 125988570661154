import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';

/**
 * Helper function to handle link inline content
 */
function handleLinkInlineContent(
  item: BlockInlineContent,
  recursiveFn: Function
) {
  const linkContent = item.content;
  const href = item.href;
  if (linkContent) {
    const innerText = recursiveFn(linkContent);
    const text = href
      ? `<a href="${href}" target="_blank" style="color: revert; text-decoration: revert;">${innerText}</a>`
      : innerText;
    return text;
  } else {
    return '';
  }
}

/**
 * Converts inline content to HTML for data storage
 * https://stackoverflow.com/questions/271743/whats-the-difference-between-b-and-strong-i-and-em
 * @blocknote
 */
export function inlineContentToExplicitHtml(
  content: BlockInlineContent[]
): string {
  if (!content) return '';
  return content
    .map((item) => {
      if (item.type === 'link') {
        return handleLinkInlineContent(item, inlineContentToExplicitHtml);
      } else if (item.type === 'text' && item.text) {
        let { text, styles } = item;

        // Innermost
        if (styles?.italic) {
          text = `<i>${text}</i>`;
        }
        // Outermost
        if (styles?.bold) {
          text = `<b>${text}</b>`;
        }
        if (styles?.superscript) {
          text = `<sup>${text}</sup>`;
        }
        if (styles?.subscript) {
          text = `<sub>${text}</sub>`;
        }
        return text;
      } else {
        return '';
      }
    })
    .join('');
}

/**
 * Converts inline content to HTML for reading
 * https://stackoverflow.com/questions/271743/whats-the-difference-between-b-and-strong-i-and-em
 */
export function inlineContentToSemanticHtml(
  content: BlockInlineContent[]
): string {
  if (!content) return '';
  return content
    .map((item) => {
      if (item.type === 'link') {
        return handleLinkInlineContent(item, inlineContentToSemanticHtml);
      } else if (item.type === 'text' && item.text) {
        let { text, styles } = item;
        // Innermost
        if (styles?.italic) {
          text = `<em>${text}</em>`;
        }
        // Outermost
        if (styles?.bold) {
          text = `<strong>${text}</strong>`;
        }
        if (styles?.superscript) {
          text = `<sup>${text}</sup>`;
        }
        if (styles?.subscript) {
          text = `<sub>${text}</sub>`;
        }
        return text;
      } else {
        return '';
      }
    })
    .join('');
}

/**
 * Converts inline content to HTML but removes any styling
 */
export function inlineContentToPlainText(
  content: BlockInlineContent[]
): string {
  if (!content) return '';
  return content
    .map((item) => {
      if (item.type === 'link') {
        const linkContent = item.content;
        if (linkContent) {
          return inlineContentToPlainText(linkContent);
        }
      } else if (item.type === 'text') {
        const text = item.text;
        if (text) {
          return text;
        }
      }
    })
    .join('');
}
