/**
 * Settings
 */

// TODO: Show content not in accordions (alternate view)
// <div id="disable-accordion-toggle" class="items-center">
//     <label class="relative inline-flex items-center cursor-pointer">
//         <input type="checkbox" value="" class="sr-only peer">
//         <div
//             class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
//         </div>
//         <span class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">Show content in accordions</span>
//     </label>
// </div>

export function Settings() {
  return `
    <div id="drawer-right-settings"
        class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800"
        tabindex="-1" aria-labelledby="drawer-right-settings-label">
        <h5 id="drawer-right-label"
            class="inline-flex items-center mb-4 text-base font-semibold text-aa-blue dark:text-aa-blue"><svg
                class="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"></path>
            </svg>Page Options</h5>

        <!-- Close button -->
        <button type="button" aria-controls="drawer-right-settings" onclick="toggleDrawer(1);"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
            <span class="sr-only">Close menu</span>
        </button>

        <!-- Settings -->

        <hr class="mb-4">

        <a href="#">
            <button type="button" onclick="openCurrentPageInNewTab();"
                class="w-full mb-2 flex items-center p-3 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                </svg>
                Open this page in new tab
            </button>
        </a>

        <hr class="mt-4 mb-4">

        <h5 id="drawer-right-label"
            class="inline-flex items-center mb-4 text-base font-semibold text-aa-blue dark:aa-blue"><svg
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-6 h-6 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>Page Settings</h5>

        <hr class="mb-4">

        <p class="mb-4 text-xs text-gray-500 dark:text-gray-400">Please note that the following settings will persist
            between web pages on <strong>this device only.</strong></p>

        <p class="mt-2 mb-2 text-xs text-gray-500 dark:text-gray-400 font-semibold">Content</p>

        <div id="drawer-right-settings-content-settings">

          <div>
            <label data-content-lo-toggle="" id="content-lo-toggle" class="inline-flex items-center cursor-pointer">
              <input id="content-lo-toggler" type="checkbox" value="" class="sr-only peer" checked
                onclick="toggleContentLOs();" />
              <div
                class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all after:ml-[2px] after:mt-[1.5px] dark:border-gray-600 peer-checked:bg-blue-600">
              </div>
              <span class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">Show learning objectives</span>
            </label>
          </div>

          <div>
            <label data-content-tp-toggle="" id="content-tp-toggle" class="inline-flex items-center cursor-pointer">
              <input id="content-tp-toggler" type="checkbox" value="" class="sr-only peer" checked
                onclick="toggleContentTPs();" />
              <div
                class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all after:ml-[2px] after:mt-[1.5px] dark:border-gray-600 peer-checked:bg-blue-600">
              </div>
              <span class="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">Show teaching points</span>
            </label>
          </div>

        </div>

        <div id="drawer-right-settings-content-settings">

            <!-- Theme Toggle -->
            <div data-theme-toggle id="theme-toggle" class="mb-8">
                <p class="mt-2 mb-2 text-xs text-gray-500 dark:text-gray-400 font-semibold">Theme</p>
                <div class="flex">
                    <div class="flex items-center mr-4" onclick="setLightMode();">
                        <input id="theme-radio-1" type="radio" value="" name="inline-radio-group"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="theme-radio-1"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Light</label>
                    </div>
                    <div class="flex items-center mr-4" onclick="setDarkMode();">
                        <input id="theme-radio-2" type="radio" value="" name="inline-radio-group"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="theme-radio-2"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Dark</label>
                    </div>
                    <div class="flex items-center mr-4" onclick="setDefaultMode();">
                        <input id="theme-radio-3" type="radio" value="" name="inline-radio-group"
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="theme-radio-3"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">System</label>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
    `;
}
