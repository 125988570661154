import { DeleteOutlined } from '@ant-design/icons';
import { Button, ButtonProps, useTheme } from '@mui/material';

export const RemoveButton = (props: ButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      {...props}
      sx={{
        color: theme.palette.error.main,
        ':hover': {
          backgroundColor: theme.palette.error.lighter,
          color: theme.palette.error.dark
        }
      }}
    >
      <DeleteOutlined />
    </Button>
  );
};
