import { htmlBeautify } from 'app/libraries/blocks2html/utils/htmlBeautify';
import { getMediaTypeFromFormat } from 'app/libraries/html2blocks/blocks/media';
import { MediaResourceType } from 'app/libraries/html2blocks/blocks/media.type';
import {
  Element,
  MediaElement,
  SlideElement,
  SlideObjectData,
  SlideProps
} from 'app/libraries/html2blocks/blocks/slide.types';

/**
 * @TODO Write tests for this file
 */

/**
 * HTML Slide information
 */
export function Slide(
  id: string,
  props: SlideProps,
  title: string,
  position: string
) {
  const slideFormat = props.format;

  const slideObjectData: SlideObjectData = JSON.parse(props.slideJSON);
  const slideElements = slideObjectData.slide;
  const slideNotes = slideObjectData.notes;
  const slideContainsMedia = slideObjectData.containsMedia;
  const slideShowTitle = slideObjectData.showTitle ?? true;

  const slideElementsHTML = createSlideHTML(slideElements);
  const slideNotesHTML = `<div data-slide-notes>${slideNotes}</div>`;

  return `
  <div 
    id="block-element-${id}" 
    data-block data-type="slide"
    data-slide-title="${title}"
    data-slide-format="${slideFormat}" 
    data-element-pos="${position}"
    ${slideContainsMedia ? 'data-slide-contains-media=true' : ''}
    ${
      slideShowTitle
        ? 'data-slide-show-title=true'
        : 'data-slide-show-title=false'
    }
  >
    ${slideElementsHTML}${slideNotesHTML}
  </div>`;
}

/**
 * Creae the slide HTML based on the format select and slide elements.
 */
function createSlideHTML(elements: SlideElement[]) {
  let innerHTML = '';
  for (let index = 0; index < elements.length; index++) {
    const slideElement: SlideElement = elements[index];
    switch (slideElement.type) {
      case 'text':
        const textSlideElement = slideElement as Element;
        innerHTML =
          innerHTML +
          `<div
            data-slide-element
            data-slide-element-type="${textSlideElement.type.trim()}"
            ${textSlideElement.fragment ? 'data-slide-element-fragment' : ''}
            data-slide-element-animation="${textSlideElement.animation}">
            ${textSlideElement.html}
          </div>`;
        break;
      case 'media':
        const mediaSlideElement = slideElement as MediaElement;
        const mediaDisplayType = getMediaTypeFromFormat(
          mediaSlideElement.media as MediaResourceType
        );
        /**
         * @TODO handle other media types
         */
        if (mediaDisplayType === 'image') {
          innerHTML =
            innerHTML +
            `<div data-slide-element
              data-slide-element-type="${mediaSlideElement.type.trim()}" 
              ${mediaSlideElement.fragment ? 'data-slide-element-fragment' : ''}
              data-slide-element-animation="${mediaSlideElement.animation}"
              data-slide-element-media="${mediaSlideElement.media.trim()}"
              data-slide-element-url="${mediaSlideElement.url}"
              data-slide-element-caption="${mediaSlideElement.caption}"
              ></div>`;
        } else if (mediaDisplayType === 'iframe') {
          innerHTML =
            innerHTML +
            `<div data-slide-element
              data-slide-element-type="${mediaSlideElement.type.trim()}" 
              ${mediaSlideElement.fragment ? 'data-slide-element-fragment' : ''}
              data-slide-element-animation="${mediaSlideElement.animation}"
              data-slide-element-media="${mediaSlideElement.media}"
              data-slide-element-url="${mediaSlideElement.url}"
              data-slide-element-caption="${mediaSlideElement.caption}"
              ></div>`;
        }
        break;
      default:
        console.error(
          `obj2html slide element format not implemented: ${slideElement.type}`
        );
        break;
    }
  }
  return htmlBeautify(innerHTML);
}
