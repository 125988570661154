import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Typography,
  Grid,
  Button,
  TextField,
  useTheme,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import MainCard from 'components/app/MainCard';
import React, { useState } from 'react';
import { ListItem } from '../../../app/types/service/course-manager/common.types';
import { RemoveButton } from 'components/app/RemoveButton';

export const ListEditor = (props: {
  title: string;
  listItemName: string;
  listSelectChoices?: ListItem[];
  itemRegexValidation?: RegExp;
  includesPrefix: boolean;
  prefixName?: string;
  prefixRegexValidation?: RegExp;
  list: ListItem[];
  setList: React.Dispatch<React.SetStateAction<ListItem[]>>;
}) => {
  const theme = useTheme();

  const [newListItem, setNewListItem] = useState<ListItem>({
    prefixStringValue: props.includesPrefix ? '' : undefined,
    stringValue: ''
  });
  const [newListItemValid, setNewListItemValid] = useState<
    boolean | undefined
  >();
  const [prefixValid, setPrefixValid] = useState<boolean | undefined>();

  const handleItemOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    if (props.itemRegexValidation) {
      const reg = new RegExp(props.itemRegexValidation);
      const valid = reg.test(e.target.value as string);
      setNewListItemValid(valid);
    }
    setNewListItem({
      ...newListItem,
      stringValue: e.target.value as string
    });
  };

  const handlePrefixOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (props.prefixRegexValidation) {
      const reg = new RegExp(props.prefixRegexValidation);
      const valid = reg.test(e.target.value as string);
      setPrefixValid(valid);
    }
    setNewListItem({
      ...newListItem,
      prefixStringValue: e.target.value
    });
  };

  // const [newListItems, setNewListItems] = useState<ListItem[]>([]);
  const [list, setList] = useState<ListItem[]>([...props.list]);

  const addListItem = (listItem: ListItem) => {
    if (listItem.stringValue === '') return;
    if (props.includesPrefix && listItem.prefixStringValue === '') return;
    let listItemToAdd = { ...listItem };
    if (!props.includesPrefix && props.listSelectChoices) {
      listItemToAdd =
        props.listSelectChoices.find(
          (c) => c.stringValue === listItemToAdd.stringValue
        ) ?? listItemToAdd;
    }
    const newList = [...list, listItemToAdd];
    setList(newList);
    setNewListItem({
      prefixStringValue: props.includesPrefix ? '' : undefined,
      stringValue: ''
    });
  };

  const moveListItemUp = (index: number) => {
    if (index === 0) return;
    const listItem = list[index];
    const newList = [
      ...list.slice(0, index - 1),
      listItem,
      list[index - 1],
      ...list.splice(index + 1)
    ];
    setList(newList);
  };

  const moveListItemDown = (index: number) => {
    if (index === list.length - 1) return;
    const listItem = list[index];
    const newList = [
      ...list.slice(0, index),
      list[index + 1],
      listItem,
      ...list.splice(index + 2)
    ];
    setList(newList);
  };

  const removeListItem = (index: number) => {
    const newList = [...list.slice(0, index), ...list.splice(index + 1)];
    setList(newList);
  };

  const save = () => {
    props.setList(list);
  };

  return (
    <div>
      <MainCard sx={{ mt: '0.5em', borderColor: theme.palette.warning.main }}>
        <Typography variant="h5">{props.title}</Typography>
        <Stack sx={{ my: '1em' }}>
          {list.map((listItem, index) => {
            return (
              <Box
                key={`list-item-${index}`}
                sx={{
                  ':hover': {
                    backgroundColor: theme.palette.background.default
                  }
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body1">
                      {listItem.prefixStringValue
                        ? `${listItem.prefixStringValue} `
                        : ''}
                      {listItem.stringValue}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      disabled={index === list.length - 1}
                      onClick={() => moveListItemDown(index)}
                    >
                      <DownOutlined />
                    </Button>
                  </Grid>

                  <Grid item xs="auto">
                    <Button
                      disabled={index === 0}
                      onClick={() => moveListItemUp(index)}
                    >
                      <UpOutlined />
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <RemoveButton
                      fullWidth
                      onClick={() => removeListItem(index)}
                    >
                      Remove
                    </RemoveButton>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Stack>

        <Grid container alignItems="center">
          {props.includesPrefix && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                value={newListItem.prefixStringValue}
                onChange={(e) => handlePrefixOnChange(e)}
                label={`${props.prefixName}`}
                error={prefixValid === false}
              />
            </Grid>
          )}
          <Grid item xs={props.includesPrefix ? 8 : 10}>
            {props.listSelectChoices ? (
              <Select
                fullWidth
                value={newListItem.stringValue}
                onChange={(e) => handleItemOnChange(e)}
                label={`New ${props.listItemName}`}
                error={newListItemValid === false}
                input={<OutlinedInput label="" />}
              >
                {props.listSelectChoices
                  .filter(
                    (choice) =>
                      !list.find(
                        (item) => item.stringValue === choice.stringValue
                      )
                  )
                  .map((choice, choiceIndex) => (
                    <MenuItem
                      key={`list-select-choice-${choiceIndex}`}
                      value={choice.stringValue}
                    >
                      <ListItemText>
                        {choice.prefixStringValue
                          ? `${choice.prefixStringValue} `
                          : ''}
                        {choice.stringValue}
                      </ListItemText>
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <TextField
                fullWidth
                value={newListItem.stringValue}
                onChange={(e) => handleItemOnChange(e)}
                label={`New ${props.listItemName}`}
                error={newListItemValid === false}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={
                newListItem.stringValue === '' ||
                (props.includesPrefix &&
                  newListItem.prefixStringValue === '') ||
                newListItemValid === false ||
                prefixValid === false
              }
              fullWidth
              onClick={() => addListItem(newListItem)}
            >
              Add {props.listItemName}
            </Button>
          </Grid>
        </Grid>

        <Button
          disabled={newListItem.stringValue !== '' || list.length === 0}
          sx={{ mt: '1em' }}
          fullWidth
          onClick={save}
        >
          Done
        </Button>
      </MainCard>
    </div>
  );
};
