import { ParagraphProps } from 'app/libraries/html2blocks/blocks/paragraph.types';
import { paragraphFontClasses } from './@shared';

/**
 * HTML Paragraph
 */
export function Paragraph(
  id: string,
  text: string,
  props: ParagraphProps,
  position: string
) {
  /**
   * NOTE: data-paragraph-text not used - use innerHTML instead.
   */
  return `
    <p 
      id="block-element-${id}" 
      class="${paragraphFontClasses} mt-2 mb-2" 
      data-block 
      data-type="paragraph"
      data-element-pos="${position}" 
    >${text}</p>`;
}
