// import { ContentObj2HtmlCreationObject } from 'app/libraries/@types/content.obj2html.types';
import { ContentEditorObject } from 'app/types/service/content.types';
import { htmlBeautify } from '../utils/htmlBeautify';
import { convertBlocksToRawHTML } from './blocks/blocks2RawHtml';
// import { DocumentEditorObject } from 'app/@types/master.types';

export type ContentHTMLCreationProcess = 'store' | 'view';

/**
 * Create the HTML Content
 */
export default function content(
  content: ContentEditorObject,
  process?: ContentHTMLCreationProcess
): string {
  /**
   * Initial default process
   */
  const creationProcess = process || 'store';

  /**
   * Extract blocks and learning objectives
   */
  const contentEditorObject = content.editor;
  const blocks = contentEditorObject?.blocks!;
  const contentHtml = convertBlocksToRawHTML(blocks, creationProcess);

  // console.log('block2html output:', contentHtml);

  return htmlBeautify(`
    <div
      id="content-${content.resource.uuid}"
      data-meta-oeq="new"
      data-version="1.0.0"
      data-content-uuid="${content.resource.uuid}"
      data-content-version="${content.resource.version}">
        <h2
          id="content-${content.resource.uuid}-heading"
        >${content.content.heading}</h2>
        <div id="container">
          ${contentHtml}
        </div>
    </div>
  `);
}
