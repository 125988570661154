/* eslint-disable @typescript-eslint/no-unused-vars */
import { el } from 'date-fns/locale';
import { BlockInlineContent, DefaultProps } from '../../blocks/common.types';
import { ErrorBlock } from '../../blocks/error.types';
import {
  createBulletListBlock,
  createNumberedListBlock
} from '../../blocks/lists';
import { ListBlock, ListProps } from '../../blocks/lists.types';
import { extractBlockIdFromElementId } from '../../utils/blockIdFromElementId';
import { htmlToInlineContent } from '../../utils/htmlToInlineContent';
import { validateOrCreateUuidV4 } from '../../utils/uuid';
import { createErrorBlock } from '../../blocks/error';

type ListType = 'bulletListItem' | 'numberedListItem';

function recursivelyCreateListBlocks(
  element: HTMLElement,
  listType: ListType,
  blockId?: string
): any {
  let result: ListBlock | null = null;

  let elContent: BlockInlineContent[] = [];
  let elChildren = [];
  let id = validateOrCreateUuidV4(blockId);

  const tag = listType === 'bulletListItem' ? 'UL' : 'OL';

  const blockPropsJSONEncoded = element.dataset.props;
  if (!blockPropsJSONEncoded) {
    return createErrorBlock(
      `Failed to create List with ID "${blockId}", missing props.`,
      '',
      blockId
    );
  }
  const blockPropsJSON = decodeURI(blockPropsJSONEncoded);

  let blockProps: ListProps;
  try {
    blockProps = JSON.parse(blockPropsJSON);
  } catch (e) {
    return createErrorBlock(
      `Failed to parse List block props with ID "${blockId}", ${e}`,
      '',
      blockId
    );
  }

  // Check if the element is an <li> element and has direct text content
  if (element.tagName === 'LI') {
    /**
     * Check for inline content, ignoring nested lists
     */
    let inlineContent = '';
    for (let i = 0; i < element.childNodes.length; i++) {
      const elNode = element.childNodes.item(i) as HTMLElement;
      if (elNode.tagName === 'UL' || elNode.tagName === 'OL') continue;
      // text
      if (elNode.nodeValue) {
        inlineContent += elNode.nodeValue.trim();
      }
      // tagged
      if (elNode.outerHTML) {
        inlineContent += elNode.outerHTML.trim();
      }
    }
    elContent = htmlToInlineContent(inlineContent);

    // check for <ul> <ol> elements from child nodes
    let listElements: (HTMLOListElement | HTMLUListElement)[] = [];
    element.childNodes.forEach((node) => {
      let element = node as HTMLElement;
      if (element.tagName === 'OL' || element.tagName === 'UL')
        listElements.push(element as HTMLOListElement | HTMLUListElement);
    });

    elChildren = [];
    // Iterate over each <ul>/<ol> and their <li> children
    for (let child of listElements) {
      for (let listItem of child.children) {
        const itemEl = listItem as HTMLElement;
        const type = itemEl.dataset.type as ListType;
        // Recursively process each child <li>
        const blockId = extractBlockIdFromElementId(itemEl?.id);
        elChildren.push(recursivelyCreateListBlocks(itemEl, type, blockId));
      }
    }
  }

  if (tag === 'UL') {
    result = createBulletListBlock(elContent, blockProps, elChildren, id);
  } else if (tag === 'OL') {
    result = createNumberedListBlock(elContent, blockProps, elChildren, id);
  }

  return result;
}

/**
 * Returns a list of blocks
 */
export function listElementToBlocks(
  element: HTMLElement
): ListBlock[] | ErrorBlock[] {
  const lists: ListBlock[] = [];
  const listItems = element.children;
  for (let item of listItems) {
    const itemEl = item as HTMLElement;
    const blockId = extractBlockIdFromElementId(itemEl?.id);
    const type = itemEl.dataset.type as ListType;
    const block = recursivelyCreateListBlocks(itemEl, type, blockId);
    lists.push(block);
  }

  return lists;
}
