import { axiosRequestJSON } from 'app/axios/axiosRequests';
import { getTaxonomiesEndpoint } from '../endpoints/taxonomies';
import { getHangarApiHeaders } from '../headers/headers';
import { getEnvironmentVariable } from 'app/service/utils/environment';
import { RegulatorType } from 'app/types/service/courses.types';

/**
 * ============================================================>>
 * Helper Functions
 * ============================================================>>
 */
export function getTaxonomyUuidByCode(code: RegulatorType) {
  switch (code) {
    case 'NAA':
      return getEnvironmentVariable(
        'REACT_APP_HANGAR_REGULATORY_COURSE_CONTENT_TAXONOMY_UUID'
      );
    case 'ASQA':
      return getEnvironmentVariable(
        'REACT_APP_HANGAR_COMPETENCY_COURSE_CONTENT_TAXONOMY_UUID'
      );
    case 'AA':
      return getEnvironmentVariable(
        'REACT_APP_HANGAR_SHORT_COURSE_CONTENT_TAXONOMY_UUID'
      );
    default:
      return null;
  }
}

/**
 * Get Taxonomy List
 */
export const getTaxonomyList = async (accessToken: string) => {
  const baseUrl = getTaxonomiesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
};

/**
 * Get Taxonomy Tree
 */
export const getTaxonomyTree = async (
  taxonomyUuid: string,
  taxonomyPath: string,
  depth: number,
  accessToken: string
) => {
  const baseUrl = getTaxonomiesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${taxonomyUuid}/tree?path=${taxonomyPath}&depth=${depth}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
};

/**
 * ============================================================>>
 * GET Taxonomy Terms
 * ============================================================>>
 */
export async function getTaxonomyTerms(
  uuid: string,
  path: string,
  accessToken: string
) {
  const baseUrl = getTaxonomiesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const url = `${baseUrl}/${uuid}/terms?path=${path}`;
  const res = await axiosRequestJSON('GET', url, headers, null);
  return res;
}

/**
 * ============================================================>>
 * POST Taxonomy Terms
 * ============================================================>>
 */
export async function postTaxonomyTerms(
  taxonomyUuid: string,
  terms: string[],
  accessToken: string
) {
  const baseUrl = getTaxonomiesEndpoint();
  const headers = getHangarApiHeaders(accessToken);
  const data = {
    terms
  };
  const url = `${baseUrl}/${taxonomyUuid}/terms`;
  const res = await axiosRequestJSON('POST', url, headers, data);
  return res;
}

/**
 * Hangar Taxonomies API functions
 */

// /**
//  * GET Top Level Taxonomies
//  */
// async function getTaxonomies(accessToken: string) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}&code=${serverToken}`;
//   const res = await axiosRequestJSON('GET', url, headers, null);
//   const htmlData = res.data;
//   return htmlData;
// }

// /**
//  * GET Taxonomy by UUID
//  */
// async function getTaxonomy(accessToken: string, uuid: string) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}?uuid=${uuid}&code=${serverToken}`;
//   const res = await axiosRequestJSON('GET', url, headers, null);
//   const htmlData = res.data;
//   return htmlData;
// }

// /**
//  * GET
//  * Taxonomy Terms
//  * @param {string} uuid
//  * @param {string} path
//  * @param {string} accessToken
//  * @return {*}
//  */
// async function getTaxonomyTermsWithData(uuid, path, accessToken) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/terms?uuid=${uuid}&path=${path}&includeData=true&code=${serverToken}`;
//   const res = await axiosRequestJSON('GET', url, headers, null);
//   const htmlData = res.data;
//   return htmlData;
// }

// /**
//  * GET
//  * Taxonomy Term by UUIDs (taxonomy UUID and term UUID)
//  * @param {string} accessToken
//  * @param {string} uuid
//  * @param {string} taxonomyUuid
//  * @return {*}
//  */
// async function getTaxonomyTerm(accessToken, uuid, taxonomyUuid) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/terms?uuid=${uuid}&taxonomyUuid=${taxonomyUuid}&includeData=true&code=${serverToken}`;
//   const res = await axiosRequestJSON('GET', url, headers, null);
//   const htmlData = res.data;
//   return htmlData;
// }

// /**
//  * GET
//  * Taxonomy Term by UUIDs (taxonomy UUID and term UUID)
//  * @param {string} accessToken
//  * @param {string} uuid
//  * @param {string} taxonomyUuid
//  * @return {*}
//  */
// async function getTaxonomyTermData(accessToken, uuid, taxonomyUuid) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/data?uuid=${uuid}&taxonomyUuid=${taxonomyUuid}&code=${serverToken}`;
//   const res = await axiosRequestJSON('GET', url, headers, null);
//   const htmlData = res.data;
//   return htmlData;
// }

// /**
//  * POST
//  * Create a Taxonomy Term in the Aviation Australia Taxonomy
//  * @param {string} accessToken
//  * @param {string} term
//  * @param {string} termParentUuid
//  * @return {*}
//  */
// async function postTaxonomyTerm(accessToken, term, termParentUuid) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/term?code=${serverToken}`;
//   const body = {
//     term,
//     parentUuid: termParentUuid
//   };
//   const res = await axiosRequestJSON('POST', url, headers, body);

//   /**
//    * This API request returns no data
//    */
//   if (!res?.status || res?.status !== 200) return res;
//   else return generateResponseObject(200, res.data, null);
// }

// /**
//  * POST multiple taxonomy terms to the taxonomy tree
//  * @param {object} terms the terms object list
//  * termObject: {
//  *     term*: "string",
//  *     parentUuid: "string"
//  *     data: {}
//  * }
//  * @param {*} accessToken
//  */
// export async function postTaxonomyTerms(taxonomyUuid: string, terms: string[], accessToken: string) {
//   const baseUrl = getTaxonomiesEndpoint();
//   const serverToken = getTaxonomiesServerToken();
//   const headers = getHangarApiHeaders(accessToken);
//   const url = `${baseUrl}/term?code=${serverToken}`;
//   const apiCalls = terms.map((e) => {
//     return {
//       method: 'POST',
//       url,
//       headers,
//       body: e,
//       timeout: 3000
//     };
//   });
//   const res = await axiosBatchRequestsJSONThrottled(apiCalls);
//   const responseObjs = res.map((e) => {
//     if (!e?.status || e?.status !== 200) return e;
//     else return generateResponseObject(200, e.data, null);
//   });
//   return responseObjs;
// }
