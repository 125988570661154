import { BlockOutlined, BuildOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Button, Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

const CourseManager = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        onClick={() => navigate('/course-manager/builder')}
        sx={{ width: '100%', my: '30px' }}
      >
        <Stack>
          <Typography variant="h1">
            <BuildOutlined />
          </Typography>
          <Typography variant="button">Course Builder</Typography>
        </Stack>
      </Button>
      <Button sx={{ width: '100%', mt: '30px' }}>
        <Stack>
          <Typography variant="h1">
            <BlockOutlined />
          </Typography>
          <Typography variant="button">Course Editor</Typography>
        </Stack>
      </Button>
    </Container>
  );
};

export default CourseManager;
