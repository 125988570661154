import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/@mantis/Loadable';
import AuthGuard from 'routes/guard/AuthGuard';
import AuthRouter from 'pages/auth/AuthRouter';
import EditorLayout from 'layout/EditorLayout';
import CourseManager from 'pages/course-manager';
import { CourseBuilder } from 'pages/course-manager/builder';

// Pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard/dashboard')));

// Courseware
const CoursesPage = Loadable(lazy(() => import('pages/courses')));
const CoursePage = Loadable(lazy(() => import('pages/course')));
const CourseAutopilotPage = Loadable(
  lazy(() => import('pages/course/autopilot'))
);
const DocumentPage = Loadable(lazy(() => import('pages/document')));
const EditorPage = Loadable(lazy(() => import('pages/editor')));
const LearningObjectivesPage = Loadable(
  lazy(() => import('pages/learning-objectives'))
);
const WorkflowsPage = Loadable(lazy(() => import('pages/workflows')));
const AutopilotAdminPage = Loadable(lazy(() => import('pages/autopilot')));

// Maintenance
const Error404 = Loadable(lazy(() => import('pages/_maintenance/404')));
const Error500 = Loadable(lazy(() => import('pages/_maintenance/500')));

// ==============================|| MANAGER ROUTING ||============================== //

const MainRoutes = {
  /**
   * @app
   * This is the main application with full navigation and mobile responsive capabilities.
   * It is designed to be used in the browser (not embedded in iframes).
   */
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <>
            <AuthRouter />
            <MainLayout />
          </>
        </AuthGuard>
      ),
      children: [
        {
          path: 'login',
          element: <></>
        },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        // Courseware
        {
          path: 'courses',
          element: <CoursesPage />
        },
        {
          path: 'course/:id',
          element: <CoursePage />
        },
        {
          path: 'course/:id/autopilot',
          element: <CourseAutopilotPage />
        },
        {
          path: 'document/:documentId',
          element: <DocumentPage />
        },
        {
          path: 'workflows',
          element: <WorkflowsPage />
        },
        {
          path: 'course-manager',
          element: <CourseManager />
        },
        {
          path: 'course-manager/builder',
          element: <CourseBuilder />
        },
        {
          path: 'learning-objectives',
          element: <LearningObjectivesPage />
        },
        {
          path: 'autopilot',
          element: <AutopilotAdminPage />
        }
      ]
    },
    {
      path: '/editor',
      element: (
        <AuthGuard>
          <EditorLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <EditorPage />
        },
        {
          path: ':documentId',
          element: <EditorPage />
        }
      ]
    },
    /**
     * @Error404
     * If any other pages are navigated to show a 404 (not found).
     */
    {
      path: '*',
      element: <Error404 />
    },
    /**
     * @Error500
     * If a server error / disconnection occurs, navigate to the server error page.
     */
    {
      path: 'error',
      element: <Error500 />
    }
  ]
};

export default MainRoutes;
