/**
 * ==========================================================================================
 * Page Breadcrumb
 * Shows the page hierarchy: Course > Topic > Heading
 * Does not show when the page renders in an iframe
 * ==========================================================================================
 */

function BreadcrumbListItem(text: string, index: number) {
  if (index === 0) {
    return `
      <li class="inline-flex items-center">
          <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">${text}</span>
      </li>
    `;
  } else {
    return `
      <li>
        <div class="flex items-center">
          <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
          </svg>
          <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">${text}</span>
        </div>
      </li>
    `;
  }
}

export function Breadcrumb(taxonomyLocation: string) {
  const taxonomySplitArr = taxonomyLocation.split('\\');
  const breadcrumbItems = taxonomySplitArr.map((item, index) => {
    return BreadcrumbListItem(item, index);
  });
  const breadcrumbItemsHTML = breadcrumbItems.join('');

  return `
    <nav data-breadcrumb id="page-breadcrumb" class="flex px-5 py-3 text-gray-700 border border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">${breadcrumbItemsHTML}</ol>
    </nav>
    `;
}
