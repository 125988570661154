import { getEnvironmentVariable } from 'app/service/utils/environment';
import { HtmlConfigObject } from '../types/htmlConfig.types';

/**
 * Get the build HTML config
 * This is used for the autopilot build process
 */
export function getBuildHTMLConfig(): HtmlConfigObject {
  const baseUrl = getEnvironmentVariable('REACT_APP_HANGAR_BASE_URL');
  const uuid = getEnvironmentVariable('REACT_APP_FWA_HTML_ASSETS_UUID');
  const version = getEnvironmentVariable('REACT_APP_FWA_HTML_ASSETS_VERSION');
  const directory = `${baseUrl}/integ/gen/${uuid}/${version}`;
  return {
    env: 'build',
    favicon: `${directory}/favicon.ico`,
    logo: `${directory}/logo.svg`,
    fonts: {
      google: getEnvironmentVariable('REACT_APP_EXT_FONTS_GOOGLE')
    },
    styles: {
      main: `${directory}/style.css`, // Auto-generated by tailwind CSS
      spotlight: `${directory}/spotlight.min.css`,
      overrides: `${directory}/overrides.css`
    },
    scripts: {
      spotlight: `${directory}/spotlight.min.js`,
      flowbite: `${directory}/flowbite.min.js`,
      mathjax: getEnvironmentVariable('REACT_APP_EXT_SCRIPTS_MATHJAX'),
      main: `${directory}/scripts.js`
    }
  };
}
