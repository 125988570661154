import MainCard from 'components/app/MainCard';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  // Container,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import {
  CourseLearningObjective,
  CourseTopic
} from 'app/types/service/course-manager/course.types';
import { LearningObjectivesResponse } from 'app/types/integration/learningobjectives.api.types';
import { searchLearningObjectives } from 'app/integration/server/library/learningobjectives';
import useHangarAuth from 'hooks/useHangarAuth';
import { PaginatedDataGrid } from 'components/app/PaginatedDataGrid';
import { isUniqueLearningObjective } from 'app/service/utils/learningObjectives';
import { LearningObjectiveChip } from 'pages/course-manager/components/LearningObjectiveChip';
import { RemoveButton } from 'components/app/RemoveButton';
import { TopicLearningObjectives } from 'pages/course-manager/components/TopicLearningObjectivesEditor';
import { useEffect, useState } from 'react';
// import { ListItem } from 'pages/course-manager/common.types';
// import { useState } from 'react';

export const ApplyLearningObjectives = (props: {
  courseCode: string;
  courseTitle: string;
  courseTopics: CourseTopic[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setPreventNext: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { user } = useHangarAuth();
  const accessToken: string = user?.accessToken;
  const theme = useTheme();

  const [searchLearningObjectiveValue, setSearchLearningObjectiveValue] =
    useState<string>('');

  const [learningObjectivesSearchResults, setLearningObjectivesSearchResults] =
    useState<CourseLearningObjective[]>([]);

  const [fetching, setFetching] = useState(false);
  const fetchLearningObjectives = async (
    taxonomyContains: string | undefined,
    topicStartsWith: string | undefined
  ) => {
    setFetching(true);
    const searchLearningObjectivesResponse = await searchLearningObjectives(
      taxonomyContains,
      topicStartsWith,
      false,
      true,
      accessToken
    );
    if (searchLearningObjectivesResponse.status !== 200) {
      setFetching(false);
      return;
    }
    const learningObjectivesResponse: LearningObjectivesResponse =
      searchLearningObjectivesResponse.data;

    const uniqueLearningObjectives = learningObjectivesResponse.unique;
    if (uniqueLearningObjectives) {
      const uloListItems: CourseLearningObjective[] =
        uniqueLearningObjectives.map((ulo): CourseLearningObjective => {
          return { ulo: ulo, stringValue: ulo.LearningObjective };
        });
      setLearningObjectivesSearchResults(uloListItems);
    }
    setFetching(false);
  };

  const [
    selectedLearningObjectiveSearchResults,
    setSelectedLearningObjectiveSearchResults
  ] = useState<({ id: string } & CourseLearningObjective)[]>([]);

  const [createLearningObjectiveValue, setCreateLearningObjectiveValue] =
    useState<string>('');

  const [addedLearningObjectives, setAddedLearningObjectives] = useState<
    CourseLearningObjective[]
  >(
    props.courseTopics
      .map((t) =>
        t.documents
          ? t.documents
            .map((d) =>
              d.learningObjectives
                ? d.learningObjectives.map((lo): CourseLearningObjective => {
                  return { stringValue: lo.stringValue, ulo: lo.ulo };
                })
                : []
            )
            .reduce((prev, curr) => [...prev, ...curr], [])
          : []
      )
      .reduce((prev, curr) => [...prev, ...curr])
      .filter(
        //remove duplicates
        (lo1, index, arr) =>
          arr.findIndex((lo2) => lo2.stringValue === lo1.stringValue) === index
      )
  );
  const addLearningObjectives = (
    learningObjectives: CourseLearningObjective[]
  ) => {
    const newAddedLearningObjectives = [
      ...addedLearningObjectives,
      ...learningObjectives.filter(
        (lo) =>
          !addedLearningObjectives
            .map((addedLo) => addedLo.stringValue)
            .includes(lo.stringValue)
      )
    ];
    setAddedLearningObjectives(newAddedLearningObjectives);
    setSelectedLearningObjectiveSearchResults([]);
  };
  const removeLearningObjective = (addedLearningObjectiveIndex: number) => {
    const newAddedLearningObjectives = [
      ...addedLearningObjectives.slice(0, addedLearningObjectiveIndex),
      ...addedLearningObjectives.splice(addedLearningObjectiveIndex + 1)
    ];
    setAddedLearningObjectives(newAddedLearningObjectives);
  };

  /**
   * Prevent continue if topic or document has no learning objectives
   */
  useEffect(() => {
    for (let i = 0; i < props.courseTopics.length; i++) {
      const topic = props.courseTopics[i];
      if (!topic.learningObjectives || topic.learningObjectives.length === 0) {
        props.setPreventNext(true);
        return;
      }
      const documents = topic.documents;
      if (!documents) continue;
      for (let j = 0; j < documents.length; j++) {
        const document = documents[j];
        if (
          !document.learningObjectives ||
          document.learningObjectives.length === 0
        ) {
          props.setPreventNext(true);
          return;
        }
      }
      props.setPreventNext(false);
    }
  }, [props.courseTopics]);

  return (
    <div>
      <MainCard sx={{ my: '1em' }}>
        <Typography sx={{ my: '0.5em' }} variant="h4">
          1) Add Learning Objectives
        </Typography>
        <Divider />
        <Container sx={{ my: '1em' }}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Search Existing
          </Typography>
          <Grid container sx={{ my: '1em', alignItems: 'center' }}>
            <Grid item xs={11}>
              <TextField
                label={'Taxonomy contains'}
                fullWidth
                value={searchLearningObjectiveValue}
                onChange={(e) =>
                  setSearchLearningObjectiveValue(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                disabled={fetching}
                onClick={async () =>
                  await fetchLearningObjectives(
                    searchLearningObjectiveValue,
                    undefined
                  )
                }
              >
                {fetching ? <CircularProgress /> : 'Search'}
              </Button>
            </Grid>
          </Grid>
          {learningObjectivesSearchResults.length > 0 && (
            <Box sx={{ my: '0.5em' }}>
              <PaginatedDataGrid
                title={'Results'}
                rows={learningObjectivesSearchResults.map(
                  (r): CourseLearningObjective & { id: string | undefined } => {
                    return { ...r, id: r.ulo?.LearningObjectiveUuid };
                  }
                )}
                columns={[
                  {
                    field: 'stringValue',
                    headerName: 'Learning Objective',
                    width: 800
                  }
                ]}
                initialState={{
                  pagination: { paginationModel: { page: 0, pageSize: 5 } }
                }}
                selectedRows={selectedLearningObjectiveSearchResults.map(
                  (item) => item.id
                )}
                setSelectedRows={setSelectedLearningObjectiveSearchResults}
                checkboxSelection={true}
              />
              <Button
                fullWidth
                onClick={() =>
                  addLearningObjectives(selectedLearningObjectiveSearchResults)
                }
              >
                Add Selected ({selectedLearningObjectiveSearchResults.length})
              </Button>
            </Box>
          )}
          <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
            Create New
          </Typography>

          <Grid container sx={{ my: '1em', alignItems: 'center' }}>
            <Grid item xs={11}>
              <TextField
                label={'<learning objective text> (Level <number>)'}
                fullWidth
                value={createLearningObjectiveValue}
                onChange={(e) =>
                  setCreateLearningObjectiveValue(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                fullWidth
                disabled={
                  !isUniqueLearningObjective(createLearningObjectiveValue)
                }
                onClick={async () => {
                  addLearningObjectives([
                    { stringValue: createLearningObjectiveValue }
                  ]);
                  setCreateLearningObjectiveValue('');
                }}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MainCard>
      <MainCard sx={{ my: '1em' }}>
        <Typography variant="h5">
          Learning Objectives ({addedLearningObjectives.length})
        </Typography>
        <Container sx={{ my: '1em' }}>
          {addedLearningObjectives.map((lo, loIndex) => (
            <Box
              sx={{
                my: '0.5em',
                ':hover': { backgroundColor: theme.palette.background.default }
              }}
              key={`added-learning-objective-${loIndex}`}
            >
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={11}>
                  <LearningObjectiveChip courseLearningObjective={lo} />
                </Grid>
                <Grid item xs={1}>
                  <RemoveButton
                    fullWidth
                    onClick={() => removeLearningObjective(loIndex)}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Container>
      </MainCard>
      <MainCard sx={{ my: '1em' }}>
        <Typography sx={{ my: '0.5em' }} variant="h4">
          2) Apply Them
        </Typography>
        <Divider />

        <Typography variant="h3" sx={{ mt: '1em' }}>
          {props.courseCode} {props.courseTitle}
        </Typography>
        <Typography variant="caption">{`Topics (${props.courseTopics.length})`}</Typography>
        <Divider />
        {props.courseTopics.map((t, tIndex) => (
          <TopicLearningObjectives
            courseTopics={props.courseTopics}
            courseTopicIndex={tIndex}
            availableLearningObjectives={addedLearningObjectives}
            setCourseTopics={props.setCourseTopics}
            setEditing={props.setEditing}
          />
        ))}
      </MainCard>
      <Alert severity="info">
        Topics and documents must have atleast 1 learning objective.
      </Alert>
    </div>
  );
};
