/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

export const PaginatedDataGrid = (props: {
  title: string;
  rows: any[]; // requires id property
  columns: GridColDef[];
  initialState: {
    pagination: {
      paginationModel: {
        page: number;
        pageSize: number;
      };
    };
  };
  checkboxSelection: boolean;
  selectedRows: GridRowSelectionModel;
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  useEffect(() => {
    setRowSelectionModel(props.selectedRows);
  }, [props.selectedRows]);

  return (
    <Container sx={{ my: '0.5em', width: '100%' }}>
      <Typography variant="h6">{props.title}</Typography>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        initialState={props.initialState}
        checkboxSelection
        disableRowSelectionOnClick
        // disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(rows) => {
          const lastRowSelected = rows.at(-1);
          const newRowSelectionModel =
            lastRowSelected && rowSelectionModel.includes(lastRowSelected)
              ? rows.slice(0, rows.length - 1)
              : rows;
          const selectedRows = props.rows.filter((row) =>
            newRowSelectionModel.includes(row.id)
          );
          props.setSelectedRows(selectedRows);
        }}
      />
    </Container>
  );
};
