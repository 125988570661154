import { ParagraphProps } from 'app/libraries/html2blocks/blocks/paragraph.types';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';

/**
 * HTML Paragraph
 */
export function Paragraph(
  id: string,
  text: string,
  position: string,
  props: ParagraphProps
) {
  /**
   * NOTE: data-paragraph-text not used - use innerHTML instead.
   */
  const propsJSON = jsonStringifyObjAndHtmlEncode(props);
  return `
    <p 
      id="block-element-${id}"  
      data-block 
      data-type="paragraph" 
      data-element-pos="${position}"
      data-props="${propsJSON}"
    >
      ${text}
    </p>`;
}
