/**
 * Courses Function Endpoint
 */

/**
 * Get
 */
export function getCoursesEndpoint() {
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  return `${baseUrl}/api/courses`;
}
