import { inlineContentToSemanticHtml } from 'app/libraries/blocks2html/utils/inlineContentToHtml';
import { BlockInlineContent } from 'app/libraries/html2blocks/blocks/common.types';
import {
  ListBlock,
  ListProps
} from 'app/libraries/html2blocks/blocks/lists.types';
import { paragraphFontClasses } from './@shared';
import { jsonStringifyObjAndHtmlEncode } from 'utils/objects';

/**
 * Create HTML Maths element
 */
export function List(
  id: string,
  tag: 'ol' | 'ul',
  content: BlockInlineContent[],
  children: ListBlock[],
  props: ListProps,
  position: string
) {
  const ulListStyles = ['list-circle', 'list-square'];

  let styleIndex = -1;

  function renderItems(listItems: ListBlock[]): string {
    if (!listItems) return '';
    if (!listItems.length) return '';

    const listType = listItems.length > 0 ? listItems[0].type : null;
    if (!listType) return '';
    const listTagType = listType === 'bulletListItem' ? 'ul' : 'ol';

    // update style index
    const isOrdered = listTagType === 'ol';
    styleIndex += 1;
    if (styleIndex > 1) styleIndex = 0;
    const arrListStyle = isOrdered ? 'list-decimal' : ulListStyles[styleIndex];

    let listHtml = `<${listTagType} class="${arrListStyle} list-inside pl-4">`;
    for (const item of listItems) {
      const propsJSON = jsonStringifyObjAndHtmlEncode(item.props);
      listHtml += `
    <li
      id="block-element-${item.id}"
      data-block
      data-type="${listType}"
      data-element-pos="${position}"
      data-props="${propsJSON}"
    >${inlineContentToSemanticHtml(item.content)}`;
      if (item.children && item.children.length > 0) {
        listHtml += renderItems(item.children);
      }
      listHtml += `</li>`;
    }
    listHtml += `</${listTagType}>`;

    return listHtml;
  }

  const listClass = tag === 'ol' ? 'list-decimal' : 'list-disc';
  return `
  <div 
    id="block-element-${id}" 
    class="${paragraphFontClasses} mt-2 mb-2" 
    data-block 
    data-type="list" 
    data-list-style="${tag}" 
    data-element-pos="${position}"
  >
    <li class="${listClass} list-inside pl-4">${inlineContentToSemanticHtml(
    content
  )}
      ${renderItems(children)}
    </li>
  </div>`;
}
