/**
 * Remove duplicates from an array of strings and sort the array in alphabetical/numerical order
 */
export function removeDuplicatesAndSort(arr: string[]): string[] {
  // Remove duplicates by converting the array to a Set and back to an array
  const uniqueArr = Array.from(new Set(arr));

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base'
  });
  return uniqueArr.sort(collator.compare);

  // Sort the array in alphabetical order
  // uniqueArr.sort((a, b) => a.localeCompare(b));
  // return uniqueArr;
}

/**
 * Remove duplicates from an array
 */
export function removeDuplicates(arr: any[]) {
  return [...new Set(arr)];
}

// Example usage:
// const inputArray: string[] = ["apple", "orange", "banana", "apple", "grape", "banana"];
// const sortedArray: string[] = removeDuplicatesAndSort(inputArray);
// console.log(sortedArray);

export function sortByText(a: any, b: any) {
  const textA = a.text.toLowerCase();
  const textB = b.text.toLowerCase();

  if (textA < textB) {
    return -1;
  }
  if (textA > textB) {
    return 1;
  }
  return 0;
}

export function sortVersionStrings(arr: string[]) {
  return arr.sort((a: string, b: string) => {
    const partsA = a.split(' ')[0].split('.').map(Number);
    const partsB = b.split(' ')[0].split('.').map(Number);

    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }

    return partsA.length - partsB.length;
  });
}

// export function sortVersionObjectKeys(key: string, arr: any[]) {
//   return arr.sort((a, b) => {
//     const versionA = a[`${key}`].split('.').map(Number);
//     const versionB = b[`${key}`].split('.').map(Number);

//     for (let i = 0; i < Math.min(versionA.length, versionB.length); i++) {
//       if (versionA[i] !== versionB[i]) {
//         return versionA[i] - versionB[i];
//       }
//     }

//     return versionA.length - versionB.length;
//   });
// }

export function compareLabels(a: any, b: any) {
  const aParts = a.label.split('.').map(Number);
  const bParts = b.label.split('.').map(Number);

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    const aValue = aParts[i] || 0;
    const bValue = bParts[i] || 0;

    if (aValue !== bValue) {
      return aValue - bValue;
    }
  }

  return 0;
}

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const findOne = (haystack: any[], arr: any[]) => {
  return arr.some((v: any) => haystack.includes(v));
};

/**
 * Check and force into an array
 */
export function ensureArray<T>(value: T | T[]): T[] {
  if (value === undefined || value === null) return [];
  if (Array.isArray(value)) {
    return value;
  } else {
    return [value];
  }
}