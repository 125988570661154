import React, { useEffect, useState } from 'react';
import {
  CourseLearningObjective,
  CourseTopic
} from '../../../app/types/service/course-manager/course.types';
import { ListEditor } from './ListEditor';
import {
  Typography,
  Grid,
  Button,
  Divider,
  Container,
  Box,
  useTheme
} from '@mui/material';
import { LearningObjectiveChip } from './LearningObjectiveChip';
import { uniqueLoRegexPattern } from 'app/service/utils/learningObjectives';

export const DocumentLearningObjectivesEditor = (props: {
  courseTopics: CourseTopic[];
  courseTopicIndex: number;
  courseDocumentIndex: number;
  availableLearningObjectives: CourseLearningObjective[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();

  const document = props.courseTopics[props.courseTopicIndex].documents?.at(
    props.courseDocumentIndex
  );
  const documentLearningObjectives = document?.learningObjectives ?? [];

  const [localLearningObjectives, setLocalLearningObjectives] = useState(
    documentLearningObjectives
  );

  const [learningObjectivesBuilt, setLearningObjectivesBuilt] = useState(false);
  const saveLearningObjectives = () => setLearningObjectivesBuilt(true);
  const editLearningObjectives = () => {
    props.setEditing(true);
    setLearningObjectivesBuilt(false);
  };

  const saveTopics = () => {
    const newCourseTopics = [...props.courseTopics];
    let documents = newCourseTopics[props.courseTopicIndex].documents;
    if (!documents) return;
    documents[props.courseDocumentIndex].learningObjectives =
      localLearningObjectives
        .map((lo) => {
          // recover ulo since ListEditor component drops the property
          const alo = props.availableLearningObjectives.find(
            (alo) => lo.stringValue === alo.stringValue
          );
          if (!alo) return undefined;
          return {
            ...alo,
            prefixStringValue: alo.prefixStringValue,
            loId: alo.prefixStringValue
          };
        })
        .filter((lo) => lo !== undefined);
    newCourseTopics[props.courseTopicIndex].documents = documents;
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    setLocalLearningObjectives(
      localLearningObjectives.filter((lo) =>
        props.availableLearningObjectives
          .map((lo) => lo.stringValue)
          .includes(lo.stringValue)
      )
    );
  }, [props.availableLearningObjectives]);

  useEffect(() => {
    saveLearningObjectives();
    saveTopics();
  }, [localLearningObjectives]);

  if (!document) return;

  return (
    <Container
      sx={{
        py: '1em',
        ':hover': {
          backgroundColor: theme.palette.background.default
        }
      }}
    >
      <Typography variant="h5">
        {props.courseTopics[props.courseTopicIndex].documents?.at(
          props.courseDocumentIndex
        )?.stringValue ?? ''}
      </Typography>

      {learningObjectivesBuilt && (
        <Box>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="caption">{`Learning Objectives (${localLearningObjectives.length})`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={props.availableLearningObjectives.length === 0}
                fullWidth
                onClick={editLearningObjectives}
              >
                Apply LOs
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Container>
            {document.learningObjectives?.map(
              (lo, index) => (
                <LearningObjectiveChip
                  key={`topic-document-learning-objective-${index}`}
                  courseLearningObjective={lo}
                />
              )

              // <Box
              //   sx={{ my: '0.5em' }}
              // >
              //   <Divider />
              //   <Typography variant="body1">{}</Typography>
              //   <Divider />
              // </Box>
            )}
          </Container>
        </Box>
      )}
      {!learningObjectivesBuilt && (
        <ListEditor
          title={''}
          listItemName={'LO'}
          listSelectChoices={props.availableLearningObjectives}
          includesPrefix={false}
          itemRegexValidation={uniqueLoRegexPattern}
          list={localLearningObjectives}
          setList={setLocalLearningObjectives}
        />
      )}
    </Container>
  );
};
