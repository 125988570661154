import { ProcessedHtmlContentObject } from '../../../types/libraries/content.obj2html.types';
import { createErrorBlock } from '../blocks/error';
import { generateContentEditorObject } from '../utils/editorjs';
import { htmlAccordion2Obj } from './accordions';
import { htmlSummary2Obj } from './summary';

/**
 * Convert HTML to an editor object
 */
export default function html2blocksV1(
  id: string,
  html: string
): ProcessedHtmlContentObject {
  /**
   * Split the HTML content file with the delimiter
   * <div class="accordion">
   */
  const accordionIdentifier = '<div class="accordion">';
  const splitHtmlArray: string[] = html.split(accordionIdentifier);

  if (splitHtmlArray.length === 1) {
    /**
     * Content File Type - (1)
     * SUMMARY FORMAT
     * IF the content has no accordions, it is considered a summary (at this point)
     */
    const htmlString = `${splitHtmlArray[0]}`;
    const text = htmlSummary2Obj(htmlString);
    return {
      heading: 'Summary',
      summary: text || '',
      learningObjectives: [],
      content: generateContentEditorObject([])
    };
  } else if (splitHtmlArray.length === 2) {
    /**
     * Content File Type - (2)
     * ACCORDION FORMAT
     * IF the content has accordions, it is standard HTML content
     */
    const headingHtmlString = `${splitHtmlArray[0]}`;
    const accordionHtmlString = `${accordionIdentifier}${splitHtmlArray[1]}`;
    const { heading, learningObjectives, blocks } = htmlAccordion2Obj(
      headingHtmlString,
      accordionHtmlString
    );

    // let slideBlock: SlideBlock | undefined;
    // let nextMediaBlock: MediaBlock | undefined;
    // for (let i = 0; i < blocks.length; i++) {
    //   slideBlock = undefined;
    //   /**
    //    * Find media slide block
    //    */
    //   if (blocks.at(i)?.type === 'slide') {
    //     slideBlock = blocks.at(i) as SlideBlock;
    //     if (slideBlock.data.format === 'media') {
    //       /**
    //        * Find next media element
    //        */
    //       nextMediaBlock = undefined;
    //       for (let j = i; j < blocks.length; j++) {
    //         if (blocks.at(j)?.type === 'media') {
    //           nextMediaBlock = blocks.at(j) as MediaBlock;
    //           break;
    //         }
    //       }
    //       /**
    //        * Create slide element
    //        */
    //       if (nextMediaBlock) {
    //         slideBlock.data.slide = [
    //           {
    //             type: 'media',
    //             html: '',
    //             fragment: false,
    //             animation: 'none',
    //             media: nextMediaBlock.data.type === 'image' ? 'img' : 'iframe',
    //             url: nextMediaBlock.data.url,
    //             caption: nextMediaBlock.data.caption
    //           } as SlideElement
    //         ];
    //         /**
    //          * Update blocks
    //          */
    //         blocks[i] = slideBlock!;
    //       }
    //     }
    //   }
    // }

    return {
      heading,
      summary: '',
      learningObjectives,
      content: generateContentEditorObject(blocks)
    };
  } else {
    console.error('Error: This is a problem!!');
    return {
      heading: 'Error',
      summary: '',
      learningObjectives: [],
      content: generateContentEditorObject([
        createErrorBlock(
          'An error occurred trying to load this content from the Hangar, please let a PDD team member know ASAP!'
        )
      ])
    };
  }
}
