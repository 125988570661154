import {
  LayoutOutlined,
  FileImageOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  ReadOutlined,
  EditOutlined,
  DiffOutlined,
  CompassOutlined,
  ShareAltOutlined,
  RocketOutlined,
  ApartmentOutlined
} from '@ant-design/icons';

// icons
const icons = {
  LayoutOutlined,
  FileImageOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  ReadOutlined,
  EditOutlined,
  DiffOutlined,
  CompassOutlined,
  ShareAltOutlined,
  RocketOutlined,
  ApartmentOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const coursewareAdmin = {
  id: 'group-courseware',
  title: 'Courseware',
  type: 'group',
  children: [
    {
      id: 'courses',
      title: 'Courses',
      type: 'item',
      icon: icons.ReadOutlined,
      url: '/courses'
    },
    {
      id: 'workflows',
      title: 'Workflows',
      type: 'item',
      url: '/workflows',
      icon: icons.ShareAltOutlined
    },
    {
      id: 'course-manager',
      title: 'Course Manager',
      type: 'item',
      url: '/course-manager',
      icon: icons.ApartmentOutlined
    },
    {
      id: 'learning-objectives',
      title: 'Learning Objectives',
      type: 'item',
      icon: icons.CompassOutlined,
      url: '/learning-objectives'
    }
  ]
};

export default coursewareAdmin;
