import {
  ContentToLink,
  CourseTopic,
  TopicToLink
} from 'app/types/service/course-manager/course.types';
import {
  CourseResponse,
  PostCourseRequestBody
} from 'app/types/integration/courses.api.types';
import {
  CourseTopicObject,
  DocumentReferenceObject,
  RegulatorType
} from 'app/types/service/courses.types';
import { extractTaxonomyComponentsFromTaxonomyString } from '../utils/taxonomies';
import {
  createCourseMetadataObject,
  createCourseResourceName
} from '../utils/courses';
import {
  postExistingCourse,
  postNewCourse
} from 'app/integration/server/library/courses';
import { upsertContent } from './document/content/upsert';
import { createDocument } from './document/create';
import {
  getTaxonomyUuidByCode,
  postTaxonomyTerms
} from 'app/integration/server/library/taxonomies';

/**
 * Course Manager service function to publish a new course
 *
 * 1. Create new course resource with empty topics
 * 2. Create/update content resources
 * 3. Create document resources with linked contents resources
 * 4. Update new course with topics containing document resources
 *
 * @returns success boolean
 */
export const createCourse = async (
  courseType: RegulatorType,
  courseTaxonomy: string,
  courseCode: string,
  courseTitle: string,
  courseTopics: CourseTopic[],
  userId: string,
  accessToken: string
): Promise<boolean> => {
  /**
   * Create empty course
   */

  const taxonomyUuid = getTaxonomyUuidByCode(courseType);
  if (!taxonomyUuid) {
    console.error(`Failed to get taxonomy UUID from ${courseType}`);
    return false;
  }
  const courseResourceName = createCourseResourceName(
    courseType,
    courseCode,
    courseTitle,
    courseTaxonomy
  );
  if (!courseResourceName) {
    console.error('Failed to create course resource name.');
    console.log(courseType);
    console.log(courseTaxonomy);
    return false;
  }
  const courseTaxonomyWithoutReg = courseTaxonomy
    .split('\\')
    .slice(1)
    .join('\\');
  const taxonomyComponents = extractTaxonomyComponentsFromTaxonomyString(
    courseType,
    courseTaxonomyWithoutReg
  );
  if (!taxonomyComponents) {
    console.error('Failed to derive taxonomy components');
    console.log(courseType);
    console.log(courseTaxonomy);
    return false;
  }
  const courseAuthority = taxonomyComponents?.authority;

  const courseMetadataObject = createCourseMetadataObject(
    courseType,
    courseCode,
    courseTitle,
    courseTaxonomy
  );
  if (!courseMetadataObject) {
    console.error('Failed to create course metadata object.');
    console.log(courseType);
    console.log(courseTaxonomy);
    return false;
  }
  let postCourseRequestBody: PostCourseRequestBody = {
    name: courseResourceName,
    description: `The course template for the single-source course offering ${courseCode} ${courseTitle}`,
    resourceReview: 'Created new course with Course Builder via Toolbox',
    template: {
      schema: '1.0.0',
      regulator: courseType,
      authority: taxonomyComponents?.authority!,
      code: courseCode,
      title: courseTitle,
      taxonomy: courseTaxonomyWithoutReg,
      metadata: courseMetadataObject,
      metrics: {}, // NOT USED YET
      topics: [],
      linkedResources: []
    }
  };
  const postNewCourseRequest = await postNewCourse(
    postCourseRequestBody,
    accessToken
  );
  if (postNewCourseRequest.status !== 200) {
    console.error(postNewCourseRequest);
    return false;
  }
  const newCourseResponse = postNewCourseRequest.data as CourseResponse;
  const courseUuid = newCourseResponse.resource.uuid;

  const topicsToLink: TopicToLink[] = [];

  for (let i = 0; i < courseTopics.length; i++) {
    const topic = courseTopics[i];
    const topicId = topic.topicId;

    const topicToLink: TopicToLink = {
      ...topic,
      documentsToLink: []
    };
    const documents = topic.documents;
    if (!documents) continue;
    for (let j = 0; j < documents.length; j++) {
      const document = documents[j];

      /**
       * Create/Update contents
       */
      const contents = document.contents;
      let contentsToLink: ContentToLink[] = [];
      if (!contents) continue;
      for (let k = 0; k < contents.length; k++) {
        const content = contents[k];
        const contentToLink = await upsertContent(
          content,
          courseUuid,
          courseTaxonomy,
          topicId,
          userId,
          accessToken
        );
        if (!contentToLink) {
          console.error('Failed to upsert content');
          console.log(content);
          return false;
        }
        contentsToLink.push(contentToLink);
      }

      /**
       * Create document
       */
      const documentToLink = await createDocument(
        courseType,
        courseAuthority,
        courseCode,
        courseTitle,
        courseTaxonomyWithoutReg,
        courseUuid,
        topicId,
        document,
        contentsToLink,
        userId,
        accessToken
      );
      if (!documentToLink) {
        console.error('Failed to create document');
        console.log(document);
        return false;
      }
      topicToLink.documentsToLink.push(documentToLink);
    }
    topicsToLink.push(topicToLink);
  }

  /**
   * Update course with linked topics
   */
  const courseTopicObjects: CourseTopicObject[] = topicsToLink.map(
    (topicToLink): CourseTopicObject => {
      return {
        topicName: topicToLink.stringValue,
        topicNumber: topicToLink.topicId ?? '',
        documents: topicToLink.documentsToLink
          ? topicToLink.documentsToLink.map(
              (documentToLink): DocumentReferenceObject => {
                return {
                  uuid: documentToLink.postItemResponse.uuid,
                  version: documentToLink.postItemResponse.version,
                  attachmentUuid:
                    documentToLink.postItemResponse.attachments.find(
                      (a) => a.type === 'file'
                    )?.attachmentId ?? ''
                };
              }
            )
          : []
      };
    }
  );
  postCourseRequestBody.resourceReview =
    'Linked topics to new course with Course Builder via Toolbox';
  postCourseRequestBody.template.topics = courseTopicObjects;
  postCourseRequestBody.template.linkedResources =
    newCourseResponse.course.linkedResources;
  const postExistingCourseRequest = await postExistingCourse(
    courseUuid,
    postCourseRequestBody,
    accessToken
  );
  if (postExistingCourseRequest.status !== 200) {
    console.error(postExistingCourseRequest);
    return false;
  }

  /**
   * Create taxonomies
   */
  for (let i = 0; i < topicsToLink.length; i++) {
    const topic = topicsToLink[i];
    for (let j = 0; j < topic.documentsToLink.length; j++) {
      const document = topic.documentsToLink[j];
      const taxonomies = document.contentsToLink.flatMap(
        (contentToLink) => contentToLink.taxonomies
      );
      const taxonomiesWithoutReg = taxonomies.map((t) =>
        t.split('\\').splice(1).join('\\')
      );
      const postTaxonomyTermsRequest = await postTaxonomyTerms(
        taxonomyUuid,
        taxonomiesWithoutReg,
        accessToken
      );
      if (postTaxonomyTermsRequest.status !== 200) {
        console.error('Failed to post taxonomy terms');
        console.error(postTaxonomyTermsRequest);
        console.error(taxonomies);
        return false;
      }
    }
  }

  return true;
};
