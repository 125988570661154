/* eslint-disable prettier/prettier */

import { DocumentEditorObject } from 'app/types/service/document.types';
import styledContent from '../main';
import { LoSummary } from './lo-summary';
import { ContentEditorObject } from 'app/types/service/content.types';
import { DocumentHTMLBuildProcess } from '..';

// ${CompSummary(master.learningObjectives).trim()}
// <hr class="mb-4 mt-6 dark:border-t-gray-600">

function MainContentButtons() {
  const buttonColorClasses =
    'text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:focus:ring-gray-500 dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700';

  return `
        <div id="page-content-buttons" class="hidden text-center mt-2 w-full">
            <div class="inline-flex rounded-md shadow-sm w-full md:w-auto" role="group">

                <button type="button" onclick="tocHandler();" data-tooltip-target="tooltip-1" data-tooltip-placement="bottom"
                class="inline-flex items-center px-4 py-2 text-sm font-medium rounded-l-lg focus:z-10 ${buttonColorClasses} w-1/3 items-center justify-center">
                    <svg 
                        aria-hidden="true" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke-width="1.5" 
                        stroke="currentColor" 
                        class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
                <div id="tooltip-1" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Table of Contents
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>

                <button id="chevron-button" type="button" onclick="toggleAllAccordions();" data-tooltip-target="tooltip-2" data-tooltip-placement="bottom"
                class="inline-flex items-center px-4 py-2 text-sm font-medium focus:z-10 ${buttonColorClasses} w-1/3 items-center justify-center">
                    <svg id="chevron-svg"
                        aria-hidden="true" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke-width="1.5" 
                        stroke="currentColor" 
                        class="w-4 h-4">
                    </svg>
                </button>
                <div id="tooltip-2" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Expand/Collapse All Content
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>

                <button type="button" aria-controls="drawer-right-settings" onclick="toggleDrawer(1)"
                data-drawer-placement="right" aria-controls="drawer-right-settings" data-tooltip-target="tooltip-3" data-tooltip-placement="bottom"
                class="inline-flex items-center px-4 py-2 text-sm font-medium rounded-r-md focus:z-10 ${buttonColorClasses} w-1/3 items-center justify-center">
                    <svg 
                        aria-hidden="true" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke-width="1.5" 
                        stroke="currentColor" 
                        class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                    </svg>
                </button>
                <div id="tooltip-3" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Page Options
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
        </div>
    `;
}

/**
 * HTML Generate Content
 */
export function Main(
  master: DocumentEditorObject,
  process: DocumentHTMLBuildProcess
) {
  /**
   * Convert the content to styled HTML
   */
  const documentLocation = master.document.location;
  const contentObjects: ContentEditorObject[] = master.document.content!;
  const contentHtml = contentObjects
    .map((contentObj) => {
      return styledContent(documentLocation, contentObj, process);
    })
    .join('\n');

  /**
   * @IMPORTANT
   * The id="page-content" is used by:
   * 1. The JavaScript powering the HTML
   * 2. The API server to identify the content
   * 3. The openEQUELLA save script
   * So, in short, do not change this without understanding the implications.
   */
  return `
        <div 
          id="page-content"
          data-title="${master.document.heading}" 
          class="md:container md:mx-auto px-4 mb-36 spotlight-group lg:max-w-[1024px]"
        >
          <!-- Page Buttons -->
          ${MainContentButtons().trim()}

          <!-- Page Heading -->
          <h1 
            id="master-heading" 
            class="text-3xl md:text-5xl lg:max-w-[80%] xl:max-w-[80%] font-bold text-aa-blue dark:text-aa-blue mt-10 mb-6"
          >
            ${master.document.heading}
          </h1>

          <hr class="dark:border-t-gray-600">

          <!-- Learning Objective Summary -->
          ${LoSummary(master.document.location, master.document.content).trim()}

          <hr class="mb-4 mt-6 dark:border-t-gray-600">

          <!-- Summary -->
          ${
            master?.document?.summary === '' ||
            master?.document?.summary === null
              ? `<!-- No summary provided -->`
              : `<hr class="mb-4 mt-6 dark:border-t-gray-600">
              <p id="summary" class="text-sm text-gray-700 dark:text-gray-400 mt-2 mb-2">${master.document.summary}</p>`
          }

          <!-- Content -->
          ${contentHtml}
        </div>
    `;
}
