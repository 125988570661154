function convertStringToLevel(str: string): string {
  const match = str.match(/\[L(\d)\]/);
  if (match) {
    const levelNumber = match[1];
    const convertedStr = str.replace(match[0], `(Level ${levelNumber})`);
    return convertedStr;
  }
  return str;
}

export function convertLearningLevelsToNewFormat(
  legacyLearningObjectives: string[]
) {
  return legacyLearningObjectives.map((str) => convertStringToLevel(str));
}
