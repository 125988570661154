import { validateOrCreateUuidV4 } from '../utils/uuid';
import { ErrorBlock } from './error.types';

export function createErrorBlock(
  message: string,
  additionalData?: string,
  id?: string
): ErrorBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'error',
    props: {
      message,
      additionalData
    }
  };
}
