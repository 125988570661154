import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import MainCard from 'components/app/MainCard';
import { ListEditor } from 'pages/course-manager/components/ListEditor';
import { TaxonomyTermSelector } from 'pages/course-manager/components/TermSelector';
import { TopicDocumentsEditor } from 'pages/course-manager/components/TopicDocumentsEditor';
import { CourseTopic } from 'app/types/service/course-manager/course.types';

export const DesignCourseStructure = (props: {
  courseTaxonomyPath: string | undefined;
  setCourseTaxonomyPath: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  courseCode: string;
  courseTitle: string;
  setCourseCode: React.Dispatch<React.SetStateAction<string>>;
  setCourseTitle: React.Dispatch<React.SetStateAction<string>>;
  courseTaxonomy: string | undefined;
  saveCourseTaxonomy: () => void;
  changeCourseTaxonomy: () => void;
  courseTopics: CourseTopic[];
  setCourseTopics: React.Dispatch<React.SetStateAction<CourseTopic[]>>;
  courseTopicsBuilt: boolean;
  editTopics: () => void;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [courseTopics, setCourseTopics] = useState<CourseTopic[]>(
    props.courseTopics
  );

  const saveTopics = () => {
    const newCourseTopics = courseTopics.map((topic) => {
      return { ...topic, topicId: topic.prefixStringValue };
    });
    props.setCourseTopics(newCourseTopics);
  };

  useEffect(() => {
    saveTopics();
  }, [courseTopics]);

  return (
    <div>
      {!props.courseTaxonomyPath && (
        <TaxonomyTermSelector
          excludeLastStep={true}
          setTaxonomyPath={props.setCourseTaxonomyPath}
        />
      )}

      {props.courseTaxonomyPath && !props.courseTaxonomy && (
        <MainCard sx={{ my: '1em' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <p>
                <b>{`${props.courseTaxonomyPath}\\`}</b>
              </p>
            </Grid>
            <Grid item xs={1}>
              <TextField
                fullWidth
                label="Code"
                value={props.courseCode}
                onChange={(e) => props.setCourseCode(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                label="Name"
                value={props.courseTitle}
                onChange={(e) => props.setCourseTitle(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={props.courseCode === '' && props.courseTitle === ''}
                fullWidth
                onClick={props.saveCourseTaxonomy}
              >
                Set Course Name
              </Button>
            </Grid>
          </Grid>
        </MainCard>
      )}
      {props.courseTaxonomy && (
        <MainCard sx={{ my: '1em' }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="overline">Course Taxonomy:</Typography>
            </Grid>
            <Grid item xs>
              <p>
                <b>{props.courseTaxonomy}</b>
              </p>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={props.changeCourseTaxonomy}>
                Change Course Name
              </Button>
            </Grid>
          </Grid>
        </MainCard>
      )}
      {props.courseTaxonomy && !props.courseTopicsBuilt && (
        <ListEditor
          title="Course Topics"
          list={courseTopics}
          listItemName={'Topic Title'}
          includesPrefix={true}
          prefixName="Topic Number"
          setList={setCourseTopics}
        />
      )}
      {props.courseTaxonomy && props.courseTopicsBuilt && (
        <MainCard sx={{ my: '1em' }}>
          <Typography variant="h3">
            {props.courseCode} {props.courseTitle}
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <Typography variant="caption">{`Topics (${props.courseTopics.length})`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={props.editTopics}>
                Edit Topics
              </Button>
            </Grid>
          </Grid>
          <Divider />
          {props.courseTopics.map((topic, topicIndex) => (
            <Box key={`course-topic-${topicIndex}`}>
              <TopicDocumentsEditor
                courseTopics={props.courseTopics}
                courseTopicIndex={topicIndex}
                setCourseTopics={props.setCourseTopics}
                setEditing={props.setEditing}
              />
              <Divider />
            </Box>
          ))}
        </MainCard>
      )}
    </div>
  );
};
