/**
 * UUID Utility Functions
 */

import { v4 as uuidv4, validate } from 'uuid';

export function generateNewUuid() {
  return uuidv4();
}

/**
 * Checks if a string input (assumes nanoId)
 * Otherwise generates a nano id of length 10
 * Editor-js uses nanoid(10) by default - https://github.com/codex-team/editor.js/pull/1667
 * So the decision was made to match that for consistency, however we don't need the 21 digit collision probability.
 */
export function validateOrCreateUuidV4(id?: string) {
  if (!id || !validate(id)) return uuidv4();
  else return id;
}
