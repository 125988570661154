/**
 * =============================================================================
 * Page Banner
 * A banner used to force information to the user.
 * This is off by default.
 * =============================================================================
 */
export function Banner() {
  return `
    <div id="page-banner" data-banner>
        <div id="informational-banner" 
            tabindex="-1" 
            class="fixed top-0 left-0 z-60 flex flex-col justify-between w-full p-4 border-b border-gray-200 md:flex-row bg-gray-50 dark:bg-gray-700 dark:border-gray-600 hidden">
          
            <!-- Banner Content -->
            <div id="page-banner-content" class="mb-4 md:mb-0 md:mr-4"></div>

            <!-- Banner Buttons -->
            <div id="page-banner-buttons" class="flex items-center flex-shrink-0">
                <a href="#" class="inline-flex items-center px-3 py-2 mr-3 text-xs font-medium text-gray-700 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-aa-blue focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <svg class="w-4 h-4 mr-1.5" 
                        fill="currentColor" 
                        viewBox="0 0 20 20" 
                        xmlns="http://www.w3.org/2000/svg" 
                        aria-hidden="true">
                        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z">
                        </path>
                    </svg> Learn more</a>
                
                <a href="#" class="inline-flex px-3 py-2 mr-2 text-xs font-medium text-white bg-aa-blue rounded-lg hover:bg-aa-navy focus:ring-4 focus:ring-blue-300 dark:bg-aa-blue dark:hover:bg-aa-navy focus:outline-none dark:focus:ring-aa-font">
                    Get started <svg class="h-4 w-4 ml-1.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path clip-rule="evenodd" fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"></path></svg></a>
                
                <!-- Close Button -->
                <button data-dismiss-target="#informational-banner" type="button" class="absolute top-2.5 right-2.5 md:relative md:top-auto md:right-auto flex-shrink-0 inline-flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close banner</span>
                </button>
            </div>
        </div>
    </div>
    `;
}
