import { PutContentRequestBody } from 'app/types/integration/content.api.types';
import { CourseContent } from 'app/types/service/course-manager/course.types';
import { putContentTaxonomies } from 'app/integration/server/library/content';
import { PostItemResponseObject } from 'app/types/integration/items.api.types';

export const updateContent = async (
  contentUuid: string,
  contentTaxonomies: string[],
  courseContent: CourseContent,
  courseUuid: string,
  courseTaxonomy: string,
  topicId: string | undefined,

  accessToken: string
): Promise<PostItemResponseObject | null> => {
  const putContentRequestBody: PutContentRequestBody = {
    taxonomies: contentTaxonomies,
    courseId: courseUuid,
    resourceReview: `Updated taxonomies with Course Builder via Toolbox`
  };

  const putContentTaxonomiesResponse = await putContentTaxonomies(
    contentUuid,
    putContentRequestBody,
    accessToken
  );

  if (putContentTaxonomiesResponse.status !== 200) {
    console.error('Failed to update content taxonomies.');
    console.log(putContentTaxonomiesResponse);
    return null;
  } else {
    const updatedContentResponseData: PostItemResponseObject =
      putContentTaxonomiesResponse.data;
    return updatedContentResponseData;
  }
};
