/* eslint-disable @typescript-eslint/no-unused-vars */

import { htmlCollectionToArray } from '../utils/html';
import { createTPBlockFromText } from '../blocks/tp';
import { createParagraphBlockFromText } from '../blocks/paragraph';
import { createHeadingBlockFromText } from '../blocks/heading';
import { createMathsBlock } from '../blocks/maths';

import { removeFluffFromString, sanitiseParagraph } from '../utils/strings';
import { recursiveHtmlListIntoArray } from '../utils/lists';
import { convertLearningLevelsToNewFormat } from '../utils/learningLevels';
import {
  getAttachmentUuidFromHangarApiUrl,
  getUuidFromEquellaApiUrl,
  getVersionFromEquellaApiUrl,
  parseAbsoluteHangarUrl
} from '../../../service/utils/hangar-urls';

import { createErrorBlock } from '../blocks/error';
import { generateSlidesFromLegacyHtml } from './slides';

// Types
import { Block } from 'app/types/libraries/block.types';
import { SlideBlock } from '../blocks/slide.types';
import { MediaProps } from '../blocks/media.type';
import { createMediaBlock } from '../blocks/media';
import {
  createBulletListBlock,
  createNumberedListBlock
} from '../blocks/lists';
import { ListArray, ListBlock } from '../blocks/lists.types';
import {
  createBlockInlineContent,
  defaultProps,
  recursiveConvertListArrayToBlocks
} from './utils';

interface LegacyHTMLAccordionMapOutput {
  heading: string;
  learningObjectives: string[];
  blocks: Block[];
}

/**
 * HTML Accordion to Editor Object
 */
export function htmlAccordion2Obj(
  headingHtml: string,
  bodyHtml: string
): LegacyHTMLAccordionMapOutput {
  /**
   * Parse HTML as virtual DOM
   */
  const parser = new DOMParser();
  const headingDoc = parser.parseFromString(headingHtml, 'text/html');
  const bodyDoc = parser.parseFromString(bodyHtml, 'text/html');

  const headingElement = headingDoc.getElementsByTagName('BODY')[0];
  const accordionElement = bodyDoc.getElementsByTagName('BODY')[0];

  // init content heading
  let contentHeading = 'Content';

  // init blocks
  const blocks: Block[] = [];
  const errorBlocks: any[] = [];
  const learningObjectives: string[] = [];

  /**
   * Heading section
   * This should only ever contain 1 of 3 elements: H2s, learningObjectives and teachingPoints
   */
  for (const el of headingElement.children) {
    if (el.tagName === 'H2') {
      /**
       * Heading 2
       * Section heading (outside of the editor)
       */
      if (el?.textContent) {
        contentHeading = el.textContent.trim();
      }
    } else if (el.tagName === 'DIV') {
      if (el.className.includes('i-lo')) {
        /**
         * Learning objectives
         * These no longer get added as blocks, but are added to the learningObjectives array
         */
        const loArray = htmlCollectionToArray(el.getElementsByTagName('li'));
        const learningObjectiveList = loArray.map((e) =>
          e?.textContent?.trim()
        );

        // Additional: Add to LO list
        learningObjectiveList.forEach((element) => {
          if (element) learningObjectives.push(element);
        });
      } else if (el.className.includes('i-tp')) {
        /**
         * Teaching points
         */
        const elementId = el.id;
        const tpArray = htmlCollectionToArray(el.getElementsByTagName('li'));
        tpArray.forEach((e) => {
          if (e?.textContent) {
            const text = sanitiseParagraph(e?.textContent || '');
            blocks.push(createTPBlockFromText(text, elementId));
          }
        });
      }
    }
  }

  /**
   * Accordion section
   * @accordionElement
   */
  const accordionSection =
    accordionElement.getElementsByClassName('accordion')[0]; // There will only be 1 accordion section

  // Error handling
  if (!accordionSection) {
    console.error('Error: Accordion section empty');
    return {
      heading: contentHeading,
      learningObjectives,
      blocks: [
        createParagraphBlockFromText(
          'An error occurred trying to load this content from the Hangar, please let a PDD team member know ASAP!'
        )
      ]
    };
  }

  /**
   * Accordions are always within:
   * <div class="card instructor">
   */
  const accordions = htmlCollectionToArray(
    accordionSection.getElementsByClassName('card instructor')
  );

  /**
   * Accordion content
   * Main content
   */
  if (accordions.length > 0) {
    accordions.forEach((element) => {
      /**
       * Parse HTML as virtual DOM
       */
      const accordionDoc = parser.parseFromString(
        element.outerHTML,
        'text/html'
      );

      /**
       * Heading 3
       * The accordion title
       */
      const nodes = accordionDoc.getElementsByClassName('i-title card-title');
      const els = htmlCollectionToArray(nodes);
      if (els.length > 1) console.error('Multiple Titles!');
      const el = els[0];
      const elementId = el.id;
      const accordionHeading = el?.textContent?.trim();
      if (accordionHeading)
        blocks.push(createHeadingBlockFromText(accordionHeading, 1, elementId)); // H3 HTML becomes H1 in the editor

      /**
       * Accordion body
       */
      const body = accordionDoc.getElementsByClassName('card-body')[0];

      /**
       * Iterate through accordion body
       */
      for (let index = 0; index < body.children.length; index++) {
        const item = body.children[index];
        if (item.tagName === 'P') {
          if (item.innerHTML.includes('$$')) {
            /**
             * Maths (LEGACY FORMAT)
             */
            const elementId = item?.id;
            const maths = item?.textContent?.replaceAll('$$', '')?.trim();
            if (maths) blocks.push(createMathsBlock(maths, elementId));
          } else if (item.outerHTML.includes('aa-table-caption')) {
            // Do nothing
          } else {
            /**
             * Paragraph
             */
            const elementId = item.id;
            const text = sanitiseParagraph(item?.textContent || '');
            if (text) {
              blocks.push(createParagraphBlockFromText(text, elementId));
            }
          }
        } else if (item.tagName === 'H4') {
          /**
           * Heading 4
           */
          const elementId = item.id;
          const heading = sanitiseParagraph(item?.textContent || '');
          blocks.push(
            createHeadingBlockFromText(
              heading ? heading : 'Heading',
              2,
              elementId
            ) // H4 HTML becomes H2 in the editor
          );
        } else if (item.tagName === 'H5') {
          /**
           * Heading 5
           */
          const elementId = item.id;
          const heading = sanitiseParagraph(item?.textContent || '');
          blocks.push(
            createHeadingBlockFromText(
              heading ? heading : 'Heading',
              3,
              elementId
            ) // H5 HTML becomes H3 in the editor
          );
        } else if (item.tagName === 'H6') {
          /**
           * Heading 6
           */
          const elementId = item.id;
          const heading = sanitiseParagraph(item?.textContent || '');
          blocks.push(
            createHeadingBlockFromText(
              heading ? heading : 'Heading',
              3,
              elementId
            ) // H6 HTML becomes H3 in the editor as well (as we only want to support 3 levels of headings for now).
          );
        } else if (item.tagName === 'DIV') {
          // DIV >> $$
          if (item.innerHTML.includes('$$')) {
            const lines = item.getElementsByTagName('P');
            const mathStrings = [];
            for (let index = 0; index < lines.length; index++) {
              const element = lines[index];
              const math = element?.textContent;
              if (math) mathStrings.push(math.replaceAll('$$', '').trim());
            }

            /**
             * Maths (Multiline)
             */
            const elementId = item.id;
            const maths = mathStrings.join(' \\\\ ');
            blocks.push(createMathsBlock(maths, elementId));

            // DIV >> raw-html
          } else if (item.className.includes('raw-html')) {
            /**
             * Raw HTML
             */
            const elementId = item.id;
            const htmlString = item.innerHTML.trim();
            blocks.push(
              createErrorBlock(
                'Raw HTML block is no longer supported. Please view live content to see what was here.',
                elementId
              )
            );

            // DIV >> video-wrapper
          } else if (item.className.includes('video-wrapper')) {
            // Error handling
            if (item.children.length > 2) {
              console.error('Media irregular - Not loaded correctly');
            }

            /**
             * Embed - Video needs to be replaced with media library resource
             */
            const embedEl: any = item.children[0];
            const captionEl: any = item.children[1];
            const iframeEl: any = embedEl.children[0];

            let service = 'unknown';
            const source = iframeEl.src;
            if (source.includes('youtube')) service = 'youtube';
            const caption = removeFluffFromString(
              captionEl?.textContent ? captionEl.textContent : ''
            );
            const mediaDataJSON = {
              url: source, // The primary URL to the media resource
              type: 'video', // The type of media resource
              caption, // The caption for the media
              service: service
            };
            const elementId = item.id;
            if (iframeEl)
              blocks.push(
                createErrorBlock(
                  'This media needs to be replaced with the equivalent media library resource. Find this in the media collection and create/update the resource if required.',
                  JSON.stringify(mediaDataJSON),
                  elementId
                )
              );
          } else if (item.className.includes('clearfix')) {
            // DIV >> Anything else
          } else {
            /**
             * Parse HTML as virtual DOM
             */
            const virtualDiv = parser.parseFromString(
              item.outerHTML,
              'text/html'
            );

            if (item.outerHTML.includes('i-tp')) {
              /**
               * Teaching points
               */
              const elementId = item.id;
              const ulArray = htmlCollectionToArray(
                virtualDiv.getElementsByTagName('li')
              );
              const teachingPointList = ulArray.map((e) =>
                sanitiseParagraph(e.innerHTML)
              );
              for (let index = 0; index < teachingPointList.length; index++) {
                const tp = teachingPointList[index];
                blocks.push(createTPBlockFromText(tp, elementId));
              }
            } else if (item.outerHTML.includes('i-sp')) {
              /**
               * Summary point element
               */
              const elementId = item.id;
              const ulArray = htmlCollectionToArray(
                virtualDiv.getElementsByTagName('li')
              );
              const summarypointList = ulArray.map((e) =>
                sanitiseParagraph(e.innerHTML)
              );
              const slideBlocks =
                generateSlidesFromLegacyHtml(summarypointList);
              slideBlocks.forEach((element: SlideBlock) => {
                blocks.push(element);
              });
            } else if (item.outerHTML.includes('i-lo')) {
              /**
               * Learning objectives
               */
              const ulArray = htmlCollectionToArray(
                virtualDiv.getElementsByTagName('li')
              );
              const learningObjectiveList = ulArray.map((e) =>
                sanitiseParagraph(e.innerHTML)
              );
              // blocks.push(createLearningObjectiveBlock(learningObjectiveList, elementId));

              // Additional: Add to LO list
              learningObjectiveList.forEach((element) => {
                learningObjectives.push(element);
              });
            }
          }
        } else if (item.tagName === 'OL' || item.tagName === 'UL') {
          /**
           * Parse HTML as virtual DOM
           */
          const virtualDiv = parser.parseFromString(
            item.outerHTML,
            'text/html'
          );

          if (item.tagName === 'OL') {
            /**
             * Ordered List
             */
            const elementId = item.id;
            const div = virtualDiv.getElementsByTagName('OL')[0];
            const listItems = recursiveHtmlListIntoArray(div);

            for (let index = 0; index < listItems.length; index++) {
              const li = listItems[index];

              const content = createBlockInlineContent(li.content);

              /**
               * If the list item contains a nested list
               */
              let listChildren: ListBlock[] = [];
              if (li.items.length > 0) {
                listChildren = recursiveConvertListArrayToBlocks(
                  li.items,
                  'ol'
                );
              }

              blocks.push(
                createNumberedListBlock(
                  [content],
                  defaultProps,
                  listChildren,
                  elementId
                )
              );
            }
          } else {
            /**
             * Unordered List
             */
            const elementId = item.id;
            const div = virtualDiv.getElementsByTagName('UL')[0];
            const listItems = recursiveHtmlListIntoArray(div);

            for (let index = 0; index < listItems.length; index++) {
              const li = listItems[index];

              const content = createBlockInlineContent(li.content);

              /**
               * If the list item contains a nested list
               */
              let listChildren: ListBlock[] = [];
              if (li.items.length > 0) {
                listChildren = recursiveConvertListArrayToBlocks(
                  li.items,
                  'ul'
                );
              }

              blocks.push(
                createBulletListBlock(
                  [content],
                  defaultProps,
                  listChildren,
                  elementId
                )
              );
            }
          }
        } else if (item.tagName === 'FIGURE') {
          /**
           * Parse HTML as virtual DOM
           */
          const virtualFig = parser.parseFromString(
            item.outerHTML,
            'text/html'
          );

          // Get all components of an image
          const imageElement: any = virtualFig.getElementsByTagName('IMG')[0];
          // const attributionElement: any = virtualFig.querySelectorAll(
          //   '[id^="file-attribution"]'
          // )[0];
          const captionElement: any =
            virtualFig.getElementsByTagName('FIGCAPTION')[0];

          const imageUrl = imageElement.src;
          let style = imageElement?.dataset?.max;
          if (!style) {
            // If data-max isn't set - check for max height setting
            const maxH = parseInt(imageElement.style.maxHeight, 10);
            if (Number.isInteger(maxH)) style = maxH;
            else {
              style = '';
            }
          }
          // const attributionUrl = attributionElement?.dataset?.file;
          const caption = captionElement.textContent;

          const hangarImageUrl = parseAbsoluteHangarUrl(imageUrl);

          const imageUuid = getUuidFromEquellaApiUrl(hangarImageUrl);
          const imageVersion = getVersionFromEquellaApiUrl(hangarImageUrl);
          const imageAttachmentUuid =
            getAttachmentUuidFromHangarApiUrl(hangarImageUrl);

          const mediaProps: MediaProps = {
            format: 'img', // The type of media resource
            itemUuid: imageUuid || '', // The primary URL to the media resource
            itemVersion: imageVersion || 0, // The version of the media resource
            attachmentUuid: imageAttachmentUuid || '', // The UUID of the attachment
            imageAttachmentUuid: '',
            attribution: '',
            maxHeight: style,
            backgroundColor: 'default',
            textAlignment: 'left',
            textColor: 'default'
          };

          // Resource-based props
          // format: MediaResourceType;
          // itemUuid: string;
          // itemVersion: number;
          // attachmentUuid: string;
          // imageAttachmentUuid: string; // Applies to: vid, 3dobj, gif, ruffle, html5
          // attribution: string;
          // // Content-based props (meaning it can be changed by the editor)
          // caption: string;
          // maxHeight: number; // px

          /**
           * Create media block
           */
          const elementId = item.id;
          blocks.push(createMediaBlock(mediaProps, caption.trim(), elementId));
        } else if (item.tagName === 'TABLE') {
          if (item.className.includes('aa-table')) {
            let tableBody: Element;
            let tableRows: any;
            if (item.children.length > 1) {
              tableRows = item.children;
            } else {
              tableBody = item.children[0];
              tableRows = htmlCollectionToArray(tableBody.children);
            }

            const tableArray: string[][] = [];

            // Generate table array
            tableRows.forEach((element: Element) => {
              const rowArray: string[] = [];
              const tableColumns = htmlCollectionToArray(element.children);
              tableColumns.forEach((element) => {
                rowArray.push(element.innerHTML);
              });
              tableArray.push(rowArray);
            });

            /**
             * Table
             */
            const elementId = item.id;
            blocks.push(
              createErrorBlock(
                'Table HTML block is no longer supported, it is now considered media',
                JSON.stringify(tableArray),
                elementId
              )
            );
          } else {
            /**
             * Raw HTML
             */
            const elementId = item.id;
            const rawHtml = item.outerHTML;
            blocks.push(
              createErrorBlock(
                rawHtml,
                'Raw HTML block is no longer supported',
                elementId
              )
            );
          }
        } else if (item.tagName === 'BR') {
          // Ignore
        } else {
          console.error('Exception', item);
          console.error(
            'An exception occurred converting HTML into editable format - This is a BUG - Please let Nick know ASAP'
          );
        }
      }
    });
  } else {
    console.warn('No accordions found!');
  }

  return {
    heading: contentHeading,
    learningObjectives: convertLearningLevelsToNewFormat(learningObjectives),
    blocks
  };
}
