/**
 * Content Function Endpoint
 */

/**
 * Get
 */
export function getContentEndpoint() {
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  return `${baseUrl}/api/content`;
}
