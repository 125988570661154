import { MathsProps } from 'app/libraries/html2blocks/blocks/maths.types';

/**
 * Create HTML Maths element
 */
export function Maths(id: string, props: MathsProps, position: string) {
  const math = props.maths;
  const lines = math.split('\\\\');
  const html = lines.map((line) => `<p>$$${line}$$</p>`).join('');
  return `
    <div 
      id="block-element-${id}" 
      class="text-base text-gray-700 dark:text-gray-400 mt-2 mb-2" 
      data-block
      data-type="math" 
      data-math-maths="${math}"
      data-element-pos="${position}" 
    >
        ${html}
    </div>
    `;
}
