/* eslint-disable prettier/prettier */
import { validateAttributionHTML } from 'app/libraries/blocks2html/utils/attribution';
import { MediaProps } from 'app/libraries/html2blocks/blocks/media.type';

export function createStyledIframeInnerHTML(
  id: string,
  url: string,
  caption: string,
  props: MediaProps
) {
  const mediaAttribution = validateAttributionHTML(props?.attribution);
  const attributionIsValid = mediaAttribution.trim() !== '' && mediaAttribution.toLowerCase().trim() !== 'unknown';
  return `
  <!-- Card -->
  <div class="relative pt-1">

    ${props.format === 'ruffle' ? '<p class="text-xs md:text-sm text-gray-400 dark:text-gray-600 text-center">The following media is interactive, to view in fullscreen please right click on it.</p>' : ''}

    <!-- Embed via iframe -->
    <div>
      <iframe 
        style="margin: auto; max-width: 100%; max-height: 60.02vw; width: 100%; aspect-ratio: 16/9;"
        src=""
        data-src="${url}"
        title="${caption}"
        frameborder="0"
        scrolling="no"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>

    ${attributionIsValid ? `
      <!-- Image attribution -->
      <div>
          <p data-media-attribution class="mt-2 text-xs text-right text-gray-300 dark:text-gray-700">${mediaAttribution}</p>
      </div>
      ` : ``}

    <!-- Image caption -->
    <div>
        <p ${attributionIsValid ? 'data-media-attribution-title' : ''} class="text-sm md:text-base text-gray-700 dark:text-gray-400 font-bold text-center mb-2">${caption}</p>
    </div>
  </div>
  
  <!-- Spotlight definition -->
  <a class="spotlight" data-media="node" data-src="#spotlight-fragment-${id}" data-title="${caption}" data-control="page,next,prev,close"></a>
  
  <!-- Spotlight content (hidden) -->
  <div style="display: none;">
    <div id="spotlight-fragment-${id}">
      <iframe 
          width="640px"
          height="385px" 
          src="${url}" 
          title="${caption}" 
          frameborder="0" 
          style="position: relative; top: 0; left: 0; bottom: 0; right: 0; width: 80vw; height: 75vh; border: none; margin: 0; padding: 0;"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen></iframe>
    </div>
  </div>`;
}
