/**
 * Find a master document's topic in a course template by the master document UUID
 */

import {
  AAMetadataObject,
  ASQAMetadataObject,
  CourseMetadataObject,
  NAAMetadataObject,
  RegulatorType
} from 'app/types/service/courses.types';

export function findCourseTopicByDocumentId(
  courseTemplate: any,
  documentId: string | undefined
) {
  const topics = courseTemplate.topics;
  for (let i = 0; i < topics.length; i++) {
    const topic = topics[i];
    const masterDocs = topic.masterDocuments;
    for (let j = 0; j < masterDocs.length; j++) {
      const masterObj = masterDocs[j];
      const url = masterObj.documentUrl;
      const urlArray = url.split('/');
      const uuid = urlArray[5];
      if (uuid === documentId) {
        return topic.topicNumber;
      }
    }
  }
}

export const createCourseMetadataObject = (
  regulator: RegulatorType,
  courseCode: string,
  courseTitle: string,
  courseTaxonomy: string
): CourseMetadataObject | null => {
  let taxonomySplit = courseTaxonomy.split('\\');
  taxonomySplit.pop(); // remove course name
  switch (regulator) {
    case 'NAA':
      let courseCodeSplit = courseCode.split('-');
      const moduleNumber = courseCodeSplit.at(-1);
      if (!moduleNumber) return null;
      const moduleName = courseTitle;
      if (!moduleName) return null;

      const licence = taxonomySplit.pop();
      if (!licence) return null;
      const regulation = taxonomySplit.pop();
      if (!regulation) return null;

      const naaMetadataObject: NAAMetadataObject = {
        moduleNumber,
        moduleName,
        regulation,
        licence
      };
      return naaMetadataObject;
    case 'AA':
      const businessUnit = taxonomySplit[1];
      const aaMetadataObject: AAMetadataObject = {
        businessUnit
      };
      return aaMetadataObject;
    case 'ASQA':
      const trainingPackage = regulator;
      const qualification = taxonomySplit[2];
      const uoc = taxonomySplit[3];
      const asqaMetadataObject: ASQAMetadataObject = {
        trainingPackage,
        qualification: {
          code: qualification,
          title: ''
        },
        uoc: {
          code: uoc,
          title: '',
          isPartial: false
        }
      };
      return asqaMetadataObject;
    default:
      return null;
  }
};

export const createCourseResourceName = (
  regulator: RegulatorType,
  courseCode: string,
  courseTitle: string,
  courseTaxonomy: string
): string | null => {
  let taxonomySplit = courseTaxonomy.split('\\');
  switch (regulator) {
    case 'NAA':
      const naaAuthority = taxonomySplit[1];
      const naaCourseName = taxonomySplit.at(-1);
      return `${naaAuthority} ${naaCourseName}`;
    case 'AA':
      return `${courseCode} ${courseTitle}`;
    case 'ASQA':
      const asqaUoc = taxonomySplit.at(-2);
      if (!asqaUoc) return null;
      return `${regulator} ${courseCode} ${asqaUoc} ${courseTitle}`;
    default:
      return null;
  }
};
