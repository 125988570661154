import { getContent } from 'app/integration/server/library/content';
import { createNewDocument } from 'app/integration/server/library/documents';
import blocks2html from 'app/libraries/blocks2html';
import html2blocks from 'app/libraries/html2blocks';
import { ContentResponse } from 'app/types/integration/content.api.types';
import { PublishDocumentRequestBody } from 'app/types/integration/documents.api.types';
import { ItemResourceResponseObject } from 'app/types/integration/items.api.types';
import { ContentEditorObject } from 'app/types/service/content.types';
import {
  ContentToLink,
  CourseDocument,
  DocumentToLink
} from 'app/types/service/course-manager/course.types';
import { RegulatorType } from 'app/types/service/courses.types';
import {
  DocumentEditorObject,
  DocumentMetadataEditorObject
} from 'app/types/service/document.types';

export const createDocument = async (
  courseType: RegulatorType,
  courseAuthority: string,
  courseCode: string,
  courseTitle: string,
  courseTaxonomyWithoutReg: string,

  courseUuid: string,
  topicId: string | undefined,
  courseDocument: CourseDocument,
  contentsToLink: ContentToLink[],
  userId: string,
  accessToken: string
): Promise<DocumentToLink | null> => {
  const documentName = courseDocument.stringValue;
  const documentResourceName = `${courseAuthority} ${courseCode} ${courseTitle} - ${documentName}`;
  const documentLocation = `${courseTaxonomyWithoutReg}\\${topicId}`;
  // const newDocumentUuid = generateNewUuid();

  const contentEditorObjects: ContentEditorObject[] = await Promise.all(
    contentsToLink.map(async (contentToLink): Promise<ContentEditorObject> => {
      const getContentResponse = await getContent(
        contentToLink.postItemResponse.uuid,
        false,
        accessToken
      );
      if (getContentResponse.status !== 200) {
        console.error('Failed to GET content');
        console.log(getContentResponse);
        throw new Error('Failed to GET content');
      }
      const contentResponse: ContentResponse = getContentResponse.data;
      const editor = html2blocks.content(contentResponse.content.html);
      if (!editor) {
        throw new Error('Failed to initialise content editor object');
      }
      return {
        ...contentResponse,
        editor
      };
    })
  );
  const newDocumentMetadataObject: DocumentMetadataEditorObject = {
    content: contentEditorObjects,
    schema: '1.0.0',
    heading: documentName,
    regulator: courseType,
    summary: '',
    location: documentLocation,
    knowledgeLevels: [],
    teachingPoints: [],
    learningObjectives: [],
    linkedCourse: {
      uuid: courseUuid
    },
    linkedContent: [],
    linkedResources: [],
    amendment: {
      description: '',
      log: []
    },
    notes: {
      justification: '',
      history: '',
      log: []
    }
  };
  const newDocumentResourceObject: ItemResourceResponseObject = {
    uuid: '',
    version: 0,
    status: 'live',
    name: documentResourceName,
    description: '',
    attachments: [],
    createdDate: '',
    modifiedDate: '',
    owner: {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      email: ''
    }
  };
  const newDocumentEditorObject: DocumentEditorObject = {
    document: newDocumentMetadataObject,
    resource: newDocumentResourceObject,
    workflow: {
      reasonForUpdate: null,
      tasks: null,
      metadata: null
    }
  };
  const documentHtml = blocks2html.document('build', newDocumentEditorObject);

  const publishDocumentRequestBody: PublishDocumentRequestBody = {
    name: documentResourceName,
    description:
      'A HTML master document designed for use by Launchpad via the LTI.',
    changeType: 'nontechnical',
    improvementDetails: 'New resource created with Course Builder via Toolbox',
    owner: userId,
    regulator: courseType,
    location: documentLocation,
    summary: '',
    resourceReview: 'New resource created with Course Builder via Toolbox',
    courseId: courseUuid,
    changeLog: [],
    file: {
      heading: documentName,
      content: documentHtml
    }
  };

  const postDocumentResponse = await createNewDocument(
    publishDocumentRequestBody,
    accessToken
  );
  if (postDocumentResponse.status !== 200) {
    console.error('Failed to POST document');
    console.log(postDocumentResponse);
    return null;
  }

  const postItemResponse = postDocumentResponse.data;
  const newDocumentToLink: DocumentToLink = {
    ...courseDocument,
    contentsToLink,
    postItemResponse
  };
  return newDocumentToLink;
};
