import { validateOrCreateUuidV4 } from '../utils/uuid';
import { MathsBlock } from './maths.types';

/**
 * Create math equation editor block
 */
export function createMathsBlock(maths: string, id?: string): MathsBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'maths',
    props: {
      maths
    }
  };
}

// export interface MathsBlock {
//   id: string;
//   type: 'Math';
//   data: {
//     math: string;
//     blockId: string;
//   };
// }

// export function mathsElementToBlock(
//   element: HTMLElement,
//   elementId: string
// ): MathsBlock | null {
//   const math = element.innerHTML.trim();
//   if (!math) return null;
//   return createMathsBlock(math, elementId);
// }
