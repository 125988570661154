import { validateOrCreateUuidV4 } from '../utils/uuid';
import { BlockInlineContent } from './common.types';
import { HeadingBlock, HeadingLevel, HeadingProps } from './heading.types';

/**
 * Creater header editor block
 */
export function createHeadingBlock(
  content: BlockInlineContent[],
  props: HeadingProps,
  id?: string
): HeadingBlock {
  const blockId = validateOrCreateUuidV4(id);
  return {
    id: blockId,
    type: 'heading',
    props,
    content
  };
}

export function createHeadingBlockFromText(
  text: string,
  headerLevel: HeadingLevel,
  id?: string
): HeadingBlock {
  const headerInlineContent: BlockInlineContent = {
    text: text,
    type: 'text',
    styles: {}
  };
  return createHeadingBlock(
    [headerInlineContent],
    {
      level: headerLevel,
      backgroundColor: 'default',
      textAlignment: 'left',
      textColor: 'default'
    },
    id
  );
}

// const mapTagNameToHeaderLevel = new Map<string, HeadingLevel>([
//   ['H3', 3],
//   ['H4', 4],
//   ['H5', 5],
//   ['H6', 6]
// ]);

// export function headerElementToBlock(
//   element: HTMLElement,
//   elementId: string
// ): HeaderBlock | null {
//   const text = element.innerHTML.trim();
//   const headerLevel = mapTagNameToHeaderLevel.get(element.tagName);
//   if (!text || !headerLevel) return null;
//   return createHeaderBlock(text, headerLevel, elementId);
// }
