import { getEnvironmentVariable } from 'app/service/utils/environment';
import { HtmlConfigObject } from '../types/htmlConfig.types';

/**
 * Get the app HTML config
 * This is used for local application previews
 */
export function getAppHTMLConfig(): HtmlConfigObject {
  const origin = window.location.origin;
  return {
    env: 'app',
    favicon: `${origin}/html/assets/favicon.ico`,
    logo: `${origin}/html/assets/logo.svg`,
    fonts: {
      google: getEnvironmentVariable('REACT_APP_EXT_FONTS_GOOGLE')
    },
    styles: {
      main: `${origin}/html/styles/style.css`, // Auto-generated by tailwind CSS
      spotlight: `${origin}/html/styles/spotlight.min.css`,
      overrides: `${origin}/html/styles/overrides.css`
    },
    scripts: {
      spotlight: `${origin}/html/scripts/spotlight.min.js`,
      flowbite: `${origin}/html/scripts/flowbite.min.js`,
      mathjax: getEnvironmentVariable('REACT_APP_EXT_SCRIPTS_MATHJAX'),
      main: `${origin}/html/scripts/scripts.js`
    }
  };
}
