import content from './content';
import document from './document';

/**
 * Toolbox Library obj2html
 * Version 1.0.0 - First release
 * Export the functions as an object
 */
const blocks2html = {
  document: document,
  content: content
};

export default blocks2html;
